import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MetaService } from '@ngx-meta/core';

import { Subscription, throwError } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { AuthService } from '../../../servicios/auth/auth.service';
import { LoaderService } from '../../../servicios/loader.service';
import { ScrollerService } from '../../../servicios/scroller.service';
import { MensajesService } from '../../../servicios/mensajes/mensajes.service';
import { ContactoService } from '../../../servicios/contacto/contacto.service';
import { CarritoService } from '../../../servicios/carrito/carrito.service';
import { ProductosService } from '../../../servicios/productos/productos.service';
import { AdministradorDeSubscripcionesService } from '../../../servicios/administrador-de-subscripciones';

import { Producto } from '../../../modelos/productos/producto';
import { Usuario } from '../../../modelos/usuario/usuario';
import { Contacto } from '../../../modelos/contacto/contacto';
import { Caracteristica } from '../../../modelos/productos/caracteristica';
import { ItemCarrito } from '../../../modelos/carrito/item-carrito';

@Component({
  selector: 'app-visor-producto',
  templateUrl: './visor-producto.component.html',
  styleUrls: ['./visor-producto.component.scss']
})
export class VisorProductoComponent implements OnInit, OnDestroy {

  @ViewChild('fotosCarousel')
  private carouselFotos : NgbCarousel;

  public formConsulta : FormGroup;
  public validarFormConsulta : boolean = false;

  public usuario : Usuario = null;
  public notificadorUsuario : Subscription = null;
  public solicitarDatosDeConsulta : boolean = true;

  public idFotoActual : string = 'producto-foto-0';
  public producto : Producto;

  public urlImagenSeleccionada : string;

  public cantidad : number = 1;

  public caracteristicaSeleccionada : Caracteristica = null;

  public posicionXZoom : number;
  public posicionYZoom : number;
  public posicionXMaxima : number;
  public posicionYMaxima : number;
  public activarZoom : boolean = false;

  public ready : boolean = false;

  constructor(private router : Router,
              private ruta : ActivatedRoute,
              private builder : FormBuilder,
              private metaService: MetaService,
              private authService : AuthService,
              private loaderService : LoaderService,
              private carritoService : CarritoService,
              private contactoService : ContactoService,
              private scrollerService : ScrollerService,
              private mensajesService : MensajesService,
              private productosService : ProductosService,
              private subscripcionesService : AdministradorDeSubscripcionesService) {

    this.formConsulta = this.builder.group({
      nombreCompleto : ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      email : ['', Validators.compose([Validators.required, Validators.email])],
      telefono : ['', Validators.pattern('[0-9]*(\/[0-9]*)?')],
      consulta : ['Hola. Estoy interesado en este producto y me gustaría obtener mas información. Muchas Gracias', Validators.compose([Validators.required, Validators.maxLength(255)])]
    });

    this.notificadorUsuario = this.authService.obtenerNotificador().subscribe((estado) => {
      this.usuario = estado.usuario;
      if(this.usuario) {
        this.solicitarDatosDeConsulta = false;
        this.llenarFormulario();
      }
    });
  }

  ngOnInit() {
    this.loaderService.mostrarCargando();
    let s1 : Subscription = this.ruta.paramMap.pipe(
      switchMap((params : ParamMap) => {
        this.ready = false;
        return this.productosService.obtenerProducto(params.get('slugProducto'));
      }),
      switchMap((response : any) => {
        if(response.data.articulo) {
          // console.log('prd', response.data.articulo);
          
          this.producto = new Producto(response.data.articulo);
          this.urlImagenSeleccionada = this.producto.tieneFotos() ? this.producto.fotosJpg[0].getUrlOriginal() : '';
          this.caracteristicaSeleccionada = this.producto.obtenerPrimerCaracteristicaConStock();

          return this.authService.obtenerLogueado();
        }
        else {
          return throwError(response.errors);
        }
      })
    ).subscribe((usuario : Usuario) => {
      this.usuario = usuario;
      if(this.usuario) {
        this.solicitarDatosDeConsulta = false;
      }
      this.metaService.setTitle(this.producto.nombre);
      this.metaService.setTag('description', this.producto.descripcion);
      this.metaService.setTag('og:type', 'website');
      this.metaService.setTag('og:url', this.router.url);
      this.metaService.setTag('twitter:card', 'summary_large_image');
      this.metaService.setTag('twitter:url', this.router.url);
      this.metaService.setTag('twitter:title', this.producto.nombre);
      this.metaService.setTag('twitter:description', this.producto.descripcion);
      if(this.producto.tieneFoto) {
        this.metaService.setTag('og:image', this.producto.fotosJpg[0].getUrlMiniatura3());
        this.metaService.setTag('og:image:width', '600');
        this.metaService.setTag('og:image:height', '600');
        this.metaService.setTag('twitter:image', this.producto.fotosJpg[0].getUrlMiniatura3());
      }
      this.scrollerService.reiniciarScrollVertical();
      this.loaderService.ocultarCargando();
      this.ready = true;
    },
    (errors) => {
      console.log(errors);
      let status : number = errors.length > 0 && errors[0].errorType == 'DataFetchingException' ? 404 : 500;
      this.loaderService.ocultarCargando();
      this.router.navigate(['/error'], {queryParams : {'status' : status}});
    });
    this.subscripcionesService.agregar('VisorProductoComponent', s1);
  }

  ngOnDestroy() : void {
    this.subscripcionesService.desechar('VisorProductoComponent');
  }

  agregarAlCarrito() {
    if(this.caracteristicaSeleccionada) {
      let item = new ItemCarrito();
      item.caracteristica = this.caracteristicaSeleccionada;
      item.producto = this.producto;
      item.cantidad = this.cantidad;
      if(this.usuario != null && this.usuario.esMayorista) {
        item.precio = this.producto.precioWeb;
        item.precioWeb = this.producto.precioMayorista;
      }
      else {
        item.precioWeb = this.producto.precioWeb;
        item.precio = this.producto.precio;
      }
      this.carritoService.actualizarArticulo(item);
    }
  }

  editarDatosDeConsulta() {
    this.llenarFormulario();
    this.solicitarDatosDeConsulta = true;
  }

  consultar() {
    this.validarFormConsulta = true;
    if(this.formConsulta.valid) {
      this.loaderService.mostrarCargando();
      let contacto : Contacto = new Contacto(this.formConsulta.value);
      contacto.articulo = new Producto({ id : this.producto.id });
      let s2 :Subscription = this.contactoService.enviarEmailConsulta(contacto).pipe(
        finalize(() => {
          this.loaderService.ocultarCargando();
        })
      ).subscribe(
        (response) => {
          this.validarFormConsulta = false;
          this.formConsulta.reset({
            consulta : 'Hola. Estoy interesado en este producto y me gustaría obtener mas información. Muchas Gracias'
          });
          this.llenarFormulario();
          this.mensajesService.mostrarMensajeTemporal('Tu consulta ha sido enviada con éxito');
        },
        (error) => {
          console.error(error);
          this.mensajesService.mostrarMensajeTemporal('Ocurrió un error al enviar tu consulta, vuelve a intentarlo');
        }
      );
      this.subscripcionesService.agregar('VisorProductoComponent', s2);
    }
  }

  incrementar() {
    if(this.caracteristicaSeleccionada && this.caracteristicaSeleccionada.tieneMasStock(this.cantidad)) {
      this.cantidad++;
    }
  }

  decrementar() {
    if(this.caracteristicaSeleccionada && this.cantidad > 1) {
      this.cantidad--;
    }
  }

  compareCaracteristica(a : Caracteristica, b : Caracteristica) : boolean {
    return Caracteristica.compareTo(a, b);
  }

  seleccionarImagen(id : string) {
    if(this.carouselFotos) {
      this.carouselFotos.select(id);
    }
  }

  cambioImagenCarousel(evento : any) {
    this.idFotoActual = evento.current;
    this.urlImagenSeleccionada = this.producto.fotosJpg[parseInt(this.idFotoActual.split('-')[2])].getUrlOriginal();
  }

  construirBreadcrumbs() {
    let enlaces = [];
    enlaces.push({
      nombre : 'Inicio',
      url : '/',
      activo : false
    },
    {
      nombre : 'Todos los productos',
      url : '/rubros',
      activo : false
    },
    {
      nombre : this.producto.rubro.nombre,
      url : `/rubros/${this.producto.rubro.slug}`,
      activo : false
    });
    if(this.producto.subRubro) {
      enlaces.push({
        nombre : this.producto.subRubro.nombre,
        url : `/rubros/${this.producto.rubro.slug}/subrubro/${this.producto.subRubro.slug}`,
        activo : false
      });
    }
    if(this.producto.subSubRubro) {
      enlaces.push({
        nombre : this.producto.subSubRubro.nombre,
        url : `/rubros/${this.producto.rubro.slug}/subrubro/${this.producto.subRubro.slug}/subsubrubro/${this.producto.subSubRubro.slug}`,
        activo : false
      });
    }
    enlaces.push({
      nombre : this.producto.nombre,
      url : `/${this.producto.slug}`,
      activo : true
    });
    return enlaces;
  }

  openZoom(event: any, mostrar: boolean) {
    event.preventDefault();
    this.activarZoom = mostrar;
    if (mostrar) {
      this.positionZoom(event);
    }
  }

  positionZoom(event) {
    this.posicionXZoom = event.pageX - event.target.x;
    this.posicionYZoom = event.pageY - event.target.y;
    this.posicionXMaxima = event.target.offsetWidth;
    this.posicionYMaxima = event.target.offsetHeight;
  }

  public ajustarScrollHorizontal(scroller : string, avanzar : boolean) {
    this.scrollerService.ajustarScrollHorizontal(scroller, avanzar);
  }

  private llenarFormulario() {
    if(this.usuario) {
      this.formConsulta.get('nombreCompleto').setValue(this.usuario.getNombreCompleto());
      this.formConsulta.get('email').setValue(this.usuario.email);
      this.formConsulta.get('telefono').setValue(this.usuario.getTelefonos());
    }
  }
}

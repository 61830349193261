import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../servicios/auth/auth.service';
import { LoaderService } from '../../servicios/loader.service';
import { ScrollerService } from '../../servicios/scroller.service';
import { MensajesService } from '../../servicios/mensajes/mensajes.service';
import { ContactoService } from '../../servicios/contacto/contacto.service';
import { AdministradorDeSubscripcionesService } from '../../servicios/administrador-de-subscripciones';

import { Contacto } from '../../modelos/contacto/contacto';
import { Usuario } from '../../modelos/usuario/usuario';

@Component({
  selector: 'app-trabajo',
  templateUrl: './trabajo.component.html',
  styleUrls: ['./trabajo.component.scss']
})
export class TrabajoComponent implements OnInit, OnDestroy {


  public usuario : Usuario = null;
  public solicitarDatosDeContacto : boolean = true;

  public formContacto : FormGroup;
  public archivoAdjunto : File;
  public archivoInvalido : boolean = false;
  public validarFormContacto : boolean = false;

  constructor(private builder : FormBuilder,
              private authService : AuthService,
              private loaderService : LoaderService,
              private mensajesService : MensajesService,
              private scrollerService : ScrollerService,
              private contactoService : ContactoService,
              private subscripcionesService : AdministradorDeSubscripcionesService) {
 
    this.formContacto = this.builder.group({
      nombreCompleto : ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      email : ['', Validators.compose([Validators.required, Validators.email])],
      telefono : ['', Validators.pattern('[0-9]*(\/[0-9]*)?')],
      consulta : ['', Validators.compose([Validators.required, Validators.maxLength(255)])]
    });

    let s1 : Subscription = this.authService.obtenerNotificador().subscribe((estado) => {
      this.usuario = estado.usuario;
      if(this.usuario) {
        this.solicitarDatosDeContacto = false;
        this.llenarFormulario();
      }
    });
    this.subscripcionesService.agregar('TrabajoComponent', s1);
  }

  ngOnInit() {
    let s1 : Subscription = this.authService.obtenerLogueado(false).subscribe((usuario) => {
      this.usuario = usuario;
      this.scrollerService.reiniciarScrollVertical();
    });
  }

  ngOnDestroy() {
    this.subscripcionesService.desechar('TrabajoComponent');
  }

  public editarDatosDeContacto() {
    this.llenarFormulario();
    this.solicitarDatosDeContacto = true;
  }

  public seleccionarArchivo(archivos : FileList) {
    if(archivos.length > 0) {
      if((archivos[0].type == 'application/pdf' ||
          archivos[0].type == 'application/msword' ||
          archivos[0].type == 'application/vnd.oasis.opendocument.text' ||
          archivos[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
         ) && archivos[0].size <= 15000000) {
        this.archivoInvalido = false;
        this.archivoAdjunto = archivos[0];
      }
      else {
        this.archivoAdjunto = null;
        this.archivoInvalido = true;
      }
    }
    else {
      this.archivoAdjunto = null;
      this.archivoInvalido = false;
    }
  }

  public enviarFormContacto() {
    this.validarFormContacto = true;
    if(this.formContacto.valid && !this.archivoInvalido) {
      let contacto : Contacto = new Contacto(this.formContacto.value);
      contacto.adjunto = this.archivoAdjunto;
      let s2 : Subscription = this.contactoService.enviarEmailContactoLaboral(contacto).pipe(
        finalize(() => {
          this.loaderService.ocultarCargando();
        })
      ).subscribe(
        (response) => {
          this.validarFormContacto = false;
          this.archivoAdjunto = null;
          this.archivoInvalido = false;
          this.formContacto.reset();
          this.llenarFormulario();
          this.mensajesService.mostrarMensajeTemporal('Tu consulta laboral ha sido enviada con éxito');
        },
        (error) => {
          console.error(error);
          this.mensajesService.mostrarMensajeTemporal('Ocurrió un error al enviar tu consulta laboral, vuelve a intentarlo');
        }
      );
      this.subscripcionesService.agregar('TrabajoComponent', s2);
    }
  }

  private llenarFormulario() {
    if(this.usuario) {
      this.formContacto.get('nombreCompleto').setValue(this.usuario.getNombreCompleto());
      this.formContacto.get('email').setValue(this.usuario.email);
      this.formContacto.get('telefono').setValue(this.usuario.getTelefonos());
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Producto } from '../../../modelos/productos/producto';

@Component({
  selector: 'app-visor-producto-miniatura',
  templateUrl: './visor-producto-miniatura.component.html',
  styleUrls: ['./visor-producto-miniatura.component.scss']
})
export class VisorProductoMiniaturaComponent implements OnInit {

  @Input() esMayorista : boolean = false;
  @Input() producto : Producto;
  @Input() vistaComprimida : boolean;
  @Input() vistaRelacionados : boolean;
  @Input() vistaDestacada : boolean;
  @Input() vistaMasVendido : boolean;
  @Input() vistaOferta : boolean;
  @Input() vistaConBorde : boolean = true;
  constructor() { }

  ngOnInit() {
  }
}

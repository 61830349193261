import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';

import { BlogComponent } from './componentes/blog/blog.component';
import { ErrorComponent } from './componentes/error/error.component';
import { EmpresaComponent } from './componentes/empresa/empresa.component';
import { TrabajoComponent } from './componentes/trabajo/trabajo.component';
import { ContactoComponent } from './componentes/contacto/contacto.component';
import { EncuestaComponent } from './componentes/encuesta/encuesta.component';
import { SucursalesComponent } from './componentes/sucursales/sucursales.component';
import { PreguntasFrecuentesComponent } from './componentes/preguntas-frecuentes/preguntas-frecuentes.component';
import { AppComponent } from './app.component';
import { LoginGuard } from './guardas/login.guard';
import { PanelClientesComponent } from './panel-admin/panel-clientes/panel-clientes.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild : [MetaGuard],
    children: [
      {
        path: 'blog',
        component: BlogComponent,
        data: {
          meta: {
            title: 'Blog',
            description: ''
          }
        }
      },
      {
        path: 'preguntas-frecuentes',
        component: PreguntasFrecuentesComponent,
        data: {
          meta: {
            title: 'Preguntas frecuentes',
            description: ''
          }
        }
      },
      {
        path: 'empresa',
        component: EmpresaComponent,
        data: {
          meta: {
            title: 'La Empresa',
            description: ''
          }
        }
      },
      {
        path: 'encuesta',
        component: EncuestaComponent,
        data: {
          meta: {
            title: 'Encuesta de satisfacción',
            description: ''
          }
        }
      },
      {
        path: 'trabajo',
        component: TrabajoComponent,
        data: {
          meta: {
            title: 'Trabajá con nosotros',
            description: ''
          }
        }
      },
      {
        path: 'contacto',
        component: ContactoComponent,
        data: {
          meta: {
            title: 'Contacto',
            description: ''
          }
        }
      },
      {
        path: 'sucursales',
        component: SucursalesComponent,
        data: {
          meta: {
            title: 'Nuestras sucursales',
            description: ''
          }
        }
      },
      {
        path: 'error',
        component: ErrorComponent,
        data: {
          meta: {
            title: '¡Parece que no encontramos eso!',
            description: 'Parece que el contenido que estás buscando ya no está disponible o estamos tardando demasiado en encontrarlo. Vuelve a intentarlo más tarde.',
            'page:type': 'error',
            'page:status': ''
          }
        }
      },
      {
        path: 'carrito',
        loadChildren: () => import('./componentes/carrito/carrito.module').then(m => m.CarritoModule)
      },
      {
        path: 'perfil',
        loadChildren: () => import('./componentes/perfil/perfil.module').then(m => m.PerfilModule),
        canActivateChild : [LoginGuard] 
      },
      {
        path: 'devoluciones',
        loadChildren: () => import('./componentes/devoluciones/devoluciones.module').then(m => m.DevolucionesModule),
        canActivateChild : [LoginGuard]
      },
      {
        path: 'pedidos',
        loadChildren: () => import('./componentes/pedidos/pedidos.module').then(m => m.PedidosModule),
        canActivateChild : [LoginGuard] 
      },
      {
        path: 'recuperar/:token',
        loadChildren: () => import('./componentes/inicio/inicio.module').then(m => m.InicioModule)
      },
      {
        path: 'panel-clientes',
        component: PanelClientesComponent,
        data: {
          meta: {
            title: 'Panel de Clientes',
            description: ''
          }
        }
      },
      {
        path: '',
        loadChildren: () => import('./componentes/inicio/inicio.module').then(m => m.InicioModule)
      },
      {
        path: '',
        loadChildren: () => import('./componentes/productos/productos.module').then(m => m.ProductosModule)
      },
      {
        path: '**', pathMatch: 'full', redirectTo: '/error?status=404'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy : PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { LOCALE_ID, Injectable, Inject } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CustomDatepickerI18nService extends NgbDatepickerI18n {

  private readonly I18N_VALUES = {
    'es-AR': {
      weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
      months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    }
  };

  private i18n : string;

  constructor(@Inject(LOCALE_ID) i18n : string) {
    super();
    this.i18n = i18n;
  }

  getWeekdayShortName(weekday: number): string {
    return this.I18N_VALUES[this.i18n].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return this.I18N_VALUES[this.i18n].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.I18N_VALUES[this.i18n].months[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}/${date.month}/${date.year}`;
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, of, empty } from 'rxjs';
import { tap, concatMap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { StorageService } from '../storage.service';

import { Usuario } from '../../modelos/usuario/usuario';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  private static CATALOGO_ID : number = environment.CATALOGO;
  private notificador : BehaviorSubject<any>;

  private logueado : Usuario = null;

  constructor(private api : ApiService,
              private storageService : StorageService) {

    let usuarioSesion = this.storageService.getItemSession('usuario');

    if(usuarioSesion) {
      this.logueado = new Usuario(JSON.parse(usuarioSesion));
    }

    this.notificador = new BehaviorSubject<any>({
      usuario: this.logueado,
      abrirModal: false
    });
  }

  ngOnDestroy() {
    this.notificador.complete();
  }

  public logout() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    this.storageService.removeItemSession('usuario');
    return this.api.post("/auth/logout").pipe(
      tap((response) => {
        this.notificar(null, true);
    }));
  }

  public recuperarCuenta(email : string) {
    let recuperacion : any = {
      email : email,
      token : null,
      contrasenia : null,
      catalogoId : AuthService.CATALOGO_ID
    }
    return this.api.post("/auth/recuperar-cuenta", recuperacion);
  }

  public login(email : string, contrasenia : string) : any {
    let credenciales = { 'email' : email, 'contrasenia': contrasenia };
    return this.api.post("/auth/login", credenciales).pipe(
      tap((response) => {
        this.notificar(response.body, true);
      })
    );
  }

  public registrar(registro : any) : any {
    registro.catalogoId = AuthService.CATALOGO_ID;
    return this.api.post("/auth/registrar", registro).pipe(
      tap((response) => {
        this.notificar(response.body, true);
      })
    );
  }

  public reiniciarCuenta(token : string, nuevaContrasenia : string) {
    let recuperacion : any = {
      token : token,
      contrasenia : nuevaContrasenia
    }
    return this.api.post("/auth/reiniciar-cuenta", recuperacion).pipe(
      tap((response) => {
        this.notificar(response.body, true);
      })
    );
  }

  public obtenerLogueado(abrirModal : boolean = false, forzarRevalidacion : boolean = false) {
    let observable;
    if(!forzarRevalidacion && this.logueado) {
      observable = of(this.logueado);
    }
    else {
      observable = this.api.get('/auth/logueado').pipe(
        concatMap((response : any) => {
          if(response && response.body) {
            return of(new Usuario(response.body));
          }
          else {
            return of(null);
          }
        })
      );
    }
    return observable.pipe(
      tap((usuario) => {
        this.notificar(usuario, true, abrirModal && usuario == null);
      })
    );
  }

  public actualizarLogueado(usuario : Usuario) {
    this.notificar(usuario, true);
  }

  public obtenerNotificador() {
    return this.notificador.asObservable();
  }

  private notificar(usuario : any, actualizar : boolean = false, forzarModal : boolean = false) {
    let usuarioReducido;

    if(usuario) {
      usuarioReducido = new Usuario();
      usuarioReducido.nombre = usuario.nombre;
      usuarioReducido.apellido = usuario.apellido;
      usuarioReducido.rol = usuario.rol
      usuarioReducido.esMayorista = usuario.esMayorista;
      usuarioReducido.solicitaMayorista = usuario.solicitaMayorista;
      usuarioReducido.email = usuario.email;
      usuarioReducido.dni = usuario.dni;
      usuarioReducido.fechaNacimiento = usuario.fechaNacimiento;
      usuarioReducido.prefijoTelefono = usuario.prefijoTelefono;
      usuarioReducido.telefono = usuario.telefono;
      usuarioReducido.prefijoCelular = usuario.prefijoCelular;
      usuarioReducido.celular = usuario.celular;
    }

    if(actualizar) {
      if(usuarioReducido) {
        this.storageService.setItemSession('usuario', JSON.stringify(usuarioReducido));
      }
      else {
        this.storageService.removeItemSession('usuario');
      }
      this.logueado = usuarioReducido;
    }

    this.notificador.next({
      usuario : usuarioReducido,
      abrirModal : forzarModal
    });
  }
}

<div class="container mt-3">
  <h3 class="titulo-principal">SUCURSALES</h3>
  <div class="row">
    <div class="col-12">
      <div class="sucursal">
        <h4 class="titulo">CASA CENTRAL</h4>
        <div class="detalles">
          <div class="informacion">
            <p>
              <strong>Dirección:</strong>
              <br/>
              <br/>
              Rivadavia 302 esq. Lima
              <br/>
              (0351) 4210006/7
              <br/>
              Skype: armeria34
              <br/>
              <br/>
              <a class="btn btn-pill btn-suiza btn-whatsapp" href="https://wa.me/5493512200004" target="_blank">
                <img src="/assets/imagenes/whatsapp.svg" height="16px">&nbsp;&nbsp; Whatsapp
              </a>
              <br/>
              <br/>
              <strong>Horario de atención:</strong>
              <br/>
              <br/>
              Lunes a Viernes
              <br/>
              9:00 a 18:00 hs.
              <br/>
              <br/>
              Sábados
              <br/>
              09:00 a 14:00 hs.
              <br/>
              <br/>
            </p>
          </div>
          <div class="imagen">
            <picture>
              <source srcset="/assets/imagenes/sucursales/sucursal_central.webp" type="image/webp">
              <img src="/assets/imagenes/sucursales/sucursal_central.jpeg" alt="CASA CENTRAL" width="500" height="300" diferir>
            </picture>
          </div>
          <div class="divisor"></div>
          <div class="mapa">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13620.162031934258!2d-64.1812402!3d-31.41301!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xae05011ceac00e3c!2sSuiza+Outdoor!5e0!3m2!1ses!2sar!4v1494619405864" width="100%" height="300" frameborder="0" style="border:0" allowfullscreen=""></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="sucursal">
        <h4 class="titulo">SUCURSAL PASEO DE LA ORIENTAL</h4>
        <div class="detalles">
          <div class="informacion">
            <p>
              <strong>Dirección:</strong>
              <br/>
              <br/>
              9 de Julio 37 – Local 3
              <br/>
              (0351) 4240306
              <br/>
              Skype: s-oriental
              <br/>
              <br/>
              <a class="btn btn-pill btn-suiza btn-whatsapp" href="https://wa.me/5493516119395" target="_blank">
                <img src="/assets/imagenes/whatsapp.svg" height="16px">&nbsp;&nbsp; Whatsapp
              </a>
              <br/>
              <br/>
              <strong>Horario de atención:</strong>
              <br/>
              <br/>
              Lunes a Viernes
              <br/>
              9:00 a 14:30 hs.
              <br/>
              15:30 a 19:30 hs.
              <br/>
              <br/>
              Sábados
              <br/>
              08:30 a 14:00 hs.
              <br/>
              <br/>
            </p>
          </div>
          <div class="imagen">
            <picture>
              <source srcset="/assets/imagenes/sucursales/sucursal_paseo_oriental.webp" type="image/webp">
              <img src="/assets/imagenes/sucursales/sucursal_paseo_oriental.jpeg" alt="SUCURSAL PASEO DE LA ORIENTAL" width="500" height="300" diferir>
            </picture>
          </div>
          <div class="divisor"></div>
          <div class="mapa">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.9772526795764!2d-64.18618688514947!3d-31.414752803315736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432a282dc837b8b%3A0x5200c916dc49606c!2s9+de+Julio+37%2C+X5000EMB+C%C3%B3rdoba%2C+Argentina!5e0!3m2!1ses!2sus!4v1465324061515" width="100%" height="300" frameborder="0" style="border:0" allowfullscreen=""></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="sucursal">
        <h4 class="titulo">SUCURSAL OUTLET</h4>
        <div class="detalles">
          <div class="informacion">
            <p>
              <strong>Dirección:</strong>
              <br/>
              <br/>
              Rivadavia 330
              <br/>
              (0351) 4210006/7
              <br/>
              Skype: depositosuiza
              <br/>
              <br/>
              <a class="btn btn-pill btn-suiza btn-whatsapp" href="https://wa.me/5493516119424" target="_blank">
                <img src="/assets/imagenes/whatsapp.svg" height="16px">&nbsp;&nbsp; Whatsapp
              </a>
              <br/>
              <br/>
              <strong>Horario de atención:</strong>
              <br/>
              <br/>
              Lunes a Viernes
              <br/>
              09:00 - 18:00 hs.
              <br/>
              <br/>
              Sábados
              <br/>
              09:00 a 14:00 hs.
              <br/>
              <br/>
            </p>
          </div>
          <div class="imagen">
            <picture>
              <source srcset="/assets/imagenes/sucursales/sucursal_outlet.webp" type="image/webp">
              <img src="/assets/imagenes/sucursales/sucursal_outlet.jpeg" alt="SUCURSAL OUTLET" width="500" height="300" diferir>
            </picture>
          </div>
          <div class="divisor"></div>
          <div class="mapa">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13620.162031934258!2d-64.1812402!3d-31.41301!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xae05011ceac00e3c!2sSuiza+Outdoor!5e0!3m2!1ses!2sar!4v1494619405864" width="100%" height="300" frameborder="0" style="border:0" allowfullscreen=""></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="sucursal">
        <h4 class="titulo">SUCURSAL OLMOS</h4>
        <div class="detalles">
          <div class="informacion">
            <p>
              <strong>Dirección:</strong>
              <br/>
              <br/>
              Av. Emilio Olmos 100
              <br/>
              esq. Rivadavia
              <br/>
              (0351) 4222046
              <br/>
              (0351) 4258431
              <br/>
              <br/>
              <a class="btn btn-pill btn-suiza btn-whatsapp" href="https://wa.me/5493512091602" target="_blank">
                <img src="/assets/imagenes/whatsapp.svg" height="16px">&nbsp;&nbsp; Whatsapp
              </a>
              <br/>
              <br/>
              <strong>Horario de atención:</strong>
              <br/>
              <br/>
              Lunes a Viernes
              <br/>
              9:00 - 18:00 hs.
              <br/>
              <br/>
              Sábados
              <br/>
              09:00 a 14:00 hs.
              <br/>
              <br/>
            </p>
          </div>
          <div class="imagen">
            <picture>
              <source srcset="/assets/imagenes/sucursales/sucursal_olmos.webp" type="image/webp">
              <img src="/assets/imagenes/sucursales/sucursal_olmos.jpeg" alt="SUCURSAL OLMOS" width="500" height="300" diferir>
            </picture>
          </div>
          <div class="divisor"></div>
          <div class="mapa">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3405.0016361342123!2d-64.18386768514947!3d-31.41408100328321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432a29d1cde3b3f%3A0xedfc594daaf61364!2sAv.+Emilio+Olmos+100%2C+X5000EOO+C%C3%B3rdoba%2C+Argentina!5e0!3m2!1ses!2sus!4v1464801735128" width="100%" height="300" frameborder="0" style="border:0" allowfullscreen=""></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

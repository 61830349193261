export class FechasFormatter {
  
  public static parsear(fecha : string) : Date {
    let retorno : Date = null;
    if(fecha) {
      let t : Array<number> = fecha.split(/[- T :]/).map(v => parseInt(v));
      if(t.length > 0) {
        retorno = new Date(t[0], t.length > 1 ? (t[1]-1) : 0, t.length > 2 ? t[2] : 0, t.length > 3 ? t[3] : 0, t.length > 4 ? t[4] : 0, t.length > 5 ? t[5] : 0);
      }
    }
    return retorno;
  }
  
  public static formatoCorto(fecha : Date, defecto : string = '', conHora : boolean = false) : string {
    let retorno: string = defecto;
    if (fecha) {
      retorno = [ this.pad(fecha.getDate()), this.pad(fecha.getMonth() + 1), fecha.getFullYear() ].join('/');
      if(conHora) {
        retorno = retorno.concat(' ').concat(this.pad(fecha.getHours()).toString()).concat(':').concat(this.pad(fecha.getMinutes()).toString());
      }
    }
    return retorno;
  }

  public static formatoAPI(fecha : Date, defecto : string = '', conHora : boolean = false) : string {
    let retorno: string = defecto;
    if (fecha) {
      retorno = [ fecha.getFullYear(), this.pad(fecha.getMonth() + 1), this.pad(fecha.getDate()) ].join('-');
      if(conHora) {
        retorno = retorno.concat('T').concat(this.pad(fecha.getHours()).toString()).concat(':').concat(this.pad(fecha.getMinutes()).toString());
      }
      else {
        retorno = retorno.concat('T00:00:00');
      }
    }
    return retorno;
  }

  private static pad(valor : number) : string {
    return (valor < 10) ? '0'.concat(valor.toString()) : valor.toString();
  }

}
import { Producto } from '../productos/producto';

export class Contacto {

  nombreCompleto : string;
  email : string;
  telefono : string;
  consulta : string;
  adjunto : File;
  articulo : Producto;
  catalogoId : number;

  constructor(data : any = {}) {
    this.nombreCompleto = data.nombreCompleto ? data.nombreCompleto : undefined;
    this.email = data.email ? data.email : undefined;
    this.telefono = data.telefono ? data.telefono : undefined;
    this.consulta = data.consulta ? data.consulta : undefined;
  }
}
import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-panel-clientes',
  templateUrl: './panel-clientes.component.html',
  styleUrls: ['./panel-clientes.component.scss']
})
export class PanelClientesComponent implements OnInit, AfterViewInit {
  @ViewChild('clientesPaginator', { static: false }) clientesPaginator!: MatPaginator;
  @ViewChild('productosPaginator', { static: false }) productosPaginator!: MatPaginator;

  filteredFiles: string[] = [];
  allFiles: string[] = [];
  opciones = [
    { clave: 'mayorista', valor: 'Mayorista' },
    { clave: 'outdoor', valor: 'Outdoor' },
    { clave: 'vortrek', valor: 'Vortrek' }
  ];
  selectedOption: string | null = null;
  selectedFile: string | null = null;
  clientesData: MatTableDataSource<any> = new MatTableDataSource([]);
  productosData: MatTableDataSource<any> = new MatTableDataSource([]);

  displayedColumnsClientes: string[] = ['IdCliente', 'RazonSocial', 'PrefCelular', 'Celular', 'Domicilio', 'Localidad', 'Provincia'];
  displayedColumnsProductos: string[] = ['Codigo', 'Nombre', 'PrecioMostrador', 'PrecioWeb', 'PrecioInstitucional', 'PrecioMayorista', 'PrecioVortrek', 'StockDisponible', 'imageUrl'];

  // Nueva propiedad para almacenar el ancho de la pantalla
  screenWidth: number = window.innerWidth;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchFiles();

    /* TO DISABLE SCREEN CAPTURE */
    document.addEventListener('keyup', (e) => {
      if (e.key == 'PrintScreen') {
        navigator.clipboard.writeText('');
        alert('Screenshots disabled!');
      }
    });

    /* TO DISABLE PRINTS WITH CTRL+P */
    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key == 'p') {
        alert('This section is not allowed to print or export to PDF');
        e.cancelBubble = true;
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });

    // Actualiza el ancho de la pantalla al iniciar
    this.screenWidth = window.innerWidth;
  }

  ngAfterViewInit(): void { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    // Actualiza la propiedad screenWidth cuando cambia el tamaño de la ventana
    this.screenWidth = event.target.innerWidth;
    console.log('Ancho de la pantalla:', this.screenWidth);
  }

  onSelectChange(): void {
    if (this.selectedOption) {
      this.filterFiles();
      this.resetSelection(); // Llama al reset cuando cambia la selección
    } else {
      this.filteredFiles = [];
      this.resetSelection(); // Llama al reset cuando no hay selección
    }
  }

  fetchFiles(): void {
    const endpoint = `https://cuos.com.ar:5050/http://suizaoutdoor.ddns.net:3114/api/v1/list-files`;

    this.http.get<string[]>(endpoint).subscribe(
      files => {
        this.allFiles = files;
        console.log("Archivos JSON:", this.allFiles);
        this.filterFiles();
      },
      error => {
        console.error('Error al obtener archivos:', error);
      }
    );
  }

  filterFiles(): void {
    if (this.selectedOption) {
      const prefix = this.selectedOption;
      this.filteredFiles = this.allFiles.filter(file => file.startsWith(prefix) && file.endsWith('.json'));
    } else {
      this.filteredFiles = [];
    }
  }

  getFileNameWithoutExtension(file: string): string {
    return file.replace('.json', '');
  }

  loadClientesData(): void {
    if (!this.selectedFile) {
      alert('Seleccionar un archivo antes de continuar.');
      return;
    }
  
    const endpoint = `https://cuos.com.ar:5050/http://suizaoutdoor.ddns.net:3114/api/v1/jsons/${this.selectedFile}`;
  
    this.http.get<any>(endpoint).subscribe(
      data => {
        this.clientesData = new MatTableDataSource(data.clientes);
        
        // Usar setTimeout para asegurar que la actualización del paginador ocurra después de que los datos estén cargados
        setTimeout(() => {
          if (this.clientesPaginator) {
            this.clientesData.paginator = this.clientesPaginator;
            this.clientesPaginator.pageSize = 10; // Configurar el tamaño de página
            this.clientesPaginator.firstPage(); // Ir a la primera página
          }
        }, 0); // Retardar la actualización del paginador
    
        this.productosData = new MatTableDataSource([]); // Limpiar productos
        
        console.log("Data clientes:", data.clientes);
      },
      error => {
        console.error('Error al obtener datos de clientes:', error);
      }
    );
  }

  loadProductosData(): void {
    if (!this.selectedFile) {
      alert('Seleccionar un archivo antes de continuar.');
      return;
    }

    const endpoint = `https://cuos.com.ar:5050/http://suizaoutdoor.ddns.net:3114/api/v1/jsons/${this.selectedFile}`;

    this.http.get<any>(endpoint).subscribe(
      data => {
        console.log("Data productos:", data.articulos);

        this.productosData = new MatTableDataSource(data.articulos.map(articulo => {
          console.log("Artículo:", articulo);
          return {
            ...articulo,
            imageUrl: `https://armeria-y-outdoor-suiza.nyc3.digitaloceanspaces.com/fotos/jpg/600/${articulo.slug}_1.jpg`
          };
        }));

        setTimeout(() => {
          if (this.productosPaginator) {
            this.productosData.paginator = this.productosPaginator;
            this.productosPaginator.pageSize = 7; // Configurar el tamaño de página
            this.productosPaginator.firstPage(); // Ir a la primera página
          }
        }, 0);
    
        this.clientesData = new MatTableDataSource([]); // Limpiar clientes
        
        console.log("Data productos:", data.articulos);
      },
      error => {
        console.error('Error al obtener datos de productos:', error);
      }
    );
  }

  hasData(): boolean {
    return this.clientesData.data.length > 0 || this.productosData.data.length > 0;
  }

  resetData(): void {
    this.clientesData = new MatTableDataSource([]);
    this.productosData = new MatTableDataSource([]);
  }

  selectFile(file: string): void {
    this.selectedFile = file;
    this.resetData();
  }

  isFileSelected(): boolean {
    return this.selectedFile !== null;
  }

  resetSelection(): void {
    this.selectedFile = null;
    this.clientesData = new MatTableDataSource([]);
    this.productosData = new MatTableDataSource([]);
  }

  applyFilter(filterValue: string): void {
    const filter = filterValue.trim().toLowerCase();
    if (this.clientesData) {
      this.clientesData.filter = filter;
      if (this.clientesData.paginator) {
        this.clientesData.paginator.firstPage();
      }
    }

    if (this.productosData) {
      this.productosData.filter = filter;
      if (this.productosData.paginator) {
        this.productosData.paginator.firstPage();
      }
    }
  }
}

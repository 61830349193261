import { Localidad } from './localidad';

export class Barrio {
  id : number
  nombre : string;
  localidad : Localidad;

  constructor(data : any = {}) {
    this.id = data.id;
    this.nombre = data.nombre;
    this.localidad = data.localidad ? new Localidad(data.localidad) : null;
  }

  public static extraer(data : any) : Array<Barrio> {
    let barrios = new Array<Barrio>();
    if(data) {
      data.forEach(function (d) {
        barrios.push(new Barrio(d));
      });
    }
    return barrios;
  }
}

import { NgModule } from '@angular/core';

import { ImagenCargaDiferidaDirective } from './imagen-carga-diferida.directive';

@NgModule({

  imports : [],

  declarations : [
    ImagenCargaDiferidaDirective
  ],

  exports : [
    ImagenCargaDiferidaDirective
  ]

})

export class ImagenCargaDiferidaModule {}
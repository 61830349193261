import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, UrlSegment } from '@angular/router';
import { MetaService } from '@ngx-meta/core';

import { of, throwError, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { LoaderService } from '../../../servicios/loader.service';
import { MensajesService } from '../../../servicios/mensajes/mensajes.service';
import { ScrollerService } from '../../../servicios/scroller.service';
import { RubrosService } from '../../../servicios/productos/rubros.service';
import { ProductosService } from '../../../servicios/productos/productos.service';
import { AdministradorDeSubscripcionesService } from '../../../servicios/administrador-de-subscripciones';

import { Base } from '../../../modelos/productos/base';
import { Rubro } from '../../../modelos/productos/rubro';
import { Linea } from '../../../modelos/productos/linea';
import { Marca } from '../../../modelos/productos/marca';
import { Genero } from '../../../modelos/productos/genero';
import { Busqueda } from '../../../modelos/productos/busqueda';
import { Producto } from '../../../modelos/productos/producto';
import { Caracteristica } from '../../../modelos/productos/caracteristica';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { AuthService } from 'src/app/servicios/auth/auth.service';

@Component({
  selector: 'app-visor-productos',
  templateUrl: './visor-productos.component.html',
  styleUrls: ['./visor-productos.component.scss']
})
export class VisorProductosComponent implements OnInit, OnDestroy {

  public usuario : Usuario = null;

  public ready : boolean = false;

  public templateFiltros : Array<string> = ['rubros', 'subrubro', 'subsubrubro', 'marca', 'linea', 'genero'];

  public urlBase : Array<string> = [];

  public paginaActual : number = 1;
  public cantidadResultados : number = 30;
  public cantidadTotalPaginas : number = 0;
  public cantidadTotalResultados : number = 0;

  public ordenamiento : any = {
    campo: 'precio',
    descendente: true
  }

  public rubro : Rubro;

  public subRubros : Array<Rubro>;
  public subRubro : Rubro;

  public subSubRubros : Array<Rubro>;
  public subSubRubro : Rubro;

  public marcas : Array<Marca>;
  public marca : Marca;

  public lineas : Array<Linea>;
  public linea : Linea;

  public generos : Array<Genero>;
  public genero : Genero;

  public productos : Array<Producto> = [];

  public mostrarFiltros : boolean = false;
  public mostrarOrden : boolean = false;
  public vistaMosaico : boolean = true;
  public esDestacados : boolean = false;
  public esBusqueda : boolean = false;
  public esOfertas : boolean = false;
  public esOutlet : boolean = false;
  public puedeFiltrar : boolean = false;

  private slugRubro : string;
  private slugSubRubro : string;
  private slugSubSubRubro : string;

  private slugMarca : string;
  private slugLinea : string;
  private slugGenero : string;

  private textoBusqueda : string;


  constructor(private router : Router,
              private ruta : ActivatedRoute,
              private metaService : MetaService,
              private loaderService : LoaderService,
              private rubrosService : RubrosService,
              private scrollerService : ScrollerService,
              private mensajesService : MensajesService,
              private productosService : ProductosService,
              private authService: AuthService,
              private subscripcionService : AdministradorDeSubscripcionesService) {
  }

  ngOnInit() : void {
    this.loaderService.mostrarCargando();
    let s1 : Subscription = this.ruta.url.pipe(
      switchMap((url : Array<UrlSegment>) => {
        this.loaderService.mostrarCargando();
        this.urlBase = url.map(urlSegment => urlSegment.path);
        this.urlBase.unshift('');
        return this.ruta.paramMap;
      }),
      switchMap((params : ParamMap) => {
        this.slugRubro = params.get('slugRubro');
        this.slugSubRubro = params.get('slugSubRubro');
        this.slugSubSubRubro = params.get('slugSubSubRubro');

        this.slugMarca = params.get('slugMarca');
        this.slugLinea = params.get('slugLinea');
        this.slugGenero = params.get('slugGenero');

        if(this.urlBase.length > 1) {
          switch(this.urlBase[1]) {
            case 'destacados':
              this.esDestacados = true;
              break;
            case 'buscar':
              this.esBusqueda = true;
              break;
            case 'ofertas':
              this.esOfertas = true;
              break;
            case 'outlet':
              this.esOutlet = true;
              this.ordenamiento = {
                campo : 'precio',
                descendente : true
              };
              break;
          }
          this.puedeFiltrar = false;
        }
        return this.ruta.queryParamMap;
      }),
      switchMap((queryParams : ParamMap) => {

        this.paginaActual = 1;
        this.cantidadResultados = 30;

        this.ordenamiento.campo = 'precio';
        this.ordenamiento.descendente = true;

        if(this.esBusqueda) {
          if(queryParams.has('texto')) {
            this.textoBusqueda = queryParams.get('texto');
            this.metaService.setTitle(`Búsqueda: ${this.textoBusqueda}`);
          }
        }

        if(queryParams.has('pagina')) {
          this.paginaActual = parseInt(queryParams.get('pagina'));
        }
        if(queryParams.has('cantidad')) {
          this.cantidadResultados = parseInt(queryParams.get('cantidad'));
        }
        if(queryParams.has('orden')) {
          this.ordenamiento.campo = queryParams.get('orden');
        }
        if(queryParams.has('descendente')) {
          this.ordenamiento.descendente = queryParams.get('descendente') == 'true' ? true : false;
        }

        if(this.slugRubro && !(this.esBusqueda || this.esOfertas || this.esOutlet || this.esDestacados)) {
          return this.rubrosService.obtenerRubro(this.slugRubro);
        }
        else {
          return of(null);
        }
      }),
      switchMap((response : any) => {
        if(response) {
          if(response.data && response.data.rubro) {
            this.puedeFiltrar = true;
            this.rubro = new Rubro(response.data.rubro);
            this.subRubros = this.rubro.subRubros;
            this.inicializarFiltros(this.rubro);
            this.metaService.setTitle(`Productos de ${this.rubro.nombre}`);
            if(this.slugSubRubro) {
              return this.rubrosService.obtenerSubRubro(this.slugRubro, this.slugSubRubro);
            }
            else {
              return of(null);
            }
          }
          else {
            return throwError(response.errors);
          }
        }
        else {
          return of(null);
        }
      }),
      switchMap((response : any) => {
        if(response) {
          if(response.data && response.data.subRubro) {
            this.subRubro = new Rubro(response.data.subRubro);
            this.subSubRubros = this.subRubro.subRubros;
            this.inicializarFiltros(this.subRubro);
            if(this.slugSubSubRubro) {
              return this.rubrosService.obtenerSubSubRubro(this.slugRubro, this.slugSubRubro, this.slugSubSubRubro);
            }
            else {
              return of(null);
            }
          }
          else {
            return throwError(response.errors);
          }
        }
        else {
          return of(null);
        }
      }),
      switchMap((response : any) => {
        if(response) {
          if(response.data && response.data.subSubRubro) {
            this.subSubRubro = new Rubro(response.data.subSubRubro);
            this.inicializarFiltros(this.subSubRubro);
          }
          else {
            return throwError(response.errors);
          }
        }
        return this.productosService.obtenerProductos(this.construirFiltros());
      })
    ).subscribe(
      (response : any) => {
        if(response && response.data && response.data.articulos) {
          this.productos = Producto.extraer(response.data.articulos.resultados);
          this.paginaActual = response.data.articulos.paginacion.paginaActual;
          this.cantidadTotalPaginas = response.data.articulos.paginacion.cantidadTotalPaginas;
          this.cantidadResultados = response.data.articulos.paginacion.cantidadResultados;
          this.cantidadTotalResultados = response.data.articulos.paginacion.cantidadTotalResultados;
          this.ordenamiento = response.data.articulos.orden;
        }
        this.ready = true;
        this.scrollerService.descongelarScrollVertical();
        this.scrollerService.reiniciarScrollVertical();
        this.loaderService.ocultarCargando();
      },
      (errors) => {
        console.error(errors);
        let status : number = errors.length > 0 && errors[0].errorType == 'DataFetchingException' ? 404 : 500;
        this.loaderService.ocultarCargando();
        this.router.navigate(['/error'], {queryParams : {'status' : status}});
      }
    );

    let s2: Subscription = this.authService.obtenerLogueado(false).subscribe((obtenerLogueado) => {
      this.usuario = obtenerLogueado;
    });
    this.subscripcionService.agregar('VisorProductosComponent', s1);
    this.subscripcionService.agregar('VisorProductosComponent', s2);
  }

  ngOnDestroy() : void {
    this.subscripcionService.desechar('VisorProductosComponent');
  }

  cambiarVista() {
    this.vistaMosaico = !this.vistaMosaico;
  }

  quitarFiltro(filtro : string) {
    switch (filtro) {
      case 'subrubro':
        this.subRubro = null;
        break;
      case 'subsubrubro':
        this.subSubRubro = null;
        break;
      case 'marca':
        this.marca = null;
        break;
      case 'linea':
        this.linea = null;
        break;
      case 'genero':
        this.genero = null;
        break;
      default:
        break;
    }
    let idx = this.urlBase.indexOf(filtro);
    if(idx >= 0) {
      this.urlBase.splice(idx, 2);
      this.router.navigate(this.urlBase);
    }
  }

  abrirFiltros() {
    this.mostrarFiltros = !this.mostrarFiltros;
    if(this.mostrarFiltros) {
      this.scrollerService.congelarScrollVertical();
    }
    else {
      this.scrollerService.descongelarScrollVertical();
    }
  }

  abrirOrden() {
    this.mostrarOrden = !this.mostrarOrden;
    if(this.mostrarOrden) {
      this.scrollerService.congelarScrollVertical();
    }
    else {
      this.scrollerService.descongelarScrollVertical();
    }
  }

  cambiarPagina(pagina : number) {
    this.router.navigate(this.urlBase, {
      queryParams : {
        pagina : pagina,
      },
      queryParamsHandling: 'merge'
    }).then(() => {
      this.scrollerService.reiniciarScrollVertical();
    });
  }

  construirBreadcrumbs() {
    let enlaces = [];
    enlaces.push({
      nombre : 'Inicio',
      url : '/',
      activo : false
    },
    {
      nombre : 'Todos los productos',
      url : '/rubros',
      activo : false
    });
    if(this.esBusqueda) {
      enlaces.push({
        nombre : this.textoBusqueda,
        url : null,
        activo : true
      });
    }
    else if(this.esDestacados) {
      enlaces.push({
        nombre : 'Novedades',
        url : null,
        activo : true
      });
    }
    else if(this.esOfertas) {
      enlaces.push({
        nombre : 'Ofertas',
        url : null,
        activo : true
      });
    }
    else if(this.esOutlet) {
      enlaces.push({
        nombre : 'Outlet',
        url : null,
        activo : true
      });
    }
    else {
      enlaces.push({
        nombre : this.rubro.nombre,
        url : `/rubros/${this.rubro.slug}`,
        activo : this.subRubro ? false : true
      });
      if(this.subRubro) {
        enlaces.push({
          nombre : this.subRubro.nombre,
          url : `/rubros/${this.rubro.slug}/subrubro/${this.subRubro.slug}`,
          activo : this.subSubRubro ? false : true
        });
      }
      if(this.subSubRubro) {
        enlaces.push({
          nombre : this.subSubRubro.nombre,
          url : `/rubros/${this.rubro.slug}/subrubro/${this.subRubro.slug}/subsubrubro/${this.subSubRubro.slug}`,
          activo : true
        });
      }
    }
    return enlaces;
  }

  hayFiltrosAplicados() {
    return this.subRubro || this.subSubRubro || this.marca || this.linea || this.genero;
  }

  tamanioContenedor() {
    let clases : string = 'col-12';
    if(this.vistaMosaico) {
      clases = clases.concat(' col-sm-6');
      if(this.puedeFiltrar) {
        clases = clases.concat(' col-md-6 col-lg-4');
      }
      else {
        clases = clases.concat(' col-md-4 col-lg-3');
      }
    }
    return clases;
  }

  reiniciarFiltros() {
    this.router.navigate(['/rubros', this.rubro.slug]);
  }

  cambiarOrden(orden : any = null) {
    this.paginaActual = 1;
    this.router.navigate(this.urlBase, {
      queryParams : {
        texto : this.textoBusqueda,
        pagina : 1,
        cantidad : this.cantidadResultados,
        orden: orden ? orden.campo : this.ordenamiento.campo,
        descendente: orden ? orden.descendente : this.ordenamiento.descendente
      },
      queryParamsHandling: 'merge'
    });
    if(orden) {
      this.mostrarOrden = false;
    }
  }

  compareOpcionesOrden(o1 : any, o2 : any) : boolean {
    return (o1 && o2) ? (o1.campo == o2.campo && o1.descendente == o2.descendente) : false;
  }

  private inicializarFiltros(rubro : Rubro) {
    this.marcas = rubro.marcas;
    this.lineas = rubro.lineas;
    this.generos = rubro.generos;

    this.marca = this.marcas.find(m => m.slug === this.slugMarca);
    this.linea = this.lineas.find(m => m.slug === this.slugLinea);
    this.genero = this.generos.find(m => m.slug === this.slugGenero);
  }

  private construirFiltros() : Busqueda {
    let busqueda : Busqueda = new Busqueda();
    if(this.esBusqueda) {
      busqueda.texto = this.textoBusqueda;
    }
    else if(this.esOfertas) {
      busqueda.oferta = true;
    }
    else if(this.esOutlet) {
      busqueda.outlet = true;
    }
    else if (this.esDestacados) {
      busqueda.destacado = true;
    }
    else {
      if(this.rubro) {
        busqueda.rubro = this.rubro.id;
      }
      if(this.subRubro) {
        busqueda.subRubro = this.subRubro.id;
      }
      if(this.subSubRubro) {
        busqueda.subSubRubro = this.subSubRubro.id;
      }
      if(this.marca) {
        busqueda.marca = this.marca.id;
      }
      if(this.linea) {
        busqueda.linea = this.linea.id;
      }
      if(this.genero) {
        busqueda.categoria = this.genero.id;
      }
    }
    busqueda.pagina = this.paginaActual - 1;
    busqueda.cantidad = this.cantidadResultados;
    busqueda.orden =  this.ordenamiento.campo;
    busqueda.descendente =  this.ordenamiento.descendente;
    return busqueda;
  }
}

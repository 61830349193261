<div class="container mt-5">
  <div class="tarjeta">
    <div class="error-container">
      <img src="/assets/imagenes/flor.svg">
      <h3 class="my-4 bold-text">¡PARECE QUE NO ENCONTRAMOS ESO!</h3>
      <h5>¿TE PERDISTE EN EL SENDERO? <br> NO HAY PROBLEMA</h5>
      <hr>
      <a class="btn btn-bold btn-cruz btn-pill btn-suiza" routerLink="/">¡VOLVER A EMPEZAR!</a>
      <div class="text-center mt-3">
        <p class="text-help">
          <a href="javascript:void(Tawk_API.toggle())" class="btn btn-link btn-sm">¿Necesitás ayuda?</a>
        </p>
      </div>
    </div>
  </div>
</div>

<header>
  <div class="menu-mobile d-md-none d-lg-none d-xl-none">
    <div class="container">
      <div class="contenedor-izquierdo">
        <button [ngClass]="{'btn-barra btn-menu' : true, 'desplegado' : menuDesplegado}"
                type="button"
                aria-label="Abrir menú"
                (click)="clickBotonAbrirMenu()"
        ></button>
        <a routerLink="/" aria-label="Inicio">
          <img class="logo" src="/assets/imagenes/logo3.svg" height="30" width="30" alt="Suiza Outdoor Logo" diferir>
        </a>
      </div>
      <div class="contenedor-derecho">
        <button class="btn-barra btn-buscar"
                type="button"
                aria-label="Abrir barra de búsqueda"
                (click)="clickBotonAbrirBusqueda()"
        ></button>
        <button class="btn-barra btn-carrito" routerLink="carrito" (click)="clickBotonAbrirCarrito()" aria-label="Carrito">
          <span class="badge badge-pill badge-suiza badge-inverse badge-small" *ngIf="contadorCarrito > 0">{{contadorCarrito}}</span>
        </button>
      </div>
    </div>
  </div>
  <div [ngClass]="{'menu-principal' : true, 'oculto' : !mostrarBusqueda}">
    <nav class="container">
      <div class="logo">
        <a routerLink="/" aria-label="Inicio">
          <img *ngIf="!logueado?.esMayorista" class="logo" src="/assets/imagenes/logo2.svg" height="30" width="30" alt="Suiza Outdoor Logo" diferir>
          <img *ngIf="logueado?.esMayorista" class="logo" src="/assets/imagenes/logo-mayorista-outdoor.svg" height="35" width="35" alt="Suiza Outdoor Logo" diferir>
        </a>
      </div>
      <div class="barra-busqueda">
        <div class="buscador">
          <form (submit)="clickBotonBuscar()" autocomplete="off">
            <input type="text" placeholder="Buscar productos, marcas, deportes, y más..." [(ngModel)]="textoBusqueda" name="texto-busqueda" aria-label="Buscar productos, marcas, deportes, y más...">
            <button class="btn-lupa" type="submit" aria-label="Buscar ahora"></button>
          </form>
        </div>
        <div class="menu">
          <ul>
            <li *ngFor="let opcion of menuPrincipal" [ngClass]="{'destacado' : opcion.destacada}">
              <a [routerLink]="opcion.enlace">{{opcion.nombre | uppercase}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="carrito text-right">
        <a class="btn-carrito" routerLink="/carrito" aria-label="Carrito de compras">
          <span class="badge badge-pill badge-suiza" *ngIf="contadorCarrito > 0">{{contadorCarrito}}</span>
        </a>
        <div *ngIf="logueado == null">
          <button class="btn" type="button" (click)="clickBotonIngresar()">Ingresá</button>
          <button class="btn" type="button" (click)="clickBotonRegistrar()">Registrate</button>
        </div>
        <div *ngIf="logueado != null">
          <button class="btn" [ngbPopover]="usuarioContent" placement="bottom-right" popoverClass="popover-p-0">{{logueado.getNombreCompleto()}}</button>
          <ng-template #usuarioContent>
            <ul class="popover-usuario">
              <li>
                <a routerLink="/perfil">Perfil</a>
              </li>
              <li>
                <a routerLink="/pedidos">Compras</a>
              </li>
              <li>
                <a routerLink="/devoluciones">Devoluciones</a>
              </li>
              <li *ngIf="logueado.esAdministrador()">
                <a target="_blank" href="{{enlacePanel}}">Panel Administrador</a>
              </li>
              <li>
                <a href="#!" (click)="clickBotonLogout()">Salir</a>
              </li>
            </ul>
          </ng-template>
        </div>
      </div>
    </nav>
  </div>
  <div class="submenu">
    <nav class="container">
      <ul>
        <li>
          <a routerLink="/rubros/armeria">
            <span>ARME</span>
            <span>RÍA</span>
          </a>
        </li>
        <li *ngFor="let opcion of menuSecundario">
          <a [routerLink]="opcion.enlace">{{opcion.nombre | uppercase}}</a>
        </li>
      </ul>
    </nav>
  </div>
</header>

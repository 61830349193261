import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCarouselModule, NgbPaginationModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from '../../pipes/pipes.module';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { ImagenCargaDiferidaModule } from '../../directives/imagen-carga-diferida/imagen-carga-diferida.module';

import { VisorProductoMiniaturaModule } from './visor-producto-miniatura/visor-producto-miniatura.module';
import { ProductosRoutingModule } from './productos-routing.module';

import { VisorProductoComponent } from './visor-producto/visor-producto.component';
import { VisorProductosComponent } from './visor-productos/visor-productos.component';
import { FiltroComponent } from './visor-productos/filtro/filtro.component';
import { RubrosComponent } from './rubros/rubros.component';
import { VisorImagenZoomComponent } from './visor-producto/visor-imagen-zoom/visor-imagen-zoom.component';

import { RubrosService } from '../../servicios/productos/rubros.service';
import { ProductosService } from '../../servicios/productos/productos.service';

@NgModule({
  declarations: [
    VisorProductoComponent,
    VisorProductosComponent,
    FiltroComponent,
    RubrosComponent,
    VisorImagenZoomComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbCarouselModule,
    NgbTabsetModule,
    BreadcrumbsModule,
    VisorProductoMiniaturaModule,
    ImagenCargaDiferidaModule,
    ProductosRoutingModule,
    PipesModule
  ]
})
export class ProductosModule { }

import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { environment } from '../../../environments/environment';

import { Contacto } from '../../modelos/contacto/contacto';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  private static CATALOGO_ID : number = environment.CATALOGO;

  constructor(private api : ApiService) { }

  public enviarEmailContacto(contacto : Contacto) {
    contacto.catalogoId = ContactoService.CATALOGO_ID;
    return this.api.post('/contacto', contacto);
  }

  public enviarEmailContactoLaboral(contacto : Contacto) {
    contacto.catalogoId = ContactoService.CATALOGO_ID;
    // construimos los datos para enviar por partes
    let form : FormData = new FormData();
    form.append('adjunto', contacto.adjunto);
    delete contacto['adjunto'];
    delete contacto['articulo'];
    form.append('contacto', new Blob([JSON.stringify(contacto)], {type : 'application/json'}));
    // hacemos un request multiparte
    return this.api.postMultipart('/contacto/laboral', form);
  }

  public enviarEmailConsulta(contacto : Contacto) {
    contacto.catalogoId = ContactoService.CATALOGO_ID;
    return this.api.post('/contacto/consulta', contacto);
  }
}

import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { interval, Subscription } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ScrollerService {

  private renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private document : Document,
              private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public congelarScrollVertical() {
    if(!this.document.getElementById('contenedor').classList.contains('scroll-congelado')) {
      this.renderer.addClass(this.document.getElementById('contenedor'), 'scroll-congelado');
    }
  }

  public descongelarScrollVertical() {
    if(this.document.getElementById('contenedor').classList.contains('scroll-congelado')) {
      this.renderer.removeClass(this.document.getElementById('contenedor'), 'scroll-congelado');
    }
  }

  public reiniciarScrollVertical() {
    let intervalo : Subscription = interval(40).subscribe(() => {
      let pos = this.document.getElementById('contenedor').scrollTop;
      if (pos > 0) {
        this.renderer.setProperty(this.document.getElementById('contenedor'), 'scrollTop', pos - 400);
      }
      else {
        intervalo.unsubscribe();
      }
    });
  }

  public ajustarScrollHorizontal(scroller : string, avanzar : boolean) {
    if(this.document.getElementById(scroller)) {
      let posicionActual = this.document.getElementById(scroller).scrollLeft;
      let anchoMaximo = this.document.getElementById(scroller).scrollWidth;
      let anchoPantalla = document.body.clientWidth / 2;

      let limite = avanzar ? posicionActual +  anchoPantalla : posicionActual - anchoPantalla;
      limite = limite <= anchoMaximo ? limite : anchoMaximo;
      limite = limite >= 0 ? limite : 0;

      let modificador = avanzar ? 1 : -1;

      let intervalo : Subscription = interval(40).subscribe(() => {
        let paso = 80 * modificador;
        posicionActual += paso;
        if((avanzar && posicionActual >= limite) || (!avanzar && posicionActual <= limite)) {
          this.renderer.setProperty(this.document.getElementById(scroller), 'scrollLeft', limite);
          intervalo.unsubscribe();
        }
        else {
          let scrollLeftActual = this.document.getElementById(scroller).scrollLeft;
          this.renderer.setProperty(this.document.getElementById(scroller), 'scrollLeft', scrollLeftActual + paso);
        }
      });
    }
  }
}

import { Base } from './base';
import { Rubro } from './rubro';
import { Linea } from './linea';
import { Marca } from './marca';
import { Genero } from './genero';
import { Caracteristica } from './caracteristica';
import { Foto } from './foto';

export class Producto extends Base {
  codigo : string;
  descripcion : string;
  peso : number;
  descripcionDetallada : string;
  rubro : Rubro;
  subRubro : Rubro;
  subSubRubro : Rubro;
  marca : Marca;
  linea : Linea;
  genero : Genero;
  caracteristicas : Array<Caracteristica>;
  precio : number;
  precioWeb : number;
  precioMayorista : number;
  stock : boolean;
  tieneFoto : boolean;
  oferta : boolean;
  destacado : boolean;
  outlet : boolean;
  masVendido : boolean;
  tieneDescuentoOferta : boolean;
  descuentoOferta : number;
  tieneDescuentoOutlet : boolean;
  descuentoOutlet : number;
  fotosJpg : Array<Foto>;
  fotosWebp : Array<Foto>;
  enlaceExterno : string;
  relacionados : Array<Producto>;
  complementarios : Array<Producto>;

  private static ExpresionEnlaceYoutube : RegExp = new RegExp(/(?:http|https|)(?::\/\/|)(?:www.|)(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/ytscreeningroom\?v=|\/feeds\/api\/videos\/|\/user\S*[^\w\-\s]|\S*[^\w\-\s]))([\w\-]{11})[a-z0-9;:@#?&%=+\/\$_.-]*/);

  constructor(data : any = {}) {
    super(data);
    this.codigo = data.codigo;
    this.descripcion = data.descripcion;
    this.peso = data.peso;
    this.descripcionDetallada = data.descripcionDetallada;
    this.rubro = data.rubro ? new Rubro(data.rubro) : undefined;
    this.subRubro = data.subrubro ? new Rubro(data.subrubro) : undefined;
    this.subSubRubro = data.subsubrubro ? new Rubro(data.subsubrubro) : undefined;
    this.marca = data.marca ? new Marca(data.marca) : undefined;
    this.linea = data.linea ? new Linea(data.linea) : undefined;
    this.genero = data.genero ? new Genero(data.genero) : undefined;
    this.caracteristicas = Caracteristica.extraer(data.caracteristicas);
    this.precioWeb = data.precioWeb;
    this.precioMayorista = data.precioMayorista ? data.precioMayorista : null;
    this.precio = data.precio;
    this.stock = data.stock ? true : false;
    this.tieneFoto = data.tieneFoto;
    this.oferta = data.oferta;
    this.destacado = data.destacado;
    this.outlet = data.outlet;
    this.masVendido = data.masVendido;
    this.descuentoOferta = data.descuentoOferta;
    this.tieneDescuentoOferta = data.tieneDescuentoOferta;
    this.descuentoOutlet = data.descuentoOutlet;
    this.tieneDescuentoOutlet = data.tieneDescuentoOutlet;
    this.fotosJpg = Foto.extraer(data.fotosJpg);
    this.fotosWebp = Foto.extraer(data.fotosWebp);
    this.enlaceExterno = data.enlaceExterno;
    this.relacionados = Producto.extraer(data.relacionados);
    this.complementarios = Producto.extraer(data.complementarios);
  }

  public descuentoFormato() {
    let descuento = 0;
    if(this.tieneDescuentoOutlet) {
      descuento = this.descuentoOutlet;
    }
    else if(this.tieneDescuentoOferta){
      descuento = this.descuentoOferta;
    }
    return Math.floor(descuento);
  }

  public precioWebFormato() {
    return Math.floor(this.precioWeb);
  }

  public precioMayoristaFormato() {
    return Math.floor(this.precioMayorista).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  public precioFormato() {
    return Math.floor(this.precio).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  public tieneDescuento() {
    return this.tieneDescuentoOutlet || this.tieneDescuentoOferta;
  }

  public tieneTalles() : boolean {
    return this.caracteristicas && 
           (this.caracteristicas.length > 1 || (this.caracteristicas.length == 1 && !this.caracteristicas[0].esTalleUnico()));
  }

  public tieneDescripcion() : boolean {
    return this.descripcion && this.descripcion.length > 0;
  }

  public tieneDescripcionDetallada() : boolean {
    return this.descripcionDetallada && this.descripcionDetallada.length > 0;
  }

  public tieneEnlaceExterno() : boolean {
    return this.enlaceExterno && this.enlaceExterno.length > 0;
  }

  public tieneVideoYoutube() : boolean {
    return this.tieneEnlaceExterno() && Producto.ExpresionEnlaceYoutube.test(this.enlaceExterno);
  }

  public getUrlVideoYoutube() : string {
    let retorno : string = '';
    if(this.tieneVideoYoutube()) {
      retorno = `https://www.youtube.com/embed/${this.enlaceExterno.replace(Producto.ExpresionEnlaceYoutube, '$1')}`;
    }
    return retorno;
  }

  public tieneFotos() : boolean {
    return this.fotosJpg && this.fotosJpg.length > 0;
  }

  public tieneMasFotos() : boolean {
    return this.fotosJpg && this.fotosJpg.length > 1;
  }

  public obtenerPrimerCaracteristicaConStock() : Caracteristica {
    let retorno : Caracteristica = null;
    for(let caracteristica of this.caracteristicas ) {
      if(caracteristica.tieneStock()) {
        retorno = caracteristica;
        break;
      }
    }
    return retorno;
  }

  public tieneInformacion() : boolean {
    return this.tieneDescripcion() || this.tieneEnlaceExterno() || this.tieneDescripcionDetallada();
  }

  public static extraer(data : any = {}) : Array<Producto> {
    let entidades : Array<Producto> = new Array();
    if(data && data.length > 0) {
      data.forEach((e) => {
        entidades.push(new Producto(e));
      });
    }
    return entidades
  }  
}
<div class="container mt-3">
  <h3 class="titulo-principal">LA EMPRESA</h3>
  <div class="row">
    <div class="col-12 text-center">
      <div class="seccion seccion-1">
        <p>
          <strong>Suiza Outdoor ® </strong>inaugurada en el siglo XIX más concretamente en el año <strong>1888</strong> es la empresa que comercializa artículos para deportes al aire libre más antigua de la República Argentina. Esta pequeña pero pujante empresa ha podido perdurar en el tiempo siendo testigo privilegiada de la vida política, social y cultural del país, adquiriendo en todos estos años una dilatada experiencia en la comercialización de artículos de <strong>caza deportiva, pesca y camping.</strong>
        </p>
      </div>
      <div class="seccion seccion-2">
        <p>
          Es sumamente gratificante para nosotros saber que acompañamos y compartimos vivencias <strong>durante más de un siglo</strong> con apellidos conocidos para nuestra historia; quienes encontraban en nuestro local un punto de reunión, su refugio preferido para contar e intercambiar con nosotros sus anécdotas y experiencias de aventuras, dejando una impronta con su paso por nuestro local.
        </p>
      </div>
      <div class="seccion seccion-3">
        <p>
          Más de un siglo en el mercado outdoor son el reflejo de una <strong>incansable vocación de servicio al cliente</strong>; actualmente la conducción de la empresa está en manos de gente joven, dinámica y práctica. La que está acompañada en su totalidad por un equipo de colaboradores y asesores de venta, jóvenes capacitados y eficientes, quienes les dan <strong>el mejor asesoramiento y respuesta a cada una de las inquietudes, brindándose en todo momento con devoción, pasión y compromiso.</strong> Contamos con cuatro (4) locales al público estratégicamente elegidos. Iremos por más, trabajaremos por más, en post de un crecimiento serio y sostenido.
        </p>
      </div>
    </div>
  </div>
</div>


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  anios : number = 0;

  constructor() {
    this.anios = new Date().getFullYear() - 1888;
  }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MensajesService {

   constructor(private toastr : ToastrService) {
   }

  public mostrarMensaje(mensaje : string, textoAccion : string = 'CERRAR', titulo : string = '', esHtml : boolean = false) {
    this.toastr.info(mensaje, titulo, {
      enableHtml : esHtml,
      timeOut: 0,
      extendedTimeOut: 0,
      closeButton: true,
      disableTimeOut: true
    });
  }

  public mostrarMensajeTemporal(mensaje : string, duracion : number = 10, titulo : string = '', esHtml : boolean = false) {
    this.toastr.info(mensaje, titulo, {
      enableHtml : esHtml,
      timeOut: duracion * 1000,
      extendedTimeOut: duracion * 1000,
      closeButton: true
    });
  }
}

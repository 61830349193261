import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Input, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: 'img[diferir]'
})
export class ImagenCargaDiferidaDirective implements AfterViewInit {

  @Input()
  src: string;

  @Input('srcDefecto')
  atributoSrcDefecto: string;

  @HostBinding('attr.src')
  atributoSrc : string;

  private soportaAtributoLazy : boolean = false;
  private soportaIntersectionObserver : boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private referenciaElemento: ElementRef) {

    this.soportaAtributoLazy = 'loading' in HTMLImageElement.prototype;

    if(this.soportaAtributoLazy) {
      this.referenciaElemento.nativeElement.setAttribute('loading', 'lazy');
    }
    else {
      this.atributoSrc = null;
    }
  }

  ngAfterViewInit() {

    this.soportaIntersectionObserver = isPlatformBrowser(this.platformId) ? (window && 'IntersectionObserver' in window) : false;

    if(!this.soportaAtributoLazy) {
      if(this.soportaIntersectionObserver) {
        this.cargarImagenDiferida();
      }
      else {
        this.cargarImagen();
      }
    }
  }

  @HostListener('error')
  onError() {
    if(this.atributoSrcDefecto) {
      let elementoPadre = this.referenciaElemento.nativeElement.parentElement as HTMLElement;
      if(elementoPadre && elementoPadre.tagName.toLowerCase() === 'picture') {
        for(let i=0; i < elementoPadre.children.length; i++) {
          let elementoHijo = elementoPadre.children.item(i);
          if(elementoHijo.tagName.toLowerCase() === 'source') {
            elementoPadre.removeChild(elementoHijo);
          }
        }
      }
      this.atributoSrc = this.atributoSrcDefecto;
    }
  }

  private cargarImagenDiferida() {
    const observador = new IntersectionObserver(instancias => {
      instancias.forEach((instancia) => {
        if(instancia) {
          this.cargarImagen();
          observador.unobserve(this.referenciaElemento.nativeElement);
        }
      });
    });
    observador.observe(this.referenciaElemento.nativeElement);
  }

  private cargarImagen() {
    this.atributoSrc = this.src;
  }
}
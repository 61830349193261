import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage.service';
import { ItemCarrito } from '../../modelos/carrito/item-carrito';

@Injectable({
  providedIn: 'root'
})
export class CarritoService implements OnDestroy {

  private notificador : BehaviorSubject<any>;
  private detalles : Array<ItemCarrito>;

  constructor(private storageService : StorageService) {
    this.detalles = new Array<ItemCarrito>();
    if(this.storageService.getItemSession('carrito')) {
      this.detalles = ItemCarrito.extraer(JSON.parse(this.storageService.getItemSession('carrito')));
    }
    this.notificador = new BehaviorSubject({
      detalles: this.detalles,
      cantidad: this.detalles.length
    });
  }

  ngOnDestroy() {
    this.notificador.complete();
  }

  public actualizarArticulo(item : ItemCarrito) {
    let accion : string;
    let itemEnCarrito = this.detalles.find((ic) => ic.producto.id === item.producto.id);
    if(!itemEnCarrito) {
      this.detalles.push(item);
      accion = 'item-agregado';
    }
    else {
      itemEnCarrito.cantidad = item.cantidad;
      itemEnCarrito.caracteristica = item.caracteristica;
      accion = 'item-modificado';
    }
    this.actualizarCarrito(item, accion);
  }

  public quitarArticulo(item : ItemCarrito) {
    let idx = this.detalles.map(ic => ic.producto.id).indexOf(item.producto.id);
    this.detalles.splice(idx, 1);
    this.actualizarCarrito(item, 'item-eliminado');
  }

  public limpiarArticulos() {
    this.detalles = [];
    this.storageService.removeItemSession('carrito');
    this.actualizarCarrito(null, 'carrito-eliminado');
  }

  public obtenerCarrito() {
    return ItemCarrito.extraer(JSON.parse(this.storageService.getItemSession('carrito')));
  }

  public obtenerNotificador() {
    return this.notificador.asObservable();
  }

  public totalCarrito() {
    return this.detalles.reduce((total : number = 0, item : ItemCarrito) => {return total + (item.precioWeb * item.cantidad)}, 0);
  }

  public totalSinDescuento() {
    return this.detalles.reduce((total : number = 0, item : ItemCarrito) => {return total + (item.precio * item.cantidad)}, 0);
  }

  public totalArticulos() {
    return this.detalles.reduce((total : number = 0, item : ItemCarrito) => {return total + item.cantidad}, 0)
  }

  private actualizarCarrito(itemModificado : ItemCarrito, accion : string) {
    this.storageService.setItemSession('carrito', JSON.stringify(this.detalles));
    this.notificador.next({
      accion : accion,
      item : itemModificado,
      detalles : this.detalles,
      cantidad : this.detalles.length
    });
  }
}

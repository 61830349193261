export class Base {
  id : number;
  slug : string;
  nombre : string;

  constructor(data : any = {}) {
    this.id = data.id;
    this.slug = data.slug;
    this.nombre = data.nombre;
  }

  public static compareTo(a : Base, b : Base) : boolean {
    return a && b && a.id === b.id;
  }

  public toString() : string {
    return this.nombre;
  }
}
<div [ngClass]="{'filtro' : true, 'colapsado' : colapsado}">
  <div class="cabecera-filtro" (click)="colapsar()">
    <h5 class="titulo" *ngIf="titulo">{{titulo | uppercase}}</h5>
  </div>
  <ul class="opciones">
    <li *ngFor="let opcion of opciones" [ngClass]="{'seleccionada' : opcion?.slug === opcionSeleccionada?.slug}">
      <a [routerLink]="crearEnlace(opcion)" [queryParams]="{ pagina : 1 }" queryParamsHandling="merge">{{opcion?.nombre}}</a>
    </li>
  </ul>
</div>

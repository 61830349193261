<form [formGroup]="form" (submit)="registrar()">
  <h3 class="titulo-principal">REGISTRATE</h3>
  <div class="form-group">
    <label>Nombre</label>
    <input type="text" formControlName="nombre"
      [ngClass]="{'form-control' : true, 'is-invalid' : (validar && form.get('nombre').invalid)}"
      placeholder="Ingresa tu nombre" />
    <div class="invalid-feedback d-block" *ngIf="validar && form.get('nombre').hasError('required')">
      El campo nombre es obligatorio</div>
  </div>
  <div class="form-group">
    <label>Apellido</label>
    <input type="text" formControlName="apellido"
      [ngClass]="{'form-control' : true, 'is-invalid' : (validar && form.get('apellido').invalid)}"
      placeholder="Ingresa tu apellido" />
    <div class="invalid-feedback d-block" *ngIf="validar && form.get('apellido').hasError('required')">
      El campo apellido es obligatorio</div>
  </div>
  <div class="form-group">
    <label>Email</label>
    <input type="email" formControlName="email"
      [ngClass]="{'form-control' : true, 'is-invalid' : (validar && form.get('email').invalid)}"
      placeholder="Ingresa tu email" />
    <div class="invalid-feedback d-block" *ngIf="validar && form.get('email').invalid">
      <span *ngIf="form.get('email').hasError('required')">El campo email es obligatorio</span>
      <span *ngIf="form.get('email').hasError('email')">El campo email debe ser una dirección de email válida</span>
      <span *ngIf="form.get('email').hasError('existente')">
        Ya tenés una cuenta registrada con esa dirección de email <br>
        <span class="enlace" (click)="recuperar()">¿Olvidaste la contraseña?</span><br>
        También podés <span class="enlace" (click)="login()">ingresar con tu usuario de
          <span>Arme</span><span>ría</span></span>
      </span>
    </div>
  </div>
  <div class="form-group">
    <label>Contraseña</label>
    <input type="password" formControlName="contrasenia"
      [ngClass]="{'form-control' : true, 'is-invalid' : (validar && form.get('contrasenia').invalid)}"
      placeholder="Ingresa tu contraseña" />
    <div class="invalid-feedback d-block" *ngIf="validar && form.get('contrasenia').invalid">
      <span *ngIf="form.get('contrasenia').hasError('required')">El campo contraseña es obligatorio</span>
      <span *ngIf="form.get('contrasenia').hasError('minlength')">El campo contraseña debe tener al menos 6
        caracteres</span>
    </div>
  </div>
  <div class="form-group soy-mayorista">
    <input class="form-check-input" formControlName="solicitaMayorista" type="checkbox" value="true"
      id="flexCheckDefault">
    <label class="form-check-label" for="flexCheckDefault">
      Soy un cliente mayorista
    </label>
  </div>
  <div class="form-group" *ngIf="form.get('solicitaMayorista').value">
    <label>Teléfono</label>
    <input type="tel" formControlName="telefono"
      [ngClass]="{'form-control' : true, 'is-invalid' : (validar && form.get('telefono').invalid)}"
      placeholder="Ingresa tu teléfono" />
    <div class="invalid-feedback d-block" *ngIf="validar && form.get('telefono').invalid">
      <span *ngIf="form.get('telefono').hasError('required')">El campo teléfono es obligatorio</span>
      <span *ngIf="form.get('telefono').hasError('maxlength') || form.get('telefono').hasError('minlength')">El campo
        teléfono debe contener 10 digitos (sin 0 ni 15)</span>
    </div>
  </div>
  <div class="alert mt-3 mb-0 documentacion" *ngIf="form.get('solicitaMayorista').value == true">
    <div class="row">
      <div class="col-1">
        <img src="/assets/imagenes/information-gray.svg" width="16px" class="imagen-alerta">
      </div>
      <div class="col-11">
        <p class="mb-0">
          Te pediremos <a routerLink="/preguntas-frecuentes" class="documentacion-adicional">documentación adicional</a>
          para poder validar tu cuenta mayorista, en tu perfil podrás adjuntar esta documentación.
        </p>
      </div>
    </div>
  </div>
  <div class="text-center mt-4">
    <button [ngClass]="{'btn btn-suiza btn-pill btn-bold px-5' : true, 'btn-loading' : cargando}" [disabled]="cargando"
      type="submit">
      REGISTRARME
    </button>
  </div>
  <hr>
  <div class="text-center">
    <p class="text-help">
      ¿Ya tenés una cuenta?
      <button class="btn btn-link btn-sm" type="button" (click)="login()">Ingresá</button>
    </p>
  </div>
</form>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://suizaoutdoor.com.ar/api',
  API_HEADERS: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  CONTACT_PHONE: "+54 9 351 611 9395",
  CONTACT_EMAIL:"tiendavirtual@suizaoutdoor.com.ar",
  CDN_FOTOS: 'https://armeria-y-outdoor-suiza.nyc3.digitaloceanspaces.com/fotos',
  URL_MERCADOPAGO: 'https://www.mercadopago.com.ar/checkout/v1/modal/?preference-id=',
  GOOGLE_ANALYTICS: 'UA-104773376-1',
  CATALOGO: 1,
  ONE_SIGNAL_APP_ID: 'your-dev-onesignal-app-id' // Añadida esta línea

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

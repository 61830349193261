<div class="contenedor-rubros">
  <div class="selector-seccion" *ngFor="let rubro of rubros" routerLink="/rubros/{{rubro.slug}}">
    <picture>
      <source [srcset]="'/assets/imagenes/rubros/' + rubro.slug + '.webp'" type="image/webp">
      <img [src]="'/assets/imagenes/rubros/' + rubro.slug + '.jpeg'" width="450" height="250" srcDefecto="/assets/imagenes/rubros/default.jpeg" diferir>
    </picture>
    <div>
      <h3>{{rubro.nombre | uppercase}}</h3>
      <a routerLink="/rubros/{{rubro.slug}}" class="btn btn-suiza btn-pill btn-bold">VER PRODUCTOS</a>
    </div>
  </div>
</div>

import { Base } from './base';
export class Linea extends Base {

  constructor(data : any = {}) {
    super(data);
  }

  public static extraer(data : any = {}) : Array<Linea> {
    let entidades : Array<Linea> = new Array();
    if(data && data.length > 0) {
      data.forEach((e) => {
        entidades.push(new Linea(e));
      });
    }
    return entidades
  }
}
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Usuario } from './../../modelos/usuario/usuario';

@Component({
  selector: 'app-panel-lateral',
  templateUrl: './panel-lateral.component.html',
  styleUrls: ['./panel-lateral.component.scss']
})
export class PanelLateralComponent implements OnInit {

  @Input() menuPrincipal : Array<any> = [];
  @Input() desplegado : boolean;
  @Input() logueado : Usuario = null;
  @Output() informarCierre : EventEmitter<any> = new EventEmitter();
  @Output() clickIngresar : EventEmitter<any> = new EventEmitter();
  @Output() clickRegistrar : EventEmitter<any> = new EventEmitter();
  @Output() clickSalir : EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  desplegar(i : number) {
    this.menuPrincipal[i].desplegada = !this.menuPrincipal[i].desplegada;
  }

  clickBotonIngresar() {
    this.cerrarMenu();
    this.clickIngresar.emit();
  }

  clickBotonRegistrar() {
    this.cerrarMenu();
    this.clickRegistrar.emit();
  }

  clickBotonSalir(event) {
    event.preventDefault();
    this.cerrarMenu();
    this.clickSalir.emit();
  }

  recalcularAltoSubmenu(opcion) {
    return 'auto'
  }

  comprobarCierre(opcion) {
    if(!opcion.subopciones || opcion.subopciones.length == 0) {
      this.cerrarMenu();
    }
  }

  cerrarMenu() {
    this.desplegado = false;
    this.informarCierre.emit();
  }
}

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { Usuario } from '../../modelos/usuario/usuario';

import { AuthService } from '../../servicios/auth/auth.service';
import { CarritoService } from '../../servicios/carrito/carrito.service';
import { AdministradorDeSubscripcionesService } from '../../servicios/administrador-de-subscripciones';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private readonly ENDPOINT_URL = environment.API_URL;

  @Input() menuPrincipal : Array<any> = [];
  @Input() menuSecundario : Array<any> = [];
  @Input() textoBusqueda : string = '';

  @Input() menuDesplegado : boolean = false;

  @Input() logueado : Usuario = null;

  @Output() clickMenu : EventEmitter<boolean> = new EventEmitter(false);
  @Output() clickCarrito : EventEmitter<boolean> = new EventEmitter(false);
  @Output() clickAbrirBusqueda : EventEmitter<boolean> = new EventEmitter(false);
  @Output() clickBuscar : EventEmitter<string> = new EventEmitter(false);
  @Output() clickIngresar : EventEmitter<boolean> = new EventEmitter(false);
  @Output() clickRegistrar : EventEmitter<boolean> = new EventEmitter(false);
  @Output() clickSalir : EventEmitter<boolean> = new EventEmitter(false);
  enlacePanel : String = this.ENDPOINT_URL + '/panel';

  mostrarBusqueda : boolean = false;
  contadorCarrito : number = 0;

  constructor(private carritoService : CarritoService,
              private subscripcionesService : AdministradorDeSubscripcionesService,
              private authService: AuthService) {
  }

  ngOnInit() : void {
    this.contadorCarrito = this.carritoService.obtenerCarrito().length;

    let s1 : Subscription = this.carritoService.obtenerNotificador().subscribe((actualizacionCarrito) => {
      this.contadorCarrito = actualizacionCarrito.cantidad;
    });
    this.subscripcionesService.agregar('HeaderComponent', s1);

    let s2: Subscription = this.authService.obtenerLogueado(false).subscribe((obtenerLogueado) => {
      this.logueado = obtenerLogueado;
    });
  }

  ngOnDestroy() : void {
    this.subscripcionesService.desechar('HeaderComponent');
  }

  public clickBotonAbrirMenu() {
    this.menuDesplegado = !this.menuDesplegado;
    this.clickMenu.emit();
  }

  public clickBotonAbrirCarrito() {
    this.clickCarrito.emit();
  }

  public clickBotonAbrirBusqueda() {
    this.mostrarBusqueda = !this.mostrarBusqueda;
    this.clickAbrirBusqueda.emit(this.mostrarBusqueda);
  }

  public clickBotonBuscar() {
    this.clickBuscar.emit(this.textoBusqueda);
  }

  public clickBotonIngresar() {
    this.clickIngresar.emit();
  }

  public clickBotonRegistrar() {
    this.clickRegistrar.emit();
  }

  public clickBotonLogout() {
    this.clickSalir.emit();
    window.location.reload();
  }
}

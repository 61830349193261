import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../servicios/auth/auth.service';
import { LoaderService } from '../../servicios/loader.service';
import { ScrollerService } from '../../servicios/scroller.service';
import { MensajesService } from '../../servicios/mensajes/mensajes.service';
import { ContactoService } from '../../servicios/contacto/contacto.service';
import { AdministradorDeSubscripcionesService } from '../../servicios/administrador-de-subscripciones';

import { Contacto } from '../../modelos/contacto/contacto';
import { Usuario } from '../../modelos/usuario/usuario';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit, OnDestroy {

  public usuario : Usuario = null;
  public solicitarDatosDeContacto : boolean = true;

  public formContacto : FormGroup;
  public validarFormContacto : boolean = false;

  constructor(private builder : FormBuilder,
              private authService : AuthService,
              private loaderService : LoaderService,
              private mensajesService : MensajesService,
              private scrollerService : ScrollerService,
              private contactoService : ContactoService,
              private subscripcionesService : AdministradorDeSubscripcionesService) {
 
    this.formContacto = this.builder.group({
      nombreCompleto : ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      email : ['', Validators.compose([Validators.required, Validators.email])],
      telefono : ['', Validators.pattern('[0-9]*(\/[0-9]*)?')],
      consulta : ['', Validators.compose([Validators.required, Validators.maxLength(255)])]
    });

    let s1 : Subscription = this.authService.obtenerNotificador().subscribe((estado) => {
      this.usuario = estado.usuario;
      if(this.usuario) {
        this.solicitarDatosDeContacto = false;
        this.llenarFormulario();
      }
    });

    this.subscripcionesService.agregar('ContactoComponent', s1);
  }

  ngOnInit() {
    let s2 : Subscription = this.authService.obtenerLogueado(false).subscribe((usuario) => {
      this.usuario = usuario;
      this.scrollerService.reiniciarScrollVertical();
    });
    this.subscripcionesService.agregar('ContactoComponent', s2);
  }

  ngOnDestroy() {
    this.subscripcionesService.agregar('ContactoComponent');
  }

  editarDatosDeContacto() {
    this.llenarFormulario();
    this.solicitarDatosDeContacto = true;
  }

  public enviarFormContacto() {
    this.validarFormContacto = true;
    if(this.formContacto.valid) {
      this.loaderService.mostrarCargando();
      let contacto : Contacto = new Contacto(this.formContacto.value);
      let s3 : Subscription = this.contactoService.enviarEmailContacto(contacto).pipe(
        finalize(() => {
          this.loaderService.ocultarCargando();
        })
      ).subscribe(
        (response) => {
          this.validarFormContacto = false;
          this.formContacto.reset();
          this.llenarFormulario();
          this.mensajesService.mostrarMensajeTemporal('Tu consulta ha sido enviada con éxito');
        },
        (error) => {
          console.error(error);
          this.mensajesService.mostrarMensajeTemporal('Ocurrió un error al enviar tu consulta, vuelve a intentarlo');
        }
      );
      this.subscripcionesService.agregar('ContactoComponent', s3);
    }
  }

  private llenarFormulario() {
    if(this.usuario) {
      this.formContacto.get('nombreCompleto').setValue(this.usuario.getNombreCompleto());
      this.formContacto.get('email').setValue(this.usuario.email);
      this.formContacto.get('telefono').setValue(this.usuario.getTelefonos());
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ImagenCargaDiferidaModule } from '../../../directives/imagen-carga-diferida/imagen-carga-diferida.module';
import { VisorProductoMiniaturaComponent } from './visor-producto-miniatura.component';


@NgModule({
  declarations: [
    VisorProductoMiniaturaComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ImagenCargaDiferidaModule
  ],
  exports: [
    VisorProductoMiniaturaComponent
  ]
})
export class VisorProductoMiniaturaModule { }

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { environment } from '../../../environments/environment';

const catalogoId = environment.CATALOGO;

const rubrosQuery = gql`
  query getRubros($catalogo:Int!) {
    rubros(catalogo:$catalogo) {
      id
      nombre
      slug
    }
  }
`;

const rubroQuery = gql`
  query getRubro($slug:String, $catalogo:Int!) {
    rubro(slug:$slug, catalogo:$catalogo) {
      id
      nombre
      slug
      subRubros {
        id
        nombre
        slug
      }
      lineas {
        id
        nombre
        slug
      }
      marcas {
        id
        nombre
        slug
      }
      categorias {
        id
        nombre
        slug
      }
    }
  }
`;
const subRubroQuery = gql`
  query getSubRubro($slugRubro: String!, $slugSubRubro: String!, $catalogo:Int!) {
    subRubro(slugRubro: $slugRubro, slugSubRubro: $slugSubRubro, catalogo:$catalogo) {
      id
      nombre
      slug
      subSubRubros {
        id
        nombre
        slug
      }
      lineas {
        id
        nombre
        slug
      }
      marcas {
        id
        nombre
        slug
      }
      categorias {
        id
        nombre
        slug
      }
    }
  }
`;
const subSubRubroQuery = gql`
  query getSubSubRubro($slugRubro: String!, $slugSubRubro: String!, $slugSubSubRubro: String!, $catalogo:Int!) {
    subSubRubro(slugRubro: $slugRubro, slugSubRubro: $slugSubRubro, slugSubSubRubro: $slugSubSubRubro, catalogo:$catalogo) {
      id
      nombre
      slug
      lineas {
        id
        nombre
        slug
      }
      marcas {
        id
        nombre
        slug
      }
      categorias {
        id
        nombre
        slug
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class RubrosService {

  constructor(private apollo : Apollo) { }

  obtenerRubros() {
    return this.apollo.watchQuery({
      query : rubrosQuery,
      variables : {
        catalogo : catalogoId
      }
    }).valueChanges;
  }

  obtenerRubro(slug : string) {
    return this.apollo.watchQuery({
      query : rubroQuery,
      variables : {
        slug : slug,
        catalogo : catalogoId
      }
    }).valueChanges;
  }

  obtenerSubRubro(slugRubro : string, slugSubRubro : string) {
    return this.apollo.watchQuery({
      query : subRubroQuery,
      variables : {
        slugRubro : slugRubro,
        slugSubRubro: slugSubRubro,
        catalogo : catalogoId
      }
    }).valueChanges;
  }

  obtenerSubSubRubro(slugRubro : string, slugSubRubro : string, slugSubSubRubro : string) {
    return this.apollo.watchQuery({
      query : subSubRubroQuery,
      variables : {
        slugRubro : slugRubro,
        slugSubRubro : slugSubRubro,
        slugSubSubRubro : slugSubSubRubro,
        catalogo : catalogoId
      }
    }).valueChanges;
  }
}

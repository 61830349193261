<form [formGroup]="form" (submit)="reiniciar()">
  <h3 class="titulo-principal">RECUPERAR CUENTA</h3>
  <div class="form-group">
    <label>Nueva contraseña</label>
    <input type="password" formControlName="contrasenia" [ngClass]="{'form-control' : true, 'is-invalid' : (validar && form.get('contrasenia').invalid)}" placeholder="Ingresa tu nueva contraseña" />
    <div  class="invalid-feedback" *ngIf="validar && form.get('contrasenia').invalid">
      <span *ngIf="form.get('contrasenia').hasError('required')">El campo nueva contraseña es obligatorio</span>
      <span *ngIf="form.get('contrasenia').hasError('minlength')">El campo contraseña debe tener al menos 8 caracteres</span>
    </div>
  </div>
  <div class="invalid-feedback d-block">{{error}}</div>
  <div class="text-center mt-4">
    <button [ngClass]="{'btn btn-suiza btn-pill btn-bold px-5' : true, 'btn-loading' : cargando}"
            [disabled]="cargando"
            type="submit"
    >
      CAMBIAR CONTRASEÑA
    </button>
    <button class="btn btn-link mt-4" type="button" (click)="recuperar()" *ngIf="error">Volver a recuperar</button>
  </div>
</form>
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../servicios/auth/auth.service';
import { MensajesService } from '../../servicios/mensajes/mensajes.service';
import { AdministradorDeSubscripcionesService } from '../../servicios/administrador-de-subscripciones';

import { Usuario } from '../../modelos/usuario/usuario';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit, OnDestroy {

  @Output() cambiarModal = new EventEmitter<any>();

  public form: FormGroup;
  public validar: boolean = false;
  public error: string = '';
  public cargando: boolean = false;

  public fechaMaxima: NgbDateStruct;
  public fechaMinima: NgbDateStruct;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private mensajes: MensajesService,
    private subscripcionesService: AdministradorDeSubscripcionesService) {

    this.form = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      contrasenia: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      telefono: [''],
      solicitaMayorista: [false]
    }, {
      validator: (formGroup: FormGroup) => {
        const solicitaMayoristaControls = formGroup.controls.solicitaMayorista;
        const telefonoControls = formGroup.controls.telefono;
        console.log(!!solicitaMayoristaControls.value);
        console.log(telefonoControls);
        
        if (!!solicitaMayoristaControls.value) {
          if (!telefonoControls.value) {
            telefonoControls.setErrors({required: true});
          } else {
            telefonoControls.setErrors(null);
          }
        } else {
          telefonoControls.setErrors(null);
        }
      }
    });
  }



  ngOnInit() { }

  ngOnDestroy() {
    this.subscripcionesService.desechar('RegistroComponent');
  }

  registrar() {
    this.validar = true;
    const esMayorista = this.form.get('solicitaMayorista').value
    const tieneTelefono = this.form.get('telefono').value.trim() || false
    if (esMayorista && !tieneTelefono) {
      console.log('error');
    }
    if (this.form.valid && !this.cargando) {

      this.cargando = true;

      let registro: any = {
        nombre: this.form.get('nombre').value.trim(),
        apellido: this.form.get('apellido').value.trim(),
        email: this.form.get('email').value.trim(),
        contrasenia: this.form.get('contrasenia').value,
        telefono: this.form.get('telefono').value.trim(),
        solicitaMayorista: this.form.get('solicitaMayorista').value == true ? true : false
      }

      let s1: Subscription = this.authService.registrar(registro).pipe(
        finalize(() => {
          this.cargando = false;
        })
      ).subscribe(
        response => {
          let usuario: Usuario = new Usuario(response.body);
          this.cambiarModal.emit(null);
          this.mensajes.mostrarMensaje(`¡Hola ${usuario.getNombreCompleto()}, te damos la bienvenida a Suiza Outdoor!`);
        },
        error => {
          console.log(error);
          if (error.status === 400) {
            this.form.get('email').setErrors({
              existente: true
            });
          }
          else {
            this.mensajes.mostrarMensajeTemporal('Ocurrió un error al registrarte, vuelve a intentarlo');
          }
        }
      );
      this.subscripcionesService.agregar('RegistroComponent', s1);
    }
  }

  login() {
    this.cambiarModal.emit({
      modal: 'Login',
      email: this.form.get('email').value
    });
  }

  recuperar() {
    this.cambiarModal.emit({
      modal: 'Recuperar',
      email: this.form.get('email').value
    });
  }
}

<div *ngIf="ready">
  <div class="container">
    <div class="row my-4">
      <div class="col-12">
        <app-breadcrumbs [enlaces]="construirBreadcrumbs()"></app-breadcrumbs>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="cabecera-producto">
          <div class="visor-imagenes">
            <ul class="visor-miniatura" *ngIf="producto.tieneFotos()">
              <li [ngClass]="{'seleccionado' : idFotoActual == 'producto-foto-' + i}" *ngFor="let foto of producto.fotosJpg; let i = index" (click)="seleccionarImagen('producto-foto-' + i)">
                <picture>
                  <source [srcset]="producto.fotosWebp[i].getUrlMiniatura1()" type="image/webp">
                  <img [src]="foto.getUrlMiniatura1()" height="96" width="96" diferir>
                </picture>
              </li>
            </ul>
            <div class="visor-principal"
              [ngClass]="{ 'zooming' : activarZoom }"
            >
              <ngb-carousel #fotosCarousel class="producto-carousel" showNavigationIndicators="false" interval="-1" *ngIf="producto.tieneMasFotos()" (slide)="cambioImagenCarousel($event)">
                <ng-template ngbSlide [id]="'producto-foto-' + i" *ngFor="let foto of producto.fotosJpg; let i = index">
                  <div class="wrapper-imagen">
                    <picture>
                      <source [srcset]="producto.fotosWebp[i].getUrlMiniatura3()" type="image/webp">
                      <img [src]="foto.getUrlMiniatura3()"
                           [alt]="producto.nombre"
                           (mouseenter)="openZoom($event, true)"
                           (mouseleave)="openZoom($event, false)"
                           (mousemove)="positionZoom($event)"
                           height="300" width="300"
                      >
                    </picture>
                  </div>
                </ng-template>
              </ngb-carousel>
              <div class="wrapper-imagen" *ngIf="!producto.tieneMasFotos()">
                <picture *ngIf="producto.tieneFotos()">
                  <source [srcset]="producto.fotosWebp[0].getUrlMiniatura3()" type="image/webp">
                  <img [src]="producto.fotosJpg[0].getUrlMiniatura3()"
                       [alt]="producto.nombre"
                       (mouseenter)="openZoom($event, true)"
                       (mouseleave)="openZoom($event, false)"
                       (mousemove)="positionZoom($event)"
                  >
                </picture>
                <img src="/assets/imagenes/logo2.svg" [alt]="producto.nombre" *ngIf="!producto.tieneFotos()">
              </div>
              <span class="upper-badge oferta" *ngIf="producto.tieneDescuento()">-{{producto.descuentoFormato()}}%</span>
            </div>
          </div>
          <div class="informacion">
            <p class="marca">{{producto.marca.nombre | uppercase}}</p>
            <h4 class="titulo">{{producto.nombre | uppercase}}</h4>
            <p class="codigo">Código: #{{producto.codigo}}</p>
            <hr>
            <form [formGroup]="formConsulta" (submit)="consultar()" *ngIf="!producto.stock">
              <div class="contenedor-datos-consulta" *ngIf="!solicitarDatosDeConsulta && usuario">
                <div class="form-row align-items-center">
                  <div class="col-12 col-lg-9">
                    <p class="mb-3"><span class="d-block d-lg-inline">Nombre completo: </span>{{usuario.getNombreCompleto()}}</p>
                    <p class="mb-3"><span class="d-block d-lg-inline">Email: </span>{{usuario.email}}</p>
                    <p class="mb-0"><span class="d-block d-lg-inline">Teléfono: </span>{{usuario.getTelefonos()}}</p>
                  </div>
                  <div class="col-12 col-lg-3 mt-3 mt-lg-0 text-center text-lg-right">
                    <button class="btn btn-sm btn-link btn-grey" (click)="editarDatosDeConsulta()" type="button">Cambiar</button>
                  </div>
                </div>
              </div>
              <div *ngIf="solicitarDatosDeConsulta">
                <div class="form-row">
                  <div class="form-group col-12">
                    <label>Nombre completo</label>
                    <input type="text"
                           [ngClass]="{'form-control' : true, 
                                       'is-invalid' : validarFormConsulta && formConsulta.get('nombreCompleto').errors
                                      }"
                           placeholder="Ingresá tu nombre completo"
                           formControlName="nombreCompleto"
                    >
                    <div class="invalid-feedback d-block" *ngIf="validarFormConsulta && formConsulta.get('nombreCompleto').errors">
                      <span *ngIf="formConsulta.get('nombreCompleto').hasError('required')">
                        El campo nombre completo es obligatorio
                      </span>
                      <span *ngIf="formConsulta.get('nombreCompleto').hasError('maxlength')">
                        El campo nombre completo tiene un máximo de 255 caracteres
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12 col-lg-6">
                    <label>Email</label>
                    <input type="email"
                           [ngClass]="{'form-control' : true, 
                                       'is-invalid' : validarFormConsulta && formConsulta.get('email').errors
                                      }"
                           placeholder="Ingresá tu email"
                           formControlName="email"
                    >
                    <div class="invalid-feedback d-block" *ngIf="validarFormConsulta && formConsulta.get('email').errors">
                      <span *ngIf="formConsulta.get('email').hasError('required')">
                        El campo email es obligatorio
                      </span>
                      <span *ngIf="formConsulta.get('email').hasError('email')">
                        El campo email debe ser una dirección de email válida
                      </span>
                    </div>
                  </div>
                  <div class="form-group col-12 col-lg-6">
                    <label>Teléfono (opcional)</label>
                    <input type="tel"
                           [ngClass]="{'form-control' : true, 
                                       'is-invalid' : validarFormConsulta && formConsulta.get('telefono').errors
                                      }"
                           placeholder="Ingresá tu teléfono"
                           formControlName="telefono"
                    >
                    <div class="invalid-feedback d-block" *ngIf="validarFormConsulta && formConsulta.get('telefono').errors">
                      <span *ngIf="formConsulta.get('telefono').hasError('pattern')">
                        El campo teléfono debe contener sólo números
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label>Consulta</label>
                  <textarea [ngClass]="{'form-control' : true, 
                                        'is-invalid' : validarFormConsulta && formConsulta.get('consulta').errors
                                       }"
                            placeholder="Dejanos tu mensaje..."
                            formControlName="consulta"
                  ></textarea>
                  <div class="invalid-feedback d-block" *ngIf="validarFormConsulta && formConsulta.get('consulta').errors">
                    <span *ngIf="formConsulta.get('consulta').hasError('required')">
                      El campo consulta es obligatorio
                    </span>
                    <span *ngIf="formConsulta.get('consulta').hasError('maxlength')">
                      El campo consulta tiene un máximo de 255 caracteres
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 text-center text-lg-right">
                  <button class="btn btn-suiza btn-pill btn-bold px-5" type="submit">CONSULTAR</button>
                </div>
              </div>
            </form>
            <div *ngIf="producto.stock">
              <div class="form-row" *ngIf="producto.tieneTalles()">
                <div class="col-12">
                  <select class="suiza-select" [(ngModel)]="caracteristicaSeleccionada" [compareWith]="compareCaracteristica">
                    <option [ngValue]="" disabled="true">TALLE</option>
                    <option *ngFor="let caracteristica of producto.caracteristicas"
                            [ngValue]="caracteristica"
                            [disabled]="!caracteristica.tieneStock()"
                    >
                      {{caracteristica}}{{!caracteristica.tieneStock() ? ' (Agotado)' : ''}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row  mt-2">
                <div class="col-12">
                  <div class="selector-cantidad">
                    <button [ngClass]="{'menos' : true, 'activo' : caracteristicaSeleccionada && cantidad > 1}"
                            (click)="decrementar()"
                    ></button>
                    <p class="numero">{{caracteristicaSeleccionada ? cantidad : '--'}}</p>
                    <button [ngClass]="{'mas' : true, 'activo' : caracteristicaSeleccionada?.tieneMasStock(cantidad)}"
                            (click)="incrementar()"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div  *ngIf="producto.stock">
              <div class="precios">
                <h3 class="precio-original">${{producto.precioFormato()}}</h3>
                <h1 class="precio-web" *ngIf="!usuario?.esMayorista">${{producto.precioWebFormato()}}</h1>
                <h1 class="precio-web" *ngIf="usuario?.esMayorista">${{producto.precioMayoristaFormato()}} <small> - FINAL MAYORISTA</small> </h1>
              </div>
              <button class="btn btn-pill btn-suiza btn-bold btn-cruz btn-agregar" (click)="agregarAlCarrito()" *ngIf="producto.stock">AÑADIR AL CARRITO</button>
            </div>
            <app-visor-imagen-zoom
              class="visor-zoomable"
              src="{{urlImagenSeleccionada}}"
              [xPos]="posicionXZoom"
              [yPos]="posicionYZoom"
              [height]="posicionXMaxima"
              [width]="posicionYMaxima"
              [activate]="activarZoom"
            ></app-visor-imagen-zoom>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="detalles" *ngIf="producto.tieneInformacion()">
    <div class="container">
      <div class="row">
        <div class="col-12 d-none d-md-flex">
          <h1>DETALLES DEL PRODUCTO</h1>
        </div>
        <div class="col-sm-12 col-lg-6 order-0 order-lg-0" *ngIf="producto.tieneDescripcion()">
          <h4>INFORMACIÓN ADICIONAL</h4>
          <p [innerHTML]="producto.descripcion"></p>
        </div>
        <div class="col-sm-12 col-lg-6 order-2 order-lg-1" *ngIf="producto.tieneEnlaceExterno() && !producto.tieneVideoYoutube()">
          <h4>MAS INFORMACIÓN</h4>
          <a [href]="producto.enlaceExterno | safeResourceUrl">Ver mas información</a>
        </div>
        <div class="col-sm-12 col-lg-6 order-2 order-lg-1" *ngIf="producto.tieneVideoYoutube()">
          <h4>VIDEO</h4>
          <iframe width="100%" height="360px" [src]="producto.getUrlVideoYoutube() | safeResourceUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col-sm-12 col-lg-6 order-1 order-lg-2" *ngIf="producto.tieneDescripcionDetallada()">
          <h4>CARACTERÍSTICAS</h4>
          <p [innerHTML]="producto.descripcionDetallada"></p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="tarjeta mb-0">
      <ngb-tabset [destroyOnHide]="false" type="pills" class="navs-suiza">
        <ngb-tab>
          <ng-template ngbTabTitle>
            <strong>FORMAS DE PAGO</strong>
          </ng-template>
          <ng-template ngbTabContent>
            <div class="p-2 pt-4">
              <p>Aceptamos todas las formas de pago, Efectivo, Tarjeta de credito (Visa, Master, Naranja, Cordobesa).</p>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <strong>POLÍTICAS DE CAMBIO Y DEVOLUCIÓN</strong>
          </ng-template>
          <ng-template ngbTabContent>
            <div class="p-2 pt-4">
              <p>Los productos pueden estar sujetos a cambios o devoluciones, en los siguientes casos:</p>
              <ol>
                <li>El producto recibido no corresponde con el producto pedido.</li>
                <li>El talle del producto recibido no corresponde con el talle del cliente.</li>
                <li>El producto adquirido está roto o fallado, al momento de recibirlo.</li>
                <li>El producto adquirido presenta un defecto de fabricación, luego de comenzar a usarlo.</li>
              </ol>
              <p><strong>Cambios</strong></p>
              <p>
                Para cambiar un producto que no presenta fallas (caso 1 y 2):
                El cliente puede cambiarlo dentro de los 30 días corridos, desde la fecha en que recibió el producto. Condiciones: Conservación de características similares a las que poseía el producto al momento en que fue recibido (etiquetas – embalaje de origen – factura – manual, y demás accesorios). Los costos de envío del primer cambio, serán gratuitos para el cliente. De ser necesario cambios adicionales, el costo de envío será a cargo íntegramente del comprador.
              </p>
              <p>
                Para cambiar un producto que presenta fallas (caso 3 y 4):
                En caso de defectos de fábrica el cliente posee 180 días corridos, desde la fecha de recepción, para reclamar ese producto y gozar de la garantía de fábrica. Se requiere aprobación previa del proveedor/fabricante y de Suiza Outdoor S.R.L para solicitar el cambio.
              </p>
              <p><strong>Devoluciones</strong></p>
              <p>
                El consumidor posee un plazo de 10 días corridos contados a partir de que se entregue el producto o se celebre el contrato, lo último que ocurra, para revocar la aceptación de la compra. En ese caso, el consumidor devolverá el producto sin indicios de uso y en idénticas condiciones a las que poseía al momento de recepción del mismo (etiquetas – embalaje de origen – factura – manual, y demás accesorios). Todo ello según Art. 34 de la Ley de Defensa del Consumidor, y artículos concordantes del Código Civil y Comercial de la Nación.
              </p>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>

  <div class="container">
    <div class="enfasis" *ngIf="producto.relacionados && producto.relacionados.length > 0">
      <div class="titulo">
        <h3>PRODUCTOS SIMILARES</h3>
      </div>
      <div class="contenedor-scroller">
        <div id="scroller-relacionados" class="scroller">
          <div class="scroller-child" *ngFor="let relacionado of producto.relacionados">
            <app-visor-producto-miniatura [esMayorista]="usuario?.esMayorista" [producto]="relacionado" [vistaRelacionados]="true"></app-visor-producto-miniatura>
          </div>
        </div>
        <div class="fader fader-left"></div>
        <div class="fader fader-right"></div>
        <div class="scroller-control scroller-prev" (click)="ajustarScrollHorizontal('scroller-relacionados', false)"></div>
        <div class="scroller-control scroller-next" (click)="ajustarScrollHorizontal('scroller-relacionados', true)"></div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="enfasis" *ngIf="producto.complementarios && producto.complementarios.length > 0">
      <div class="titulo">
        <h3>EQUIPATE TAMBIÉN CON</h3>
      </div>
      <div class="contenedor-scroller">
        <div id="scroller-complementarios" class="scroller">
          <div class="scroller-child" *ngFor="let complementario of producto.complementarios">
            <app-visor-producto-miniatura [esMayorista]="usuario?.esMayorista" [producto]="complementario" [vistaRelacionados]="true"></app-visor-producto-miniatura>
          </div>
        </div>
        <div class="fader fader-left"></div>
        <div class="fader fader-right"></div>
        <div class="scroller-control scroller-prev" (click)="ajustarScrollHorizontal('scroller-complementarios', false)"></div>
        <div class="scroller-control scroller-next" (click)="ajustarScrollHorizontal('scroller-complementarios', true)"></div>
      </div>
    </div>
  </div>
</div>

<div [ngClass]="{'notificacion-carrito' : true, 'desplegado' : mostrarNotificacion, 'ocultar' : ocultar}">
  <div class="btn-bar">
    <button class="btn-cerrar"  (click)="cerrar()">CERRAR</button>
  </div>
  <div class="producto" *ngIf="item">
    <div class="imagen">
      <div class="imagen-wrapper">
        <img src="{{item.producto.fotosJpg[0].getUrlMiniatura1()}}" alt="{{item.producto.slug}}" *ngIf="item.producto.tieneFotos()">
        <img src="/assets/imagenes/logo2.svg" alt="{{item.producto.slug}}" *ngIf="!item.producto.tieneFotos()">
      </div>
    </div>
    <div class="informacion">
      <p>¡Añadiste un nuevo artículo a tu carrito!</p>
      <h3>{{item.producto}}</h3>
      <span>Talle: {{item.catacteristica}} Cantidad: {{item.cantidad}}</span>
    </div>
    <div class="botones">
      <a class="btn btn-pill btn-suiza btn-bold btn-white-bordered btn-cruz mr-0 mr-md-2" routerLink="/rubros" (click)="cerrar()">
        VER MÁS PRODUCTOS
      </a>
      <a class="btn btn-pill btn-suiza btn-inverse btn-bold" routerLink="/carrito" (click)="cerrar()">
        IR AL CARRITO
      </a>
    </div>
  </div>
</div>

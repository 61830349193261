import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RedirectGuard } from '../../guardas/redirec.guard';
import { RubrosComponent } from './rubros/rubros.component';
import { VisorProductosComponent } from './visor-productos/visor-productos.component';
import { VisorProductoComponent } from './visor-producto/visor-producto.component';

const routes: Routes = [
  {
    path: 'rubros',
    component: RubrosComponent,
    data: {
      meta: {
        title: 'Catálogo de productos',
        description: 'En Suiza tenemos todos los productos que necesitás para enfrentar cualquier aventura. ¡Equipate con lo mejor!'
      }
    }
  },
  {
    path: 'destacados',
    component: VisorProductosComponent,
    data: {
      meta: {
        title: 'Productos destacados',
        description: '¡Equipate con estos productos y accesorios de primera calidad!'
      }
    }
  },
  {
    path: 'ofertas',
    component: VisorProductosComponent,
    data: {
      meta: {
        title: 'Productos en oferta',
        description: '¡Encontrá en Suiza las mejores ofertas!'
      }
    }
  },
  {
    path: 'outlet',
    component: VisorProductosComponent,
    data: {
      meta: {
        title: 'Productos de outlet',
        description: '¡Descubrí una gran selección de productos con descuento de -30%, -50% y -70%!'
      }
    }
  },
  {
    path: 'buscar',
    component: VisorProductosComponent,
  },
  {
    path: 'rubros/armeria',
    component: VisorProductosComponent,
    canActivate : [RedirectGuard],
    data : {
      urlExterna : 'https://armeriasuiza.com.ar'
    }
  },
  {
    path: 'rubros/:slugRubro',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/marca/:slugMarca',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/linea/:slugLinea',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/marca/:slugMarca/linea/:slugLinea',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/marca/:slugMarca/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/linea/:slugLinea/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/marca/:slugMarca/linea/:slugLinea/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/marca/:slugMarca',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/linea/:slugLinea',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/marca/:slugMarca/linea/:slugLinea',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/marca/:slugMarca/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/linea/:slugLinea/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/marca/:slugMarca/linea/:slugLinea/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/subsubrubro/:slugSubSubRubro',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/subsubrubro/:slugSubSubRubro/marca/:slugMarca',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/subsubrubro/:slugSubSubRubro/linea/:slugLinea',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/subsubrubro/:slugSubSubRubro/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/subsubrubro/:slugSubSubRubro/marca/:slugMarca/linea/:slugLinea',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/subsubrubro/:slugSubSubRubro/marca/:slugMarca/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/subsubrubro/:slugSubSubRubro/linea/:slugLinea/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: 'rubros/:slugRubro/subrubro/:slugSubRubro/subsubrubro/:slugSubSubRubro/marca/:slugMarca/linea/:slugLinea/genero/:slugGenero',
    component: VisorProductosComponent
  },
  {
    path: ':slugProducto',
    component: VisorProductoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductosRoutingModule { }

import { Producto } from '../productos/producto';
import { Caracteristica } from '../productos/caracteristica';

export class ItemCarrito {
  id : number;
  producto : Producto;
  caracteristica : Caracteristica;
  cantidad : number;
  precio : number;
  precioWeb : number;

  constructor(data : any = {}) {
    this.producto = data.producto ? new Producto(data.producto) : undefined;
    this.caracteristica = data.caracteristica ? new Caracteristica(data.caracteristica) : undefined;
    this.cantidad = data.cantidad ? data.cantidad : 0;
    this.precio = data.precio ? data.precio : 0;
    this.precioWeb = data.precioWeb ? data.precioWeb : 0;
  }

  public subtotalFormato() {
    let subtotal = 0;
    if(this.cantidad > 0) {
      subtotal = this.cantidad * this.precio;
    }
    return Math.floor(subtotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  public precioFormato() {
    return Math.floor(this.precio).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  public precioWebFormato() {
    return Math.floor(this.precioWeb);
  }

  public subtotalWebFormato() {
    let subtotal = 0;
    if(this.cantidad > 0) {
      subtotal = this.cantidad * Math.floor(this.precioWeb);
    }
    return subtotal;
  }

  public static extraer(data : any = {}) : Array<ItemCarrito> {
    let entidades : Array<ItemCarrito> = new Array();
    if(data && data.length > 0) {
      data.forEach((e) => {
        entidades.push(new ItemCarrito(e));
      });
    }
    return entidades
  }
}
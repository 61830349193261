import { Component, OnInit, OnDestroy, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RouterEvent, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';

import { Subscription } from 'rxjs';
import { delay, concatMap } from 'rxjs/operators';

import { MetaService } from '@ngx-meta/core';
import { AuthService } from './servicios/auth/auth.service';
import { LoaderService } from './servicios/loader.service';
import { ScrollerService } from './servicios/scroller.service';
import { RubrosService } from './servicios/productos/rubros.service';
import { AdministradorDeSubscripcionesService } from './servicios/administrador-de-subscripciones';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Usuario } from './modelos/usuario/usuario';
import { Rubro } from './modelos/productos/rubro';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public menuPrincipal : Array<any> = [
    {
      nombre: 'INICIO',
      enlace: '/',
      destacada: false,
    },
    {
      nombre: 'CATÁLOGO',
      enlace: '/rubros',
      destacada: false,
    },
    {
      nombre: 'OFERTAS',
      enlace: '/ofertas',
      destacada: false,
    },
    {
      nombre: 'OUTLET',
      enlace: '/outlet',
      destacada: true,
    },
    {
      nombre: 'EMPRESA',
      enlace: '/empresa',
      destacada: false,
    },
    {
      nombre: 'BLOG',
      enlace: '/blog',
      destacada: false,
    },
    {
      nombre: 'CONTACTO',
      enlace: '/contacto',
      destacada: false,
    }
  ];

  public menuLateralPrincipal : Array<any> = [
    {
      nombre: 'INICIO',
      enlace: '/',
      icono: 'icono-inicio',
      subopciones: [],
      destacada: true
    },
    {
      nombre: 'CATÁLOGO',
      enlace: null,
      icono: 'icono-catalogo',
      subopciones: [],
      destacada: true
    },
    {
      nombre: 'OFERTAS',
      enlace: '/ofertas',
      icono: 'icono-ofertas',
      subopciones: [],
      destacada: true
    },
    {
      nombre: 'OUTLET',
      enlace: '/outlet',
      icono: 'icono-outlet',
      subopciones: [],
      destacada: true
    },
    {
      nombre: 'CARRITO DE COMPRAS',
      enlace: '/carrito',
      icono: 'icono-carrito',
      subopciones: [],
      destacada: true
    },
    {
      nombre: 'SUCURSALES',
      enlace: '/sucursales',
      icono: 'icono-sucursales',
      subopciones: [],
      destacada: false
    },
    {
      nombre: 'LA EMPRESA',
      enlace: '/empresa',
      icono: 'icono-empresa',
      subopciones: [],
      destacada: false
    },
    {
      nombre: 'PREGUNTAS FRECUENTES',
      enlace: '/preguntas-frecuentes',
      icono: 'icono-faq',
      subopciones: [],
      destacada: false
    }
  ];

  public menuSecundario : Array<any> = [];
  public textoBusqueda : string = '';

  public panelLateralMenuDesplegado : boolean = false;

  public mostrarLoader : boolean = true;
  public mostrarDecoracion : boolean = true;

  public logueado : Usuario = null;
  public tokenRecuperacion : string = null;

  @ViewChild('modalCuentaUsuario', {static : true})
  private modalCuentaUsuario : any;
  private modalCuentaUsuarioRef : NgbModalRef;
  private componenteModal : string = null;

  public emailModal : string = null;

  public currentRoute: string;  // Nueva variable para la ruta actual


  private static readonly GA_MEASUREMENT_ID = environment.GOOGLE_ANALYTICS;

  constructor(private router : Router,
              private ruta : ActivatedRoute,
              private modalService : NgbModal,
              private metaService : MetaService,
              private authService : AuthService,
              private rubrosService : RubrosService,
              private loaderService : LoaderService,
              private scrollerService : ScrollerService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private subscripciones : AdministradorDeSubscripcionesService) {
  }

  ngOnInit() {
    let s1 : Subscription = this.authService.obtenerNotificador().pipe(delay(10)).subscribe((estado) => {
      this.logueado = estado.usuario;
      if (estado.abrirModal) {
        this.abrirModal('Login');
      }
    });

    let s2 : Subscription = this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.currentRoute = event.url;  // Actualiza la ruta actual
        this.mostrarDecoracion = event.url !== '/empresa';
        if(event.url.startsWith('/recuperar')) {
          let url = event.url.split('/');
          if(url.length > 2 && url[2].length > 0) {
            this.tokenRecuperacion = url[2];
            this.abrirModal('Reiniciar');
          }
        }
        else if(event.url.startsWith('/buscar')) {
          this.ruta.queryParams.subscribe((params) => {
            if(params.texto) {
              this.textoBusqueda = params.texto;
            }
          });
        }
        if(isPlatformBrowser(this.platformId)) {
          gtag('config', AppComponent.GA_MEASUREMENT_ID, {
            'page_path': event.urlAfterRedirects
          });
        }
      }
    });

    let s3 : Subscription = this.authService.obtenerLogueado(false, true).pipe(
      concatMap((usuario : Usuario) => {
        this.logueado = usuario;
        return this.rubrosService.obtenerRubros();
      })
    ).subscribe(
      (response : any) => {
        let rubros : Array<Rubro> = Rubro.extraer(response.data.rubros);
        rubros = rubros.filter(r => r.nombre.toLowerCase() !== 'armeria');
        let keywords : string = '';
        rubros.forEach((rubro, indice) => {
          keywords = keywords.concat(rubro.nombre)
                             .concat(indice < rubros.length ? ', ' : '');
        });
        this.metaService.setTag('keywords', keywords);
        this.menuSecundario = this.menuLateralPrincipal[1].subopciones = rubros.map((r) => {
          return {
            nombre: r.nombre,
            enlace: `/rubros/${r.slug}`
          }
        });
      },
      (error) => {
        console.error(error);
      }
    );

    this.subscripciones.agregar('AppComponent', s1, s2, s3);
  
    // Inicializar OneSignal solo en producción
  if (environment.production) {
    this.initializeOneSignal();
  }
}

  ngOnDestroy() {
    this.subscripciones.desechar('AppComponent');
  }

  // Inicialización de OneSignal
  initializeOneSignal(): void {
    if ('OneSignal' in window) {
      const OneSignal = (window as any).OneSignal || [];
      OneSignal.push(function() {
        OneSignal.init({
          appId: environment.ONE_SIGNAL_APP_ID,
          safari_web_id: 'web.onesignal.auto.safari.uuid', // Reemplaza esto con tu safari web id
          notifyButton: {
            enable: true,
          },
          allowLocalhostAsSecureOrigin: true // Esta línea permite localhost como origen seguro
        });
      });
    }
  }

  public clickBotonMenu() {
    this.panelLateralMenuDesplegado = !this.panelLateralMenuDesplegado;
    if(this.panelLateralMenuDesplegado) {
      this.scrollerService.congelarScrollVertical();
    }
    else {
      this.scrollerService.descongelarScrollVertical();
    }
  }

  public clickBotonCarrito() {
    this.informarCierreMenu();
  }

  public clickBotonAbrirBusqueda() {
    this.informarCierreMenu();
  }

  public clickBotonBuscar(texto : string) {
    if(texto && texto.length > 0) {
      this.router.navigate(['/buscar'], {
        queryParams : {
          texto : texto
        }
      });
    }
  }

  public informarCierreMenu() {
    this.panelLateralMenuDesplegado = false;
    this.scrollerService.descongelarScrollVertical();
  }

  public clickBotonSalir() {
    this.authService.logout().subscribe(() => {
      this.router.navigateByUrl('/');
        window.location.reload();
    });
  }

  public clickBotonIngresar() {
    this.abrirModal('Login');
  }

  public clickBotonRegistrar() {
    this.abrirModal('Registro');
  }

  public cambiarModal(contenido : any) {
    if(contenido == null && this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    else if(contenido.modal) {
      this.componenteModal = contenido.modal;
      this.emailModal = contenido.email;
    }
    else {
      this.componenteModal = contenido;
    }
  }

  public clickBotonVolverArriba() {
    this.scrollerService.reiniciarScrollVertical();
  }

  private abrirModal(componente : string) {
    this.modalService.dismissAll();
    this.componenteModal = componente;
    this.modalCuentaUsuarioRef = this.modalService.open(this.modalCuentaUsuario, {
      backdrop: 'static',
      centered: true,
    });
    this.modalCuentaUsuarioRef.result.finally(() => {
      this.loaderService.ocultarCargando();
    });
  }
}

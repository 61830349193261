<div *ngIf="ready">
  <div class="main-carousel">
    <ngb-carousel [interval]="15000">
      <ng-template ngbSlide>
        <div class="wrapper-imagen-principal">
          <picture>
            <source srcset="/assets/imagenes/sliders/slider-columbia-invierno.webp" type="image/webp">
            <img src="/assets/imagenes/sliders/slider-columbia-invierno.jpeg" alt="Destacados Columbia" width="1920" height="800" diferir>
          </picture>
        </div>
        <div class="carousel-caption">
          <h3 class="titulo-banner orangish">¿ESTÁS LISTO PARA LA AVENTURA?</h3>
          <div class="contenedor-productos">
            <ng-container *ngFor="let producto of productosDestacadosColumbia">
              <app-visor-producto-miniatura [esMayorista]="usuario?.esMayorista" [producto]="producto" [vistaDestacada]="true"></app-visor-producto-miniatura>
            </ng-container>
          </div>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="wrapper-imagen-principal">
          <picture>
            <source srcset="/assets/imagenes/sliders/slider-salomon-invierno.webp" type="image/webp">
            <img src="/assets/imagenes/sliders/slider-salomon-invierno.jpeg" alt="Destacados Salomon" width="1920" height="800" diferir>
          </picture>
        </div>
        <div class="carousel-caption">
          <h3 class="titulo-banner blueish">¡HAY NUEVOS CAMINOS POR DESCUBRIR!</h3>
          <div class="contenedor-productos">
            <ng-container *ngFor="let producto of productosDestacadosSalomon">
              <app-visor-producto-miniatura [esMayorista]="usuario?.esMayorista" [producto]="producto" [vistaDestacada]="true"></app-visor-producto-miniatura>
            </ng-container>
          </div>
        </div>
      </ng-template>
      <!-- <ng-template ngbSlide>
        <div class="wrapper-imagen-principal">
          <picture>
            <source srcset="/assets/imagenes/sliders/slider-thenorthface-invierno.webp" type="image/webp">
            <img src="/assets/imagenes/sliders/slider-thenorthface-invierno.jpeg" alt="Destacados The North Face" width="1920" height="800" diferir>
          </picture>
        </div>
        <div class="carousel-caption">
          <h3 class="titulo-banner redish">TODO LARGO VIAJE, COMIENZA CON UN PASO</h3>
          <div class="contenedor-productos">
            <ng-container *ngFor="let producto of productosDestacadosTNF">
              <app-visor-producto-miniatura [esMayorista]="usuario?.esMayorista" [producto]="producto" [vistaDestacada]="true"></app-visor-producto-miniatura>
            </ng-container>
          </div>
        </div>
      </ng-template> -->
      <ng-template ngbSlide>
        <div class="wrapper-imagen-principal">
          <picture>
            <source srcset="/assets/imagenes/sliders/slider-vortrek-invierno.webp" type="image/webp">
            <img src="/assets/imagenes/sliders/slider-vortrek-invierno.jpeg" alt="Destacados Vortrek" width="1920" height="800" diferir>
          </picture>
        </div>
        <div class="carousel-caption">
          <h3 class="titulo-banner tealish">¿QUE DESAFÍO VAS A ENFRENTAR HOY?</h3>
          <div class="contenedor-productos">
            <ng-container *ngFor="let producto of productosDestacadosVortrek">
              <app-visor-producto-miniatura [esMayorista]="usuario?.esMayorista" [producto]="producto" [vistaDestacada]="true"></app-visor-producto-miniatura>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
  <div class="barra-ventajas">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 bordered-col">
          <a routerLink="/preguntas-frecuentes">
            <img src="/assets/imagenes/icono_metodos_pago_home.svg" alt="Medios de pago" width="60" height="60">
            <p>Pagá con tarjeta de crédito, débito o efectivo</p>
          </a>
        </div>
        <div class="col-12 col-md-4 bordered-col">
          <a routerLink="/preguntas-frecuentes">
            <img src="/assets/imagenes/icono_envios_home.svg" alt="Envios a todo el pais" width="60" height="60">
            <p>Recibí tu pedido en la puerta de tu casa</p>
          </a>
        </div>
        <div class="col-12 col-md-4 bordered-col">
          <a routerLink="/sucursales">
            <img src="/assets/imagenes/icono_sucursales_home.svg" alt="Retiro gratuito en sucursales" width="60" height="60">
            <p>Retirá gratis por nuestras sucursales</p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="enfasis d-flex flex-wrap justify-content-around align-items-center gris-claro">
    <div routerLink="/rubros/armeria" class="selector-seccion">
      <picture>
        <source srcset="/assets/imagenes/rubros/armeria.webp" type="image/webp">
        <img src="/assets/imagenes/rubros/armeria.jpeg" alt="ARME-RÍA" width="450" height="250" diferir>
      </picture>
      <div>
        <h3><span>ARME</span><span>RÍA</span></h3>
        <a routerLink="/rubros/armeria" class="btn btn-suiza btn-pill btn-bold">
          VER PRODUCTOS
        </a>
      </div>
    </div>
    <div class="selector-seccion" routerLink="/rubros/camping" [queryParams]="{'orden' : 'precio', 'descendente' : true}">
      <picture>
        <source srcset="/assets/imagenes/rubros/camping.webp" type="image/webp">
        <img src="/assets/imagenes/rubros/camping.jpeg" alt="CAMPING" width="450" height="250" diferir>
      </picture>
      <div>
        <h3>CAMPING</h3>
        <a routerLink="/rubros/camping" [queryParams]="{'orden' : 'precio', 'descendente' : true}" class="btn btn-suiza btn-pill btn-bold">
          VER PRODUCTOS
        </a>
      </div>
    </div>
    <div class="selector-seccion" routerLink="/rubros/pesca" [queryParams]="{'orden' : 'precio', 'descendente' : true}">
      <picture>
        <source srcset="/assets/imagenes/rubros/pesca.webp" type="image/webp">
        <img src="/assets/imagenes/rubros/pesca.jpeg" alt="PESCA" width="450" height="250" diferir>
      </picture>
      <div>
        <h3>PESCA</h3>
        <a routerLink="/rubros/pesca" [queryParams]="{'orden' : 'precio', 'descendente' : true}" class="btn btn-suiza btn-pill btn-bold">
          VER PRODUCTOS
        </a>
      </div>
    </div>
  </div>
  <div class="enfasis rojo mascara-promocional">
    <div class="pill">
      <h3>¡Descubrí una gran selección de productos con descuento de -30%, -50% y -70%! </h3>
      <a class="btn btn-suiza btn-pill btn-bold btn-inverse btn-edelweiss" routerLink="/outlet">¡VISITAR OUTLET!</a>
    </div>
  </div>
  <div class="enfasis gris-claro">
    <div class="titulo">
      <h3>CONOCÉ TODO LO NUEVO DE SUIZA</h3>
      <a class="btn btn-suiza btn-pill btn-bold btn-cruz" routerLink="/destacados">VER MAS</a>
    </div>
    <div class="contenedor-scroller">
      <div id="scroller-destacados" class="scroller">
        <div class="scroller-child" *ngFor="let producto of productosDestacados">
          <app-visor-producto-miniatura [esMayorista]="usuario?.esMayorista" [producto]="producto" [vistaMasVendido]="true"></app-visor-producto-miniatura>
        </div>
      </div>
      <div class="fader fader-left"></div>
      <div class="fader fader-right"></div>
      <div class="scroller-control scroller-prev" (click)="ajustarScrollHorizontal('scroller-destacados', false)"></div>
      <div class="scroller-control scroller-next" (click)="ajustarScrollHorizontal('scroller-destacados', true)"></div>
    </div>
  </div>
  <div class="enfasis gris-oscuro">
    <div class="titulo">
      <h3>ENCONTRÁ LAS MEJORES OFERTAS</h3>
      <a class="btn btn-suiza btn-pill btn-bold btn-cruz" routerLink="/ofertas">VER MAS</a>
    </div>
    <div class="contenedor-scroller">
      <div id="scroller-outlet" class="scroller">
        <div class="scroller-child" *ngFor="let producto of productosOfertas">
          <app-visor-producto-miniatura [esMayorista]="usuario?.esMayorista" [producto]="producto" [vistaOferta]="true" [vistaConBorde]="false"></app-visor-producto-miniatura>
        </div>
      </div>
      <div class="fader fader-left"></div>
      <div class="fader fader-right"></div>
      <div class="scroller-control scroller-prev" (click)="ajustarScrollHorizontal('scroller-outlet', false)"></div>
      <div class="scroller-control scroller-next" (click)="ajustarScrollHorizontal('scroller-outlet', true)"></div>
    </div>
  </div>
  <div class="enfasis">
    <div class="titulo">
      <h3>¡EQUIPATE CON ESTOS PRODUCTOS Y ACCESORIOS!</h3>
    </div>
    <div class="contenedor-scroller">
      <div id="scroller-mas-vendidos" class="scroller">
        <div class="scroller-child" *ngFor="let producto of productosMasVendidos">
          <app-visor-producto-miniatura [esMayorista]="usuario?.esMayorista" [producto]="producto" [vistaOferta]="true"></app-visor-producto-miniatura>
        </div>
      </div>
      <div class="fader fader-left"></div>
      <div class="fader fader-right"></div>
      <div class="scroller-control scroller-prev" (click)="ajustarScrollHorizontal('scroller-mas-vendidos', false)"></div>
      <div class="scroller-control scroller-next" (click)="ajustarScrollHorizontal('scroller-mas-vendidos', true)"></div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';

import { AuthService } from '../servicios/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivateChild {

  constructor (private router : Router,
               private authService : AuthService) { }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.obtenerLogueado(true, true).pipe(
      concatMap((usuario) => {
        return of(usuario !== null);
      }),
      tap((estaLogueado) => {
        if(!estaLogueado) {
          this.router.navigate(['/']);
        }
      })
    );
  }
}

<app-navbar></app-navbar>

<div class="no-screenshot" [ngClass]="{
  'container-general': true,
  'full-height': !isFileSelected(),
  'auto-height': isFileSelected()
}">
  <!-- Left panel, only visible when screen width is greater than 561px -->
  <div class="left-panel" *ngIf="screenWidth > 561">
    <h4>Panel Mayoristas</h4>
    <mat-form-field appearance="fill" class="custom-width">
      <mat-label>Elegir opción</mat-label>
      <mat-select [(value)]="selectedOption" (selectionChange)="onSelectChange()">
        <mat-option [value]="null">Elegir opción</mat-option>
        <mat-option *ngFor="let opcion of opciones" [value]="opcion.clave">{{ opcion.valor }}</mat-option>
      </mat-select>
    </mat-form-field>
    <ul>
      <li *ngFor="let file of filteredFiles" 
          (click)="selectFile(file)"
          class="files">{{ getFileNameWithoutExtension(file) }}
      </li>
    </ul>
  </div>

  <!-- Right panel, always visible -->
  <div class="right-panel" *ngIf="screenWidth <= 561 || (selectedFile && screenWidth > 561)">
    <!-- Content from left panel, displayed if screen width is less than or equal to 561px -->
    <div *ngIf="screenWidth <= 561" class="left-panel-content-mobile">
      <h4>Panel Mayoristas</h4>
      <mat-form-field appearance="fill" class="custom-width">
        <mat-label>Elegir opción</mat-label>
        <mat-select [(value)]="selectedOption" (selectionChange)="onSelectChange()">
          <mat-option [value]="null">Elegir opción</mat-option>
          <mat-option *ngFor="let opcion of opciones" [value]="opcion.clave">{{ opcion.valor }}</mat-option>
        </mat-select>
      </mat-form-field>
      <ul>
        <li *ngFor="let file of filteredFiles" 
            (click)="selectFile(file)"
            class="files">{{ getFileNameWithoutExtension(file) }}
        </li>
      </ul>
    </div>

    <div class="btns-filtros">
      <button class="btn-clientes" mat-button (click)="loadClientesData()">Clientes</button>
      <button class="btn-productos" mat-button (click)="loadProductosData()">Productos</button>
      <mat-form-field class="example-full-width" style="width: 260px;">
        <mat-label>Buscar</mat-label>
        <input matInput (input)="applyFilter($event.target.value)" placeholder="Buscar...">
      </mat-form-field>
    </div>

    <!-- Tabla de Clientes -->
    <div *ngIf="clientesData.data.length > 0">
      <h5>Clientes:</h5>
      <div class="table-clientes-wrapper">   
      <table mat-table [dataSource]="clientesData" class="mat-elevation-z8 table-clientes">
        <ng-container matColumnDef="IdCliente">
          <th mat-header-cell *matHeaderCellDef style="width: 10%; padding-left: 20px !important;">Id.Cliente</th>
          <td mat-cell *matCellDef="let element" style="padding-left: 20px !important;" class="mat-cell-wrap">{{element.IdCliente}}</td>
        </ng-container>
        <ng-container matColumnDef="RazonSocial">
          <th mat-header-cell *matHeaderCellDef>RazonSocial</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap">{{element.RazonSocial}}</td>
        </ng-container>
        <ng-container matColumnDef="PrefCelular">
          <th mat-header-cell *matHeaderCellDef>Pref.Cel</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap">{{element.PrefCelular}}</td>
        </ng-container>
        <ng-container matColumnDef="Celular">
          <th mat-header-cell *matHeaderCellDef>Celular</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap">{{element.Celular}}</td>
        </ng-container>
        <ng-container matColumnDef="Domicilio">
          <th mat-header-cell *matHeaderCellDef>Domicilio</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap">{{element.Domicilio}}</td>
        </ng-container>
        <ng-container matColumnDef="Localidad">
          <th mat-header-cell *matHeaderCellDef>Localidad</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap">{{element.Localidad}}</td>
        </ng-container>
        <ng-container matColumnDef="Provincia">
          <th mat-header-cell *matHeaderCellDef>Provincia</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap">{{element.Provincia}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsClientes"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsClientes;"></tr>
      </table>
    </div>

      <mat-paginator #clientesPaginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>

    <!-- Tabla de Productos -->
    <div *ngIf="productosData.data.length > 0">
      <h5>Productos:</h5>
      <div class="table-clientes-wrapper">   
      <table mat-table [dataSource]="productosData" class="mat-elevation-z8 table-productos">
        <ng-container matColumnDef="Codigo">
          <th mat-header-cell *matHeaderCellDef style="width: 10%; padding-left: 20px !important;">Código</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap" style="padding-left: 10px !important;">{{element.codigo}}</td>
        </ng-container>
        <ng-container matColumnDef="Nombre">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap" style="width: 21%;">
            <div class="nombre-producto">
              {{element.nombre}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="PrecioMostrador">
          <th mat-header-cell *matHeaderCellDef>Precio Mostrador</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap" style="width: 11%;">$ {{element.precio}}</td>
        </ng-container>
        <ng-container matColumnDef="PrecioWeb">
          <th mat-header-cell *matHeaderCellDef>Precio Web</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap" style="width: 11%;">$ {{element.precio_web}}</td>
        </ng-container>
        <ng-container matColumnDef="PrecioInstitucional">
          <th mat-header-cell *matHeaderCellDef>Precio Institucional</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap" style="width: 11%;">$ {{element.precio_institucional}}</td>
        </ng-container>
        <ng-container matColumnDef="PrecioMayorista">
          <th mat-header-cell *matHeaderCellDef>Precio Mayorista</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap" style="width: 11%;">$ {{element.precio_mayorista}}</td>
        </ng-container>
        <ng-container matColumnDef="PrecioVortrek">
          <th mat-header-cell *matHeaderCellDef>Precio Vortrek</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap">$ {{element.precio_vortrek}}</td>
        </ng-container>
        <ng-container matColumnDef="StockDisponible">
          <th mat-header-cell *matHeaderCellDef>Stock Disponible</th>
          <td mat-cell *matCellDef="let element" class="mat-cell-wrap" style="width: 8%;">{{element.stock.data}}</td>
        </ng-container>
        <ng-container matColumnDef="imageUrl">
          <th mat-header-cell *matHeaderCellDef>Imágenes</th>
          <td mat-cell *matCellDef="let element">
            <img [src]="element.imageUrl" width="100" height="100" alt="Imagen">
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsProductos"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsProductos;"></tr>
      </table>
    </div>

      <mat-paginator #productosPaginator [pageSize]="7" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>

import { Component, OnChanges, Input, SimpleChanges, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Observable, of, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { AdministradorDeSubscripcionesService } from '../../../../servicios/administrador-de-subscripciones';

@Component({
  selector: 'app-visor-imagen-zoom',
  templateUrl: './visor-imagen-zoom.component.html',
  styleUrls: ['./visor-imagen-zoom.component.scss']
})
export class VisorImagenZoomComponent implements OnChanges {

  @ViewChild('portlet') portlet: ElementRef;

  @Input('src') src: string;
  @Input('xPos') xPos: number;
  @Input('yPos') yPos: number;
  @Input('height') height: number;
  @Input('width') width: number;
  @Input('activate') activate:boolean;

  private imgHeight : number = 0;
  private imgWidth : number = 0;

  public initialized : boolean = false;
  public shouldMove : boolean = false;
  public error : boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private subscripcionesService : AdministradorDeSubscripcionesService) {
    this.xPos = 50;
    this.yPos = 50;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(isPlatformBrowser(this.platformId)) {
      if(changes.src && changes.src.currentValue != changes.src.previousValue) {
        let imageLoader = new Image();
        let s1 : Subscription = new Observable((o) => {
          imageLoader.onload = (event) => {
            o.next(event);
            o.complete();
          };
          imageLoader.onerror = (error) => {
            o.error(error);
            o.complete();
          };
          imageLoader.src = this.src;
        }).pipe(
          catchError(() => {
            this.error = true;
            this.initialized = true;
            return of(null);
          }),
          finalize(() => {
            this.subscripcionesService.desechar('VisorImagenZoomComponent');
          })
        ).subscribe(
          (event : any) => {
            if(event) {
              this.error = false;
              this.imgHeight = event.target.height;
              this.imgWidth = event.target.width;
              this.initialized = true;
            }
          }
        );
        this.subscripcionesService.agregar('VisorImagenZoomComponent', s1);
      }
      setTimeout(() => {
        if(this.initialized && this.activate) {
          if(!this.error) {
            this.shouldMove = this.imgHeight > 800 && this.imgWidth > 800;
            this.portlet.nativeElement.style.backgroundSize = 'auto';
            this.portlet.nativeElement.style.backgroundImage = this.buildBackground(this.src);
            if (this.shouldMove) {
              let relativeXZoom = this.validPercent(this.xPos * 100 / this.width);
              let relativeYZoom = this.validPercent(this.yPos * 100 / this.height);
              this.portlet.nativeElement.style.backgroundPosition = this.buildPosition(relativeXZoom, relativeYZoom);
            }
            else if(this.imgHeight > this.height || this.imgWidth > this.width) {
              this.portlet.nativeElement.style.backgroundSize = 'contain';
            }
          }
          else {
            this.portlet.nativeElement.style.background = 'none';
          }
        }
      }, 2);
    }
  }

  private validPercent(value) {
    return value < 0 ? 0 : (value > 100 ? 100 : value);
  }

  private buildPosition(xPos: number, yPos: number): string {
    return xPos.toFixed(2) + "% " + yPos.toFixed(2) + "%";
  }

  private buildBackground(src: string): string {
    return "url(" + src + ")";
  }
}

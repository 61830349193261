<div class="faded" *ngIf="ready">
  <div class="barra-utilidades">
    <div class="barra-nombre">
      <h3 class="titulo-principal" *ngIf="rubro">{{rubro.nombre | uppercase}}</h3>
      <h3 class="titulo-principal" *ngIf="esBusqueda">Resultados de: {{textoBusqueda | uppercase}}</h3>
      <h3 class="titulo-principal" *ngIf="esOfertas">OFERTAS</h3>
      <h3 class="titulo-principal" *ngIf="esOutlet">OUTLET</h3>
      <p class="cantidad" *ngIf="productos">({{cantidadTotalResultados}} {{ cantidadTotalResultados == 1 ? 'producto' : 'productos'}})</p>
    </div>
    <div class="barra-controles">
      <ul>
        <li class="ordenar" (click)="abrirOrden()">
          Ordenar
        </li>
        <li class="vista-mosaico" *ngIf="!vistaMosaico" (click)="cambiarVista()">
          Mosaico
        </li>
        <li class="vista-listado" *ngIf="vistaMosaico" (click)="cambiarVista()">
          Listado
        </li>
        <li class="filtrar" (click)="abrirFiltros()" *ngIf="puedeFiltrar">
          Filtrar
        </li>
      </ul>
    </div>
    <div class="barra-filtros-aplicados" *ngIf="puedeFiltrar && hayFiltrosAplicados()">
      <div class="barra-desplazable">
        <button class="badge badge-pill badge-suiza" (click)="quitarFiltro('subrubro')" *ngIf="subRubro">{{subRubro}}</button>
        <button class="badge badge-pill badge-suiza" (click)="quitarFiltro('subsubrubro')" *ngIf="subSubRubro">{{subSubRubro}}</button>
        <button class="badge badge-pill badge-suiza" (click)="quitarFiltro('marca')" *ngIf="marca">{{marca}}</button>
        <button class="badge badge-pill badge-suiza" (click)="quitarFiltro('linea')" *ngIf="linea">{{linea}}</button>
        <button class="badge badge-pill badge-suiza" (click)="quitarFiltro('genero')" *ngIf="genero">{{genero}}</button>
      </div>
      <a class="borrar-todos" [routerLink]="['/rubros', rubro.slug]">Borrar todos los filtros</a>
    </div>
  </div>

  <div class="container mb-4">
    <div class="row">
      <div [ngClass]="{'col-md-4 col-lg-3' : true, 'filtros-desacoplables' : 'true', 'desplegado' : mostrarFiltros}" *ngIf="puedeFiltrar">
        <div class="cabecera">
          <h3>Filtrar por</h3>
          <button class="btn-cerrar-filtros" (click)="abrirFiltros()"></button>
        </div>
        <div class="filtros-aplicados" *ngIf="hayFiltrosAplicados()">
          <p class="titulo">Selección actual:</p>
          <button class="badge badge-pill badge-suiza" (click)="quitarFiltro('subrubro')" *ngIf="subRubro">{{subRubro}}</button>
          <button class="badge badge-pill badge-suiza" (click)="quitarFiltro('subsubrubro')" *ngIf="subSubRubro">{{subSubRubro}}</button>
          <button class="badge badge-pill badge-suiza" (click)="quitarFiltro('marca')" *ngIf="marca">{{marca}}</button>
          <button class="badge badge-pill badge-suiza" (click)="quitarFiltro('linea')" *ngIf="linea">{{linea}}</button>
          <button class="badge badge-pill badge-suiza" (click)="quitarFiltro('genero')" *ngIf="genero">{{genero}}</button>
          <br />
          <a class="borrar-todos" [routerLink]="['/rubros', rubro.slug]">Borrar todos los filtros</a>
          <br />
        </div>
        <app-filtro [titulo]="'Categoría'"
                    [urlBase]="urlBase"
                    [opciones]="subRubros"
                    [opcionSeleccionada]="subRubro"
                    [template]="templateFiltros"
                    [ordenEnTemplate]="1"
                    *ngIf="subRubros?.length > 0">
        </app-filtro>
        <app-filtro [titulo]="'Características'"
                    [urlBase]="urlBase"
                    [opciones]="subSubRubros"
                    [opcionSeleccionada]="subSubRubro"
                    [template]="templateFiltros"
                    [ordenEnTemplate]="2"
                    *ngIf="subSubRubros?.length > 0">
        </app-filtro>
        <app-filtro [titulo]="'Marca'"
                    [urlBase]="urlBase"
                    [opciones]="marcas"
                    [opcionSeleccionada]="marca"
                    [template]="templateFiltros"
                    [ordenEnTemplate]="3"
                    *ngIf="marcas?.length > 0">
        </app-filtro>
        <app-filtro [titulo]="'Línea'"
                    [urlBase]="urlBase"
                    [opciones]="lineas"
                    [opcionSeleccionada]="linea"
                    [template]="templateFiltros"
                    [ordenEnTemplate]="4"
                    *ngIf="lineas?.length > 0">
        </app-filtro>
        <app-filtro [titulo]="'Género'"
                    [urlBase]="urlBase"
                    [opciones]="generos"
                    [opcionSeleccionada]="genero"
                    [template]="templateFiltros"
                    [ordenEnTemplate]="5"
                    *ngIf="generos?.length > 0">
        </app-filtro>
      </div>
      <div [ngClass]="{'orden-desacoplable' : 'true', 'desplegado' : mostrarOrden}">
        <div class="cabecera">
          <h3>Ordenar por</h3>
          <button class="btn-cerrar-orden" (click)="abrirOrden()"></button>
        </div>
        <ul>
          <li [ngClass]="{'seleccionado' : ordenamiento.campo == 'nombre' && !ordenamiento.descendente}"
              (click)="cambiarOrden({campo : 'nombre', descendente : false})">A-Z</li>
          <li [ngClass]="{'seleccionado' : ordenamiento.campo == 'nombre' && ordenamiento.descendente}"
              (click)="cambiarOrden({campo : 'nombre', descendente : true})">Z-A</li>
          <li [ngClass]="{'seleccionado' : ordenamiento.campo == 'precio' && !ordenamiento.descendente}"
              (click)="cambiarOrden({campo : 'precio', descendente : false})">MENOR PRECIO</li>
          <li [ngClass]="{'seleccionado' : ordenamiento.campo == 'precio' && ordenamiento.descendente}"
              (click)="cambiarOrden({campo : 'precio', descendente : true})">MAYOR PRECIO</li>
        </ul>
      </div>
      <div [ngClass]="{'col-12 col-md-8 col-lg-9' : puedeFiltrar, 'col-12' : !puedeFiltrar}">
        <div class="row mt-4 d-none d-md-flex">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <app-breadcrumbs [enlaces]="construirBreadcrumbs()"></app-breadcrumbs>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 col-lg-9">
                <h3 class="titulo-principal" *ngIf="rubro">{{rubro.nombre | uppercase}}</h3>
                <h5 class="titulo-principal" *ngIf="esBusqueda">Resultados de "{{textoBusqueda | uppercase}}"</h5>
                <h3 class="titulo-principal" *ngIf="esDestacados">NOVEDADES</h3>
                <h3 class="titulo-principal" *ngIf="esOfertas">OFERTAS</h3>
                <h3 class="titulo-principal" *ngIf="esOutlet">OUTLET</h3>
                &nbsp; &nbsp;
                <span class="cantidad" *ngIf="productos">
                  ({{cantidadTotalResultados}} {{ cantidadTotalResultados == 1 ? 'producto' : 'productos'}})
                </span>
              </div>
              <div class="col-md-4 col-lg-3 text-right">
                <div class="control-orden">
                  <label>Ordenar por :</label>
                  <select class="selector-orden" [(ngModel)]="ordenamiento" (change)="cambiarOrden()" [compareWith]="compareOpcionesOrden">
                    <option [ngValue]="{campo : 'relevancia', descendente: false}" *ngIf="esBusqueda">Relevancia</option>
                    <option [ngValue]="{campo : 'nombre', descendente : false}">A-Z</option>
                    <option [ngValue]="{campo : 'nombre', descendente : true}">Z-A</option>
                    <option [ngValue]="{campo : 'precio', descendente : false}">MENOR PRECIO</option>
                    <option [ngValue]="{campo : 'precio', descendente : true}">MAYOR PRECIO</option>
                  </select>
                </div>
                <div class="control-vista">
                  <label>Ver :
                    <a [routerLink]="urlBase" [queryParams]="{cantidad : 30}" queryParamsHandling="merge">30</a> / 
                    <a [routerLink]="urlBase" [queryParams]="{cantidad : 40}" queryParamsHandling="merge">40</a> / 
                    <a [routerLink]="urlBase" [queryParams]="{cantidad : 50}" queryParamsHandling="merge">50</a>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 contenedor-productos" *ngIf="cantidadTotalResultados > 0">
            <app-visor-producto-miniatura [esMayorista]="usuario?.esMayorista" [producto]="producto" [vistaComprimida]="!vistaMosaico" *ngFor="let producto of productos"></app-visor-producto-miniatura>
          </div>
          <div class="col-12" *ngIf="cantidadTotalResultados == 0">
            <div class="tarjeta">
              <div class="row align-items-center">
                <div class="col-12 col-md-7 col-lg-8 text-center text-md-left">
                  <h5 class="titulo-principal">NO ENCONTRAMOS PRODUCTOS...</h5>
                  <h6 class="subtitulo-principal" *ngIf="esBusqueda">PRUEBA CAMBIANDO LA BÚSQUEDA</h6>
                  <h6 class="subtitulo-principal" *ngIf="!esBusqueda">PRUEBA QUITANDO ALGUNOS FILTROS</h6>
                </div>
                <div class="col-12 col-md-5 col-lg-4 text-center text-md-right">
                  <img class="img-fluid" src="/assets/imagenes/marmota2.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <ngb-pagination class="d-flex justify-content-center"
                            [maxSize]="5"
                            [page]="paginaActual"
                            [pageSize]="cantidadResultados"
                            [collectionSize]="cantidadTotalResultados"
                            (pageChange)="cambiarPagina($event)"
                            *ngIf="cantidadTotalResultados > cantidadResultados"
            >
              <ng-template ngbPaginationPrevious>
                <span class="btn-anterior">Anterior</span>
              </ng-template>
              <ng-template ngbPaginationNext>
                <span class="btn-siguiente">Siguiente</span>
              </ng-template>
              <ng-template ngbPaginationEllipsis>
                <span class="btn-ellipsis">···</span>
              </ng-template>
              <ng-template ngbPaginationNumber let-page>
                <span class="btn-pagina">{{ page }}</span>
              </ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
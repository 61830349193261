<div class="zoom-container" *ngIf="activate">
  <div #portlet class="zoom-portlet">
    <div [ngClass]="{'contenedor' : true, 'info' : !error && !shouldMove, 'error' : error}" *ngIf="initialized">
      <small *ngIf="!error && !shouldMove">
        Aún no disponemos de una imagen mayor
      </small>
      <small *ngIf="error">No pudimos cargar esta imagen</small>
    </div>
  </div>
</div>

import { Rol } from './rol';
import { Provincia } from './../../modelos/domicilio/provincia';
import { Localidad } from './../../modelos/domicilio/localidad';
import { Barrio } from './../../modelos/domicilio/barrio';
import { FechasFormatter } from './../../formatter/fechas-formatter';

export class Usuario {

  id : number;
  rol : Rol;
  nombre : string;
  apellido : string;
  dni : string;
  email: string;
  solicitaMayorista: boolean;
  esMayorista: boolean;
  fechaNacimiento : Date;
  prefijoTelefono : string;
  telefono : string;
  prefijoCelular : string;
  celular : string;
  calle : string;
  numero : string;
  piso : string;
  departamento : string;
  codigoPostal : string;
  provincia : Provincia;
  localidad : Localidad;
  barrio : Barrio;
  aclaracion : string;
  descuentoWeb : number;

  constructor (data : any = {}) {
    this.id = data.id ? data.id : null;
    this.rol = data.rol ? new Rol(data.rol) : null;
    this.nombre = data.nombre ? data.nombre : null;
    this.apellido = data.apellido? data.apellido : null;
    this.email = data.email ? data.email : null;
    this.solicitaMayorista = data.solicitaMayorista ? true : false;
    this.esMayorista = data.esMayorista ? data.esMayorista : false;
    this.dni = data.dni ? data.dni : null;
    this.fechaNacimiento = data.fechaNacimiento ? FechasFormatter.parsear(data.fechaNacimiento) : null;
    this.prefijoTelefono = data.prefijoTelefono ? data.prefijoTelefono : null;
    this.telefono = data.telefono ? data.telefono : null;
    this.prefijoCelular = data.prefijoCelular ? data.prefijoCelular : null;
    this.celular = data.celular ? data.celular : null;
    this.calle = data.calle ? data.calle : null;
    this.numero = data.numero ? data.numero : null;
    this.piso = data.piso ? data.piso : null;
    this.departamento = data.departamento ? data.departamento : null;
    this.provincia = data.provincia ? new Provincia(data.provincia) : null;
    this.localidad = data.localidad ? new Localidad(data.localidad) : null;
    this.barrio = data.barrio ? new Barrio(data.barrio) : null;
    this.aclaracion = data.aclaracion ? data.aclaracion : null;
    this.codigoPostal = data.codigoPostal ? data.codigoPostal : null;
  }

  public getNombreCompleto() : string {
    return `${this.nombre ? this.nombre : ''} ${this.apellido ? this.apellido : ''}`;
  }

  public tieneTelefono() : boolean {
    return (this.prefijoTelefono &&
            this.prefijoTelefono.trim().length > 0) ||
           (this.telefono && 
            this.telefono.trim().length > 0);
  }

  public tieneCelular() : boolean {
    return (this.prefijoCelular &&
            this.prefijoCelular.trim().length > 0) ||
           (this.celular && 
            this.celular.trim().length > 0);
  }

  public getTelefono() : string {
    let telefono : string = '';
    if(this.prefijoTelefono && this.prefijoTelefono.trim().length > 0) {
      telefono = telefono.concat(this.prefijoTelefono.trim());
    }
    if(this.telefono && this.telefono.trim().length > 0) {
      telefono = telefono.concat(this.telefono.trim());
    }
    return telefono;
  }

  public getCelular() : string {
    let celular : string = '';
    if(this.prefijoCelular && this.prefijoCelular.trim().length > 0) {
      celular = celular.concat(this.prefijoCelular.trim());
    }
    if(this.celular && this.celular.trim().length > 0) {
      celular = celular.concat(this.celular.trim());
    }
    return celular;
  }

  public getTelefonos() : string {
    let telefono : string = '';
    if(this.tieneTelefono()) {
      telefono = this.getTelefono();
    }
    if(this.tieneCelular()) {
      if(telefono.length > 0) {
        telefono = telefono.concat('/');
      }
      telefono = telefono.concat(this.getCelular());
    }
    return telefono;
  }

  public esAdministrador()  {
    return this.rol.nombre == "Administrador";
  }
}
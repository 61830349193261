import { environment } from '../../../environments/environment';

const urlBase = environment.CDN_FOTOS;

export class Foto {

  id : number;
  urlOriginal : string;
  urlMiniatura1 : string;
  urlMiniatura2 : string;
  urlMiniatura3 : string;


  constructor(data : any = {}) {
    this.urlOriginal = data.urlFoto ? data.urlFoto : (data.urlOriginal ? data.urlOriginal : undefined);
    this.urlMiniatura1 = data.urlFotoMiniatura1 ? data.urlFotoMiniatura1 : (data.urlMiniatura1 ? data.urlMiniatura1 : undefined);
    this.urlMiniatura2 = data.urlFotoMiniatura2 ? data.urlFotoMiniatura2 : (data.urlMiniatura2 ? data.urlMiniatura2 : undefined);
    this.urlMiniatura3 = data.urlFotoMiniatura3 ? data.urlFotoMiniatura3 : (data.urlMiniatura3 ? data.urlMiniatura3 : undefined);
  }

  public getUrlOriginal() {
    return `${urlBase}/${this.urlOriginal}`;
  }

  public getUrlMiniatura1() {
    return `${urlBase}/${this.urlMiniatura1}`;
  }

  public getUrlMiniatura2() {
    return `${urlBase}/${this.urlMiniatura2}`;
  }

  public getUrlMiniatura3() {
    return `${urlBase}/${this.urlMiniatura3}`;
  }

  public static extraer(data : any = {}) : Array<Foto> {
    let entidades : Array<Foto> = new Array();
    if(data && data.length > 0) {
      data.forEach((e) => {
        entidades.push(new Foto(e));
      });
    }
    return entidades
  }
}
import { Base } from './base';
import { Marca } from './marca';
import { Linea } from './linea';
import { Genero } from './genero';
import { Caracteristica } from './caracteristica';

export class Rubro extends Base {
  subRubros : Array<Rubro>;
  marcas : Array<Marca>;
  lineas : Array<Linea>;
  generos : Array<Genero>;


  constructor(data : any = {}) {
    super(data);
    this.subRubros = data.subRubros ? Rubro.extraer(data.subRubros) : (data.subSubRubros ? Rubro.extraer(data.subSubRubros) : []);
    this.marcas = Marca.extraer(data.marcas);
    this.lineas = Linea.extraer(data.lineas);
    this.generos = Genero.extraer(data.categorias);
  }

  public static extraer(data : any = {}) : Array<Rubro> {
    let entidades : Array<Rubro> = new Array();
    if(data && data.length > 0) {
      data.forEach((e) => {
        entidades.push(new Rubro(e));
      });
    }
    return entidades
  }
}
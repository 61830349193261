<form [formGroup]="form" (submit)="recuperarCuenta()">
  <h3 class="titulo-principal">RECUPERAR CUENTA</h3>
  <div class="form-group">
    <label>Email</label>
    <input type="email" formControlName="email" [ngClass]="{'form-control' : true, 'is-invalid' : (validar && form.get('email').invalid)}" placeholder="Ingresa tu email" />
    <div  class="invalid-feedback" *ngIf="validar && form.get('email').invalid">
      <span *ngIf="form.get('email').hasError('required')">El campo email es obligatorio</span>
      <span *ngIf="form.get('email').hasError('email')">El campo email no es una dirección de email válida</span>
      <span *ngIf="form.get('email').hasError('invalid')">El campo email no es válido o no pertenece a un usuario registrado</span>
    </div>
  </div>
  <div class="text-center mt-4">
    <button [ngClass]="{'btn btn-suiza btn-pill btn-bold px-5' : true, 'btn-loading' : cargando}"
            [disabled]="cargando"
            type="submit">
      RECUPERAR
    </button>
    <button class="btn btn-link btn-block mt-3" type="button" (click)="volver()">Volver</button>
  </div>
</form>

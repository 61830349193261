import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { LoaderService } from '../../servicios/loader.service';
import { ScrollerService } from '../../servicios/scroller.service';
import { MensajesService } from '../../servicios/mensajes/mensajes.service';
import { ProductosService } from '../../servicios/productos/productos.service';
import { AdministradorDeSubscripcionesService } from '../../servicios/administrador-de-subscripciones';

import { Producto } from '../../modelos/productos/producto';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { AuthService } from 'src/app/servicios/auth/auth.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit, OnDestroy {

  usuario : Usuario = null;

  ready : boolean = false;
  productosDestacadosColumbia : Array<Producto>;
  productosDestacadosVortrek : Array<Producto>;
  productosDestacadosSalomon : Array<Producto>;
  productosDestacadosTNF : Array<Producto>;
  productosDestacados : Array<Producto>;
  productosMasVendidos : Array<Producto>;
  productosOfertas : Array<Producto>;

  constructor(private loaderService : LoaderService,
              private scrollerService : ScrollerService,
              private mensajesService : MensajesService,
              private productosService : ProductosService,
              private authService: AuthService,
              private subscripcionesService : AdministradorDeSubscripcionesService) {
  }

  ngOnInit() {
    this.loaderService.mostrarCargando();
    let s1 : Subscription = this.productosService.obtenerProductosParaHome().subscribe(
      (response : any) => {
        this.productosDestacadosColumbia = Producto.extraer(response.data.articulosDestacadosColumbia.resultados);
        this.productosDestacadosVortrek = Producto.extraer(response.data.articulosDestacadosVortrek.resultados);
        this.productosDestacadosSalomon = Producto.extraer(response.data.articulosDestacadosSalomon.resultados);
        this.productosDestacadosTNF = Producto.extraer(response.data.articulosDestacadosTNF.resultados);

        this.productosMasVendidos = Producto.extraer(response.data.articulosMasVendidos.resultados);
        this.productosDestacados = Producto.extraer(response.data.articulosDestacados.resultados);
        this.productosOfertas = Producto.extraer(response.data.articulosOfertas.resultados);
 
        this.ready = true;
        this.scrollerService.reiniciarScrollVertical();
        this.loaderService.ocultarCargando();
      },
      (error) => {
        console.error(error);
        this.mensajesService.mostrarMensajeTemporal('Parece que esto está tardando demasiado, vuelve a cargar la página');
      }
    );
    this.subscripcionesService.agregar('InicioComponent', s1);

    let s2: Subscription = this.authService.obtenerLogueado(false).subscribe((obtenerLogueado) => {
      this.usuario = obtenerLogueado;
    });
    this.subscripcionesService.agregar('InicioComponent', s2);
  }

  ngOnDestroy() {
    this.subscripcionesService.desechar('InicioComponent');
  }

  public ajustarScrollHorizontal(scroller : string, avanzar : boolean) {
    this.scrollerService.ajustarScrollHorizontal(scroller, avanzar);
  }
}

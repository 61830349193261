import { NgModule, LOCALE_ID, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BrowserModule, BrowserTransferStateModule, TransferState, makeStateKey } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink, HttpLinkHandler } from 'apollo-angular-link-http';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';

import { NgbModule, NgbDate, NgbDateParserFormatter, NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

import { MetaModule, MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ErrorComponent } from './componentes/error/error.component';
import { HeaderComponent } from './componentes/header/header.component';
import { FooterComponent } from './componentes/footer/footer.component';
import { NotificacionCarritoComponent } from './componentes/notificacion-carrito/notificacion-carrito.component';
import { PanelLateralComponent } from './componentes/panel-lateral/panel-lateral.component';
import { BlogComponent } from './componentes/blog/blog.component';
import { EmpresaComponent } from './componentes/empresa/empresa.component';
import { TrabajoComponent } from './componentes/trabajo/trabajo.component';
import { ContactoComponent } from './componentes/contacto/contacto.component';
import { EncuestaComponent } from './componentes/encuesta/encuesta.component';
import { SucursalesComponent } from './componentes/sucursales/sucursales.component';
import { LoginComponent } from './componentes/login/login.component';
import { RegistroComponent } from './componentes/registro/registro.component';
import { RecuperarCuentaComponent } from './componentes/recuperar-cuenta/recuperar-cuenta.component';
import { NuevaContraseniaComponent } from './componentes/nueva-contrasenia/nueva-contrasenia.component';
import { PreguntasFrecuentesComponent } from './componentes/preguntas-frecuentes/preguntas-frecuentes.component';
import { NgbDateCustomParserFormatter } from './formatter/ngb-date-custom-parser-formatter';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { InicioModule } from './componentes/inicio/inicio.module';
import { ProductosModule } from './componentes/productos/productos.module';

import { CustomDatepickerI18nService } from './servicios/custom-datepicker-i18n.service';
import { PanelClientesComponent } from './panel-admin/panel-clientes/panel-clientes.component';
import { NavbarComponent } from './panel-admin/navbar/navbar.component';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { MatInputModule } from '@angular/material/input';



const STATE_KEY = makeStateKey<any>('apollo.state');
const GRAPHQL_URL = environment.API_URL + '/graphql';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PanelLateralComponent,
    NotificacionCarritoComponent,
    BlogComponent,
    EmpresaComponent,
    TrabajoComponent,
    ContactoComponent,
    EncuestaComponent,
    SucursalesComponent,
    LoginComponent,
    RegistroComponent,
    NuevaContraseniaComponent,
    RecuperarCuentaComponent,
    PreguntasFrecuentesComponent,
    ErrorComponent,
    PanelClientesComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserTransferStateModule,
    HttpLinkModule,
    ApolloModule,
    AppRoutingModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    BrowserAnimationsModule,
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: () => {
        return new MetaStaticLoader({
          pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
          pageTitleSeparator: ' - ',
          applicationName: 'Suiza Outdoor',
          defaults: {
            title: 'Sumate a la aventura',
            description: 'Suiza Outdoor since 1888 la trayectoria nos avala, la calidad nos distingue',
            'og:image': `${environment.CDN_FOTOS}/imagenes/logo_suiza.png`,
            'og:type': 'website',
            'og:image:width': '600',
            'og:image:height': '600',
            'og:locale': 'es_AR',
          }
        });
      }
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ProductosModule,
    InicioModule
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  cache: InMemoryCache;
  link: HttpLinkHandler;

  constructor(private readonly apollo: Apollo,
    private readonly transferState: TransferState,
    private readonly httpLink: HttpLink,
    @Inject(PLATFORM_ID) readonly platformId: Object) {

    const isBrowser = isPlatformBrowser(platformId);

    /**
     *
     *
     */
    this.cache = new InMemoryCache();
    this.link = this.httpLink.create({
      uri: GRAPHQL_URL,
      withCredentials: true
    });

    /**
     *
     */
    this.apollo.create({
      link: this.link,
      cache: this.cache,
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all'
        }
      },
      ...(isBrowser ? { ssrForceFetchDelay: 200 } : { ssrMode: true })
    });

    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();
    }
  }

  /**
   *
   *
   */
  onServer() {
    this.transferState.onSerialize(STATE_KEY, () => this.cache.extract());
  }

  /**
   *
   *
   */
  onBrowser() {
    const state = this.transferState.get<NormalizedCacheObject>(
      STATE_KEY,
      null,
    );
    this.cache.restore(state);
  }
}


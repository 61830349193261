import { Provincia } from './provincia';

export class Localidad {
  id : number;
  nombre : string;
  provincia : Provincia;
  codigoPostal : string;

  constructor(data : any = {}) {
    this.id = data.id ? data.id : null;
    this.nombre = data.nombre ? data.nombre : null;
    this.provincia = data.provincia ? new Provincia(data.provincia) : null;
    this.codigoPostal = data.codigoPostal ? data.codigoPostal : null;
  }

  public static extraer(data : any) : Array<Localidad> {
    let localidades = new Array<Localidad>();
    if(data) {
        data.forEach(function (d) {
        localidades.push(new Localidad(d));
      });
    }
    return localidades;
  }
}
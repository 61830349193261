<nav [ngClass]="{'panel-lateral' : true, 'desplegado' : desplegado}">
  <div class="informacion-usuario">
    <ng-container *ngIf="logueado == null">
      <h3>¡Bienvenido!</h3>
      <p>Ingresá a tu cuenta para ver tus compras, devoluciones, y más!</p>
      <button (click)="clickBotonIngresar()" class="btn btn-pill btn-suiza btn-inverse px-5">Ingresá</button>
      &nbsp;&nbsp;
      <button (click)="clickBotonRegistrar()" class="btn btn-pill btn-suiza btn-outline">Regístrate</button>
    </ng-container>
    <ng-container *ngIf="logueado != null">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0">¡Hola {{logueado.getNombreCompleto()}}!</h3>
        <a [routerLink]="['/perfil']" (click)="cerrarMenu()" class="btn btn-sm btn-link btn-white">Perfil</a>
      </div>
      <div class="mt-3">
        <a [routerLink]="['/pedidos']" (click)="cerrarMenu()" class="my-2 btn btn-pill btn-suiza btn-inverse px-5">Compras</a>
        &nbsp;&nbsp;
        <a [routerLink]="['/devoluciones']" (click)="cerrarMenu()" class="my-2 btn btn-pill btn-suiza btn-outline">Devoluciones</a>
      </div>
    </ng-container>
  </div>
  <ul class="menu">
    <li *ngFor="let opcion of menuPrincipal; let i = index" [ngClass]="{'desplegable' : opcion.subopciones?.length > 0, 'colapsado' : !opcion.desplegada}" (click)="desplegar(i)">
      <a class="{{opcion.icono}}" [ngClass]="{'destacado' : opcion.destacada}" [routerLink]="opcion.enlace" (click)="comprobarCierre(opcion)">{{opcion.nombre | uppercase}}</a>
      <ul class="submenu" [ngStyle]="{height : recalcularAltoSubmenu(opcion)}" *ngIf="opcion.subopciones?.length > 0">
        <li>
          <a routerLink="/rubros/armeria">
            <span>ARME</span>
            <span>RÍA</span>
          </a>
        </li>
        <li *ngFor="let subopcion of opcion.subopciones">
          <a [routerLink]="subopcion.enlace" (click)="comprobarCierre(subopcion)">{{subopcion.nombre | uppercase}}</a>
        </li>
      </ul>
    </li>
    <li>
      <a class="icono-ayuda" href="javascript:void(Tawk_API.toggle())" (click)="cerrarMenu()">AYUDA</a>
    </li>
    <li *ngIf="logueado != null">
      <a href="#" class="icono-salir" (click)="clickBotonSalir($event)">SALIR</a>
    </li>
  </ul>
</nav>

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { environment } from '../../../environments/environment';

import { Busqueda } from '../../modelos/productos/busqueda';

const catalogoId = environment.CATALOGO;

const fragments = {
  articuloResumido : gql`
    fragment ArticuloResumido on Articulo {
      id
      codigo
      slug
      nombre
      stock
      marca {
        id
        nombre
      }
      fotosJpg {
        urlFoto
        urlFotoMiniatura1
        urlFotoMiniatura2
        urlFotoMiniatura3
      }
      fotosWebp {
        urlFoto
        urlFotoMiniatura1
        urlFotoMiniatura2
        urlFotoMiniatura3
      }
      tieneDescuentoOferta
      descuentoOferta
      tieneDescuentoOutlet
      descuentoOutlet
      precio
      precioWeb
      precioMayorista
    }
  `
};

const queryArticulos = gql`
  query getArticulos($busqueda:Busqueda) {
    articulos(busqueda:$busqueda) {
      orden {
        campo
        descendente
      }
      paginacion {
        paginaActual
        cantidadResultados
        cantidadTotalPaginas
        cantidadTotalResultados
      }
      resultados {
        ...ArticuloResumido
      }
    }
  }
  ${fragments.articuloResumido}
`;

const queryArticulo = gql`
  query getArticulo($slug:String, $catalogo:Int!) {
    articulo(slug:$slug, catalogo:$catalogo) {
      id
      codigo
      slug
      nombre
      descripcion
      descripcionDetallada
      peso
      enlaceExterno
      marca {
        id
        nombre
        slug
      }
      rubro {
        id
        nombre
        slug
      }
      subRubro {
        id
        nombre
        slug
      }
      subSubRubro {
        id
        nombre
        slug
      }
      linea {
        id
        nombre
        slug
      }
      categoria {
        id
        nombre
        slug
      }
      stock
      tieneDescuentoOferta
      descuentoOferta
      tieneDescuentoOutlet
      descuentoOutlet
      precio
      precioWeb
      precioMayorista
      fotosJpg {
        urlFoto
        urlFotoMiniatura1
        urlFotoMiniatura2
        urlFotoMiniatura3
      }
      fotosWebp {
        urlFoto
        urlFotoMiniatura1
        urlFotoMiniatura2
        urlFotoMiniatura3
      }
      caracteristicas {
        id
        nombre
        disponible
      }
      complementarios {
        ...ArticuloResumido
      }
      relacionados {
        ...ArticuloResumido
      }
    }
  }
  ${fragments.articuloResumido}
`;

const queryHome = gql`
  query getArticulosHome {
    articulosDestacadosVortrek : articulos(busqueda : {
      marca : 105,
      completo : true,
      cantidad : 3,
      catalogo : ${catalogoId}
    }) {
      resultados {
        ...ArticuloResumido
      }
    }
    articulosDestacadosTNF : articulos(busqueda : {
      marca : 504,
      completo : true,
      cantidad : 3,
      catalogo : ${catalogoId}
    }) {
      resultados {
        ...ArticuloResumido
      }
    }
    articulosDestacadosColumbia : articulos(busqueda : {
      marca : 113,
      completo : true,
      cantidad : 3,
      catalogo : ${catalogoId}
    }) {
      resultados {
        ...ArticuloResumido
      }
    }
    articulosDestacadosSalomon : articulos(busqueda : {
      marca : 394,
      completo : true,
      cantidad : 3,
      catalogo : ${catalogoId}
    }) {
      resultados {
        ...ArticuloResumido
      }
    }
    articulosDestacados : articulos(busqueda : {
      destacado : true,
      cantidad : 15,
      catalogo : ${catalogoId}
    }) {
      resultados {
        ...ArticuloResumido
      }
    }
    articulosMasVendidos : articulos(busqueda : {
      masVendido : true,
      cantidad : 15,
      catalogo : ${catalogoId}
    }) {
      resultados {
        ...ArticuloResumido
      }
    }
    articulosOfertas : articulos(busqueda : {
      oferta : true,
      cantidad : 15,
      catalogo : ${catalogoId}
    }) {
      resultados {
        ...ArticuloResumido
      }
    }
  }
  ${fragments.articuloResumido}
`;

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(private apollo : Apollo) {}

  public obtenerProductosParaHome() {
    return this.apollo.watchQuery({
      query : queryHome
    }).valueChanges;
  }

  public obtenerProductos(busqueda : Busqueda) {
    busqueda.catalogo = catalogoId;
    return this.apollo.watchQuery({
      query : queryArticulos,
      variables : {
        busqueda : busqueda
      }
    }).valueChanges;
  }

  public obtenerProducto(slug : string) {
    return this.apollo.watchQuery({
      query : queryArticulo,
      variables : {
        slug : slug,
        catalogo : catalogoId
      }
    }).valueChanges;
  }
}

import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { of, Subscription } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private renderer: Renderer2;

  private mostrandoCargador : boolean = false;
  private timer : Subscription;

  constructor(@Inject(DOCUMENT) private document : Document, private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public mostrarCargando(demora : number = 200) {
    if(!this.mostrandoCargador && this.timer == null) {
      if(demora > 0) {
        this.timer = of(1).pipe(
          delay(demora),
          finalize(() => {
            this.clearTimer();
          })
        ).subscribe(() => {
          this.mostrandoCargador = true;
          this.renderer.addClass(this.document.body, 'loading');
        });
      }
      else {
        this.mostrandoCargador = true;
        this.renderer.addClass(this.document.body, 'loading');
        this.clearTimer();
      }
    }
  }

  public ocultarCargando() {
    this.mostrandoCargador = false;
    this.renderer.removeClass(this.document.body, 'loading');
    this.clearTimer();
  }

  private clearTimer() {
    if(this.timer) {
      this.timer.unsubscribe();
      this.timer = null;
    }
  }
}

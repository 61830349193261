import { Component, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss']
})
export class FiltroComponent implements OnChanges {

  @Input() titulo : string;
  @Input() urlBase : Array<string>;
  @Input() template : Array<string>;
  @Input() ordenEnTemplate : number;

  @Input() opciones : Array<any>;
  @Input() opcionSeleccionada : any;

  private prefijo : string;
  private indicePrefijo : number;
  private nuevoIndicePrefijo : number;

  public colapsado : boolean = false;

  constructor() {

  }

  ngOnChanges() {
    this.prefijo = this.template[this.ordenEnTemplate];
    this.indicePrefijo = this.urlBase.indexOf(this.prefijo);
    if(this.indicePrefijo === -1) {
      for(let i = this.ordenEnTemplate; i >= 0; i--) {
        let nidx = this.urlBase.indexOf(this.template[i]);
        if(nidx !== -1) {
          this.nuevoIndicePrefijo = nidx + 2;
          break;
        }
      }
    }
  }

  crearEnlace(opcion : any = null) : Array<string> {
    let enlace : Array<string> = Array.from(this.urlBase);
    if(this.indicePrefijo === -1) {
      // si el prefijo no existe en la url de base lo insertamos junto con 
      // el slug de la opcion
      enlace.splice(this.nuevoIndicePrefijo, 0, this.prefijo, opcion.slug);
    }
    else {
      // si el prefijo existe en la url de base, reemplazamos el valor de su slug
      // por el slug de la opcion actual y eliminamos el resto de los
      // filtros aplicados a continuacion
      let idx = this.indicePrefijo + 1;
      let endidx = enlace.length - idx;
      enlace.splice(idx, endidx, opcion.slug);
    }
    return enlace;
  }

  colapsar() {
    this.colapsado = !this.colapsado
  }
}

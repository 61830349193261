import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { ImagenCargaDiferidaModule } from '../../directives/imagen-carga-diferida/imagen-carga-diferida.module';

import { VisorProductoMiniaturaModule } from '../productos/visor-producto-miniatura/visor-producto-miniatura.module';
import { InicioRoutingModule } from './inicio-routing.module';

import { InicioComponent } from './inicio.component';

import { ProductosService } from '../../servicios/productos/productos.service';


@NgModule({
  declarations: [
    InicioComponent
  ],
  imports: [
    CommonModule,
    NgbCarouselModule,
    ImagenCargaDiferidaModule,
    VisorProductoMiniaturaModule,
    InicioRoutingModule
  ]
})
export class InicioModule { }

import { Base } from './base';
export class Caracteristica extends Base {

  disponible : number;

  constructor(data : any = {}) {
    super(data);
    this.disponible = data.disponible ? data.disponible : undefined;
  }

  public esTalleUnico() : boolean {
    return this.toString() === 'TALLE UNICO';
  }

  public tieneStock() : boolean {
    return this.disponible > 0;
  }

  public tieneMasStock(cantidad : number) : boolean {
    return this.disponible > cantidad;
  }

  public static compareTo(a : Caracteristica, b : Caracteristica) : boolean {
    return super.compareTo(a, b);
  }

  public toString() : string {
    return this.nombre.toUpperCase();
  }

  public static extraer(data : any = {}) : Array<Caracteristica> {
    let entidades : Array<Caracteristica> = new Array();
    if(data && data.length > 0) {
      data.forEach((e) => {
        entidades.push(new Caracteristica(e));
      });
    }
    return entidades
  }
}
<div [ngClass]="{'contenedor-producto' : true,
                 'comprimido' : vistaComprimida,
                 'relacionado' : vistaRelacionados,
                 'destacado' : vistaDestacada,
                 'mas-vendido' : vistaMasVendido,
                 'oferta' : vistaOferta,
                 'bordered' : vistaConBorde }"
>
  <div class="producto">
    <span class="upper-badge oferta" *ngIf="producto.tieneDescuento()">-{{producto.descuentoFormato()}}%</span>
    <div class="imagenes">
      <div class="wrapper-imagen">
        <picture *ngIf="producto.tieneFotos()">
          <source [srcset]="producto.fotosWebp[0].getUrlMiniatura2()" type="image/webp">
          <img [src]="producto.fotosJpg[0].getUrlMiniatura2()"
               alt="{{producto.nombre}}"
               width="210"
               height="210"
               srcDefecto="/assets/imagenes/logo2.svg"
               [routerLink]="['/', producto.slug]"
               diferir
        >
        </picture>
        <img *ngIf="!producto.tieneFotos()"
             src="/assets/imagenes/logo2.svg"
             alt="{{producto.nombre}}"
             width="210"
             height="210"
             [routerLink]="['/', producto.slug]"
        >
      </div>
    </div>
    <div class="detalles">
      <a [routerLink]="['/', producto.slug]">VER DETALLE</a>
    </div>
    <div class="informacion">
      <p class="marca">{{producto.marca.nombre | uppercase}}</p>
      <a class="nombre" [routerLink]="['/', producto.slug]">{{producto.nombre | uppercase}}</a>
      <hr />
      <p class="precio-web" *ngIf="producto.stock && !esMayorista">${{producto.precioWebFormato()}}</p>
      <p class="precio-web" *ngIf="producto.stock && esMayorista">${{producto.precioMayoristaFormato()}} <br></p>
      <a class="precio-web" [routerLink]="['/', producto.slug]" *ngIf="!producto.stock">CONSULTAR</a>
    </div>
  </div>
  <div class="btn-destacado text-center">
    <a class="btn btn-pill btn-bold btn-suiza" [routerLink]="['/', producto.slug]">¡LO QUIERO!</a>
  </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private static readonly ENDPOINT_URL = environment.API_URL;
  private static readonly HEADERS = environment.API_HEADERS;
  private static MULTIPART_HEADERS = environment.API_HEADERS;

  constructor(private http : HttpClient) {
    ApiService.MULTIPART_HEADERS = {...ApiService.HEADERS};
    delete ApiService.MULTIPART_HEADERS['Content-Type'];
  }

  public post(url : string, data?: any) {
    return this.http.post(
      ApiService.ENDPOINT_URL + url, 
      JSON.stringify(data), 
      {
        headers : ApiService.HEADERS,
        observe : 'response',
        withCredentials : true
      },
    );
  }

  public postMultipart(url : string, data?: FormData) {
    return this.http.post(
      ApiService.ENDPOINT_URL + url, 
      data,
      {
        headers : ApiService.MULTIPART_HEADERS,
        observe : 'response',
        withCredentials : true
      },
    );
  }

  public put(url : string, data?: any) {
    return this.http.put(
      ApiService.ENDPOINT_URL + url, 
      JSON.stringify(data), 
      {
        headers : ApiService.HEADERS,
        observe : 'response',
        withCredentials : true
      },
    );
  }  

  public get (url : string) {
    return this.http.get(
      ApiService.ENDPOINT_URL + url,
      {
        headers : ApiService.HEADERS,
        observe : 'response',
        withCredentials : true
      },
    );
  }

  public delete (url : string) {
    return this.http.delete(
      ApiService.ENDPOINT_URL + url,
      {
        headers : ApiService.HEADERS,
        observe : 'response',
        withCredentials : true
      },
    );
  }
}

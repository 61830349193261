<div class="container mt-3">
  <h3 class="titulo-principal">PREGUNTAS FRECUENTES</h3>
  <div class="tarjeta">
    <h5 class="titulo">FORMAS DE PAGO</h5>
    <p>Aceptamos todas las formas de pago, Efectivo, Tarjeta de credito (Visa, Master, Naranja, Cordobesa).</p>
  </div>
  <div class="tarjeta">
    <h5 class="titulo">POLÍTICAS DE CAMBIO Y DEVOLUCIÓN</h5>
    <p>Los productos pueden estar sujetos a cambios o devoluciones, en los siguientes casos:</p>
    <ol>
      <li>El producto recibido no corresponde con el producto pedido.</li>
      <li>El talle del producto recibido no corresponde con el talle del cliente.</li>
      <li>El producto adquirido está roto o fallado, al momento de recibirlo.</li>
      <li>El producto adquirido presenta un defecto de fabricación, luego de comenzar a usarlo.</li>
    </ol>
  </div>
  <div class="tarjeta">
    <h5 class="titulo">CAMBIOS</h5>
    <p>
      Para cambiar un producto que no presenta fallas (caso 1 y 2):
      El cliente puede cambiarlo dentro de los 30 días corridos, desde la fecha en que recibió el producto. Condiciones: Conservación de características similares a las que poseía el producto al momento en que fue recibido (etiquetas – embalaje de origen – factura – manual, y demás accesorios). Los costos de envío del primer cambio, serán gratuitos para el cliente. De ser necesario cambios adicionales, el costo de envío será a cargo íntegramente del comprador.
    </p>
    <p>
      Para cambiar un producto que presenta fallas (caso 3 y 4):
      En caso de defectos de fábrica el cliente posee 180 días corridos, desde la fecha de recepción, para reclamar ese producto y gozar de la garantía de fábrica. Se requiere aprobación previa del proveedor/fabricante y de Suiza Outdoor S.R.L para solicitar el cambio.
    </p>
  </div>
  <div class="tarjeta">
    <h5 class="titulo">DEVOLUCIONES</h5>
    <p>
      El consumidor posee un plazo de 10 días corridos contados a partir de que se entregue el producto o se celebre el contrato, lo último que ocurra, para revocar la aceptación de la compra. En ese caso, el consumidor devolverá el producto sin indicios de uso y en idénticas condiciones a las que poseía al momento de recepción del mismo (etiquetas – embalaje de origen – factura – manual, y demás accesorios). Todo ello según Art. 34 de la Ley de Defensa del Consumidor, y artículos concordantes del Código Civil y Comercial de la Nación.
    </p>
  </div>
  <div class="tarjeta">
    <h5 class="titulo">MAYORISTAS</h5>
    <p>Para validar tu cuenta mayorista necesitamos la siguiente documentación que puedes adjuntar en tu perfil:</p>
    <ol>
      <li>Formulario de adhesión completo.</li>
      <li>Copia del DNI del titular.</li>
      <li>Constancia de Inscripción en Afip.</li>
      <li>Constancia de Ingresos Brutos.</li>
    </ol>
  </div>
</div>

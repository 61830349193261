<footer>
  <div class="divisor">
    <h1>{{anios}} años de trayectoria nos avalan<span class="d-none d-sm-inline">, la calidad nos distingue</span></h1>
  </div>
  <div class="pie">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
          <p class="titulo-seccion">INFORMACIÓN</p>
          <ul>
            <li>
              <a routerLink="/empresa">Empresa</a>
            </li>
            <li>
              <a routerLink="/sucursales">Sucursales</a>
            </li>
            <li>
              <a routerLink="/contacto">Franquicias</a>
            </li>
            <li>
              <a routerLink="/preguntas-frecuentes">Preguntas frecuentes</a>
            </li>
            <li>
              <a routerLink="/trabajo">Trabajá con nosotros</a>
            </li>
            <li>
              <a href="javascript:void(Tawk_API.toggle())">¿Necesitás ayuda?</a>
            </li>
            <li>
              <a routerLink="/devoluciones">Devoluciones</a>
            </li>
            <li>
              <a href="https://tiendavirtual.suizaoutdoor.com.ar/llama.aspx">Vendedor virtual</a>
            </li>
            <li>
              <a routerLink="encuesta">Encuesta</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
          <p class="titulo-seccion">
            <a routerLink="/sucursales">CASA CENTRAL</a>
          </p>
          <p>
            Rivadavia 302 esq. Lima<br />
            (0351) 4210006/7
          </p>
          <p>
            Lunes a viernes: 9:00 a 18:00<br />
            Sábados: 09:00 a 14:00
          </p>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
          <p class="titulo-seccion">
            <a routerLink="/sucursales">SUCURSALES</a>
          </p>
          <p class="titulo-sucursal">PEATONAL CASEROS</p>
          <p>
            Caseros 44
          </p>
          <p class="titulo-sucursal">AV. EMILIO OLMOS</p>
          <p>
            Av. Emilio Olmos 100 esq. Rivadavia
          </p>
          <p class="titulo-sucursal">OUTLET</p>
          <p>
            Rivadavia 222 Subsuelo
          </p>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
          <p class="titulo-seccion">
            <a routerLink="/contacto">CONTACTO</a>
          </p>
          <p>
            <a href="mailto:tiendavirtual@suizaoutdoor.com.ar">tiendavirtual@suizaoutdoor.com.ar</a>
          </p>
          <p class="titulo-seccion mt-4">SEGUÍNOS</p>
          <div class="enlaces-sociales">
            <a class="enlace-facebook" href="https://www.facebook.com/Suiza.outdoor" target="blank" aria-label="Encontranos en Facebook"></a>
            <a class="enlace-twitter" href="https://twitter.com/SUIZAOUTDOOR" target="blank" aria-label="Encontranos en Twitter"></a>
            <a class="enlace-youtube" href="https://www.youtube.com/channel/UCz3t8EXWeny9E2_KC9EntsQ" target="blank" aria-label="Encontranos en YouTube"></a>
            <a class="enlace-instagram" href="https://www.instagram.com/suizaoutdoor/" target="blank" aria-label="Encontranos en Instagram"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<form [formGroup]="form" (submit)="login()">
  <h3 class="titulo-principal">INGRESÁ</h3>
  <div class="form-group">
    <label>Email</label>
    <input type="email" formControlName="email" [ngClass]="{'form-control' : true, 'is-invalid' : (validar && form.get('email').invalid)}" placeholder="Ingresa tu email" />
    <div class="invalid-feedback" *ngIf="validar && form.get('email').invalid">
      <span *ngIf="form.get('email').hasError('required')">El campo email es obligatorio</span>
      <span *ngIf="form.get('email').hasError('email')">El campo email no es una dirección de email válida</span>
    </div>
  </div>
  <div class="form-group">
    <label>Contraseña</label>
    <input type="password" formControlName="contrasenia" [ngClass]="{'form-control' : true, 'is-invalid' : (validar && form.get('contrasenia').invalid)}" placeholder="Contraseña"/>
    <div class="invalid-feedback" *ngIf="validar && form.get('contrasenia').hasError('required')">
      El campo contraseña es obligatorio
    </div>
  </div>
  <div class="invalid-feedback d-block" *ngIf="error">{{error}}</div>
  <div class="text-center mt-4">
    <button [ngClass]="{'btn btn-suiza btn-pill btn-bold px-5' : true, 'btn-loading' : cargando}"
            [disabled]="cargando"
            type="submit"
    >
      INGRESAR
    </button>
  </div>
  <hr>
  <div class="text-center mb-3">
    <p class="text-help">
      ¿Todavía no tenés una cuenta?
      <button class="btn btn-sm btn-link" type="button" (click)="registrarme()">Regístrate</button>
    </p>
    <p class="text-help">
      <button class="btn btn-sm btn-link" type="button" (click)="recuperar()">¿Olvidaste la contraseña?</button>
    </p>
  </div>
</form>


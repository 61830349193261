import { Component, OnInit, OnDestroy, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { RESPONSE, REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';
import { Request, Response } from 'express';

import { Subscription, throwError } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { AdministradorDeSubscripcionesService } from '../../servicios/administrador-de-subscripciones';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  public status : number = 0;

  constructor(private router : Router,
              private ruta : ActivatedRoute,
              private subscripcionesService : AdministradorDeSubscripcionesService,
              @Optional() @Inject(REQUEST) private request: Request,
              @Optional() @Inject(RESPONSE) private response: Response,
              @Inject(PLATFORM_ID) private platformId: any) {

  }

  ngOnInit() : void {
    let s1 : Subscription = this.ruta.queryParams.subscribe(params => {
      if(params && params.status) {
        this.status = <number>params.status;
        if (isPlatformServer(this.platformId)) {
          this.response.status(params.status);
        }
      }
    });
    this.subscripcionesService.agregar('ErrorComponent', s1);
  }

  ngOnDestroy() : void {
    this.subscripcionesService.desechar('ErrorComponent');
  }
}

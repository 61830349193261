import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../servicios/auth/auth.service';
import { AdministradorDeSubscripcionesService } from '../../servicios/administrador-de-subscripciones';

import { Usuario } from '../../modelos/usuario/usuario';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Input() email : string;
  @Output() cambiarModal = new EventEmitter<any>();

  public form : FormGroup;
  public validar = false;
  public error : string = '';
  public cargando : boolean = false;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private subscripcionesService : AdministradorDeSubscripcionesService) {

    this.form = this.formBuilder.group({
        email: ['', Validators.compose([Validators.required, Validators.email])],
        contrasenia: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.form.get('email').setValue(this.email);
  }

  ngOnDestroy() {
    this.subscripcionesService.desechar('LoginComponent');
  }

  login() {
    this.validar = true;
    if (this.form.valid && !this.cargando) {
      this.cargando = true;

      let email : string = this.form.get("email").value.trim();
      let contrasenia : string = this.form.get("contrasenia").value;

      let s1 : Subscription = this.authService.login(email, contrasenia).pipe(
        finalize(() => {
         this.cargando = false;
        })
        ).subscribe(
          response => {
            this.cambiarModal.emit(null);
            window.location.reload();
        },
        error => {
          switch (error.status) {
            case 400:
            case 401:
              this.error="El email y/o la contraseña no coinciden";
            break;
            default:
              this.error = 'Ocurrió un error al iniciar sesión, vuelve a intentarlo';
            break;
          }
        }
      );
      this.subscripcionesService.agregar('LoginComponent', s1);
    }
  }

  recuperar() {
    this.cambiarModal.emit({
      modal : 'Recuperar',
      email : this.form.get('email').value
    });
  }

  registrarme() {
    this.cambiarModal.emit('Registro');
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { throwError, Subscription } from 'rxjs';

import { Rubro } from '../../../modelos/productos/rubro';

import { LoaderService } from '../../../servicios/loader.service';
import { ScrollerService } from '../../../servicios/scroller.service';
import { MensajesService } from '../../../servicios/mensajes/mensajes.service';
import { RubrosService } from '../../../servicios/productos/rubros.service';
import { AdministradorDeSubscripcionesService } from '../../../servicios/administrador-de-subscripciones';

@Component({
  selector: 'app-rubros',
  templateUrl: './rubros.component.html',
  styleUrls: ['./rubros.component.scss']
})
export class RubrosComponent implements OnInit, OnDestroy {

  public rubros : Array<Rubro>;

  constructor(private router : Router,
              private loaderService : LoaderService,
              private scrollerService : ScrollerService,
              private mensajesService : MensajesService,
              private rubrosService : RubrosService,
              private subscripcionesService : AdministradorDeSubscripcionesService) {

  }

  ngOnInit() : void {
    this.loaderService.mostrarCargando();
    let s1 : Subscription = this.rubrosService.obtenerRubros().pipe(
    ).subscribe(
      (response : any) => {
        if(response.data.rubros) {
          this.rubros = Rubro.extraer(response.data.rubros);
          this.scrollerService.reiniciarScrollVertical();
          this.loaderService.ocultarCargando();
        }
        else {
          return throwError(response.errors);
        }
      },
      (errors) => {
        console.error(errors);
        this.loaderService.ocultarCargando();
        this.mensajesService.mostrarMensajeTemporal('Parece que esto está tardando demasiado, vuelve a cargar la página');
      }
    );
    this.subscripcionesService.agregar('RubrosComponent', s1);
  }

  ngOnDestroy() : void {
    this.subscripcionesService.desechar('RubrosComponent');
  }
}

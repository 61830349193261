export class Provincia {
  id : number
  nombre : string;

  constructor(data : any = {}) {
    this.id = data.id;
    this.nombre = data.nombre;
  }

  public static extraer(data : any) : Array<Provincia> {
    let provincias = new Array<Provincia>();
    if(data) {
      data.forEach(function (d) {
        provincias.push(new Provincia(d));
      });
    }
    return provincias;
  }

  public static compareTo(a : Provincia, b : Provincia) : boolean {
    return a && b ? a.id === b.id : a === b;
  }
}

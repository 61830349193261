<ng-container *ngIf="currentRoute !== '/panel-clientes'; else panelClientesTemplate">
  <app-header [menuPrincipal]="menuPrincipal"
              [menuSecundario]="menuSecundario"
              [menuDesplegado]="panelLateralMenuDesplegado"
              [textoBusqueda]="textoBusqueda"
              [logueado]="logueado"
              (clickMenu)="clickBotonMenu()"
              (clickCarrito)="clickBotonCarrito()"
              (clickAbrirBusqueda)="clickBotonAbrirBusqueda()"
              (clickBuscar)="clickBotonBuscar($event)"
              (clickIngresar)="clickBotonIngresar()"
              (clickRegistrar)="clickBotonRegistrar()"
              (clickSalir)="clickBotonSalir()">
  </app-header>
  <app-panel-lateral [menuPrincipal]="menuLateralPrincipal"
                     [desplegado]="panelLateralMenuDesplegado"
                     [logueado]="logueado"
                     (clickIngresar)="clickBotonIngresar()"
                     (clickRegistrar)="clickBotonRegistrar()"
                     (clickSalir)="clickBotonSalir()"
                     (informarCierre)="informarCierreMenu()">
  </app-panel-lateral>
  <div class="contenedor-principal">
    <div class="contenido">
      <router-outlet></router-outlet>
    </div>
    <div class="decoraciones">
      <picture *ngIf="mostrarDecoracion">
        <source srcset="/assets/imagenes/pie-montania.webp" type="image/webp">
        <img src="/assets/imagenes/pie-montania.jpeg" alt="SUIZA OUTDOOR® -Since 1888" width="1920" height="800" diferir>
      </picture>
      <div class="suiza-since">
        <h1>SUIZA OUTDOOR®</h1>
        <hr />
        <h2>Since 1888</h2>
        <button class="btn-volver-arriba" (click)="clickBotonVolverArriba()">VOLVER ARRIBA</button>
      </div>
    </div>
  </div>
</ng-container>

<div class="floating-bottom-bar">
  <a href="https://wa.me/5493516119395" target="_blank" rel="noreferrer" class="btn-whatsapp">
    <img src="/assets/imagenes/whatsapp.svg" alt="WhatsApp Icon" style="width: 30px; height: auto;">
  </a>
</div>
<app-notificacion-carrito></app-notificacion-carrito>
<app-footer></app-footer>

<ng-template #modalCuentaUsuario let-modal>
  <div class="modal-body" [ngSwitch]="componenteModal">
    <button type="button" class="close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <app-login 
      *ngSwitchCase="'Login'"
      [email]="emailModal"
      (cambiarModal)="cambiarModal($event)">
    </app-login>
    <app-registro 
      *ngSwitchCase="'Registro'"
      (cambiarModal)="cambiarModal($event)">
    </app-registro>
    <app-recuperar-cuenta 
      *ngSwitchCase="'Recuperar'"
      [email]="emailModal"
      (cambiarModal)="cambiarModal($event)">
    </app-recuperar-cuenta>
    <app-nueva-contrasenia 
      *ngSwitchCase="'Reiniciar'"
      [token]="tokenRecuperacion"
      (cambiarModal)="cambiarModal($event)">
    </app-nueva-contrasenia>
  </div>
</ng-template>

<ng-template #panelClientesTemplate>
  <router-outlet></router-outlet>
</ng-template>

import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts : Array<string> = value.trim().split('/');
      if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
        return {year: null, month : null, day: parseInt(dateParts[0])};
      } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
        return {year: null, month: parseInt(dateParts[1]), day: parseInt(dateParts[0])};
      } else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
        return {year: parseInt(dateParts[2]), month: parseInt(dateParts[1]), day: parseInt(dateParts[0])};
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
        `${Number.isInteger(date.day) ? this.padNumber(date.day) : ''}/${Number.isInteger(date.month) ? this.padNumber(date.month) : ''}/${date.year}` :
        '';
  }

  private padNumber(numero : number) : string {
    return (numero < 10) ? '0'.concat(numero.toString()) : numero.toString();
  }

  private isNumber(cadena : string) : boolean {
    let expression = new RegExp('\d');
    return expression.test(cadena);
  }
}
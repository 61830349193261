<div class="container mt-3">
  <h3 class="titulo-principal">BLOG</h3>

  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Eclipse Lunar 27 de Julio 2018</div>
  <div class="copete">Es el segundo que ocurre en el año y es el que posee la mayor duración del siglo XXI.
  </div>
  <div class="descripcion"><br>
  ¿Qué es un eclipse lunar?<br>
  Los eclipses lunares ocurren cuando el satélite terrestre pasa por la sombra de la Tierra, lo que no sucede todos los meses, porque la órbita de la Luna está inclinada con respecto a la de la Tierra-Sol (eclíptica).<br>
  <br>
  A diferencia de los eclipses solares, los lunares son visibles desde cualquier lugar del mundo, una vez que la Luna está sobre el horizonte en el momento del eclipse.<br>
  <br>
  <br>
  <br>
  El eclipse lunar que se desarrolla el viernes 27 de julio será el segundo de su tipo en 2018 y el más largo del siglo XXI, dado que su fase de apogeo durara 1 hora y 42 minutos. El horario del punto máximo del fenómeno varía según la ubicación geográfica:<br>
  <br>
  Argentina, Uruguay y Brasil (Río, San Pablo y Brasilia) 17:21 hs<br>
  México, Perú y Colombia 15:21 hs<br>
  Chile, Bolivia y Estados Unidos (ET) 16:21 hs<br>
  España, Francia e Italia 22:21 hs<br>
  <br>
  Desde Argentina no se podrá apreciar el apogeo, sino que sólo la fase final y la penumbra posterior a la misma. El cronograma de desarrollo del mismo totaliza 6 horas y 14 minutos:<br>
  <br>
  Inicio de la penumbra: 14:14:47 hora de Argentina<br>
  Inicio de eclipse parcial: 15:24:27 hs<br>
  Inicio de eclipse total: 16:30:15 hs<br>
  Punto máximo del eclipse: 17:21:44 hs<br>
  Finalización de eclipse total: 18:13:11 hs<br>
  Fin de eclipse parcial: 19:19:00 hs<br>
  Fin de la penumbra: 20:28:38 hs<br>
  <br>
  Luna de Sangre<br>
  De acuerdo con la NASA, el mejor lugar del mundo para observar el eclipse será el Océano Índico. Se podrá comprobar que la Luna no desaparece de la vista sino que adquiere una tonalidad rojiza.<br>
  <br>
  Esto se debe a que la atmósfera de la Tierra, que se extiende unos 80 kilómetros más allá del diámetro de nuestro planeta, actúa como una lente que desvía la luz del Sol y filtra eficazmente sus componentes azules, de manera que deja pasar solo la luz roja que será reflejada por el satélite. Así, la Luna adquiere el resplandor cobrizo tan característico.<br>
  <br>
  No hace falta protección para ver<br>
  Al contrario que con los eclipses de Sol, para ver este eclipse lunar no hace falta ninguna protección ocular. No obstante, es recomendable utilizar prismáticos y telescopios para disfrutar mejor del fenómeno.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Sistema de vestimenta por capas.</div>
  <div class="copete">
  Las actividades en la montaña se han popularizado, suponen aire fresco y aventurero para muchos, pero a la hora de lanzarnos al outdoor hay que considerar si llevamos la vestimenta de características técnicas y en combinación adecuada.</div>
  <div class="descripcion"><br>
  <br>
  Es importante saber cómo vestirse para la montaña, utilizando el sistema de las 3 capas, que además de ofrecerte la comodidad y confort cuando realzamos la actividad, también sigamos parámetros de seguridad en caso de condiciones climatológicas adversas.<br>
  Este sistema de las tres capas se ha llamado también el de la cebolla, puesto que vamos superponiendo capas, como una cebolla, protegiéndonos del frío o cualquier condición adversas que encontremos.<br>
  <br>
  Vestir prendas con ciertas características técnicas cuando estamos en plena actividad al aire libre permite mantener el cuerpo seco y evitar que el sudor se condense; además, evita que la lluvia, la nieve o el fuerte viento haga contacto con la piel. Ello además de obvio beneficio en términos de confort y comodidad, permite realizar la actividad con mayor seguridad. No hay que olvidar que nos encontramos muchas veces en ambientes extremos.<br>
  <br>
  Lo más importante es que, como capas de una cebolla, la ropa montaña sea sencilla de retirar si el nivel de la actividad o las condiciones climáticas te obligan a ello.<br>
  <br>
  El objetivo es bastante claro: ofrecer comodidad, lo que requiere además, mantener cálido al montañista; transpirabilidad, ligereza e impermeabilidad.<br>
  <br>
  <br>
  1.- Primera capa:<br>
  Conocida como la segunda piel. Es aquella que está más ceñida al cuerpo.<br>
  Es fundamental que esta primera capa sea capaz de absorber el sudor y expulsarlo, para mantener al montañista seco de la humedad que produce tu propio cuerpo a través de la sudoración. Necesitamos que esta capa sea transpirable.<br>
  <br>
  Las prendas de este tipo son camisetas térmicas compuestas por fibras sintéticas como el poliéster, clorofibra o polipropileno.<br>
  <br>
  Es fundamental evitar a toda costa utilizar ropa interior de algodón, pues el mismo retiene humedad y sudor, lo cual acarrearía ciertas complicaciones durante la actividad en el outdoor.<br>
  <br>
  2.- Segunda capa:<br>
  O también denominada capa media, capaz de conservar el calor. Es aquella que acumula entre sus fibras la mayor cantidad de aire, para que se vaya calentando con el calor corporal.<br>
  <br>
  De esta manera estaría protegiendo a quien la utiliza del frío extremo. Y, hay quienes aseguran que, mientras más gruesa sea mayor es la capacidad de abrigar.<br>
  <br>
  No obstante, no debes abusar del grosor porque provocaría sudoración excesiva, lo que generará una sensación de incomodidad.<br>
  <br>
  Las prendas que pueden ser utilizadas son los conocidos forros polares y como una segunda segunda capa, podemos utilizar algún tipo de abrigo de tipo sntético o pluma (Ver blog anterior #¿Abrigo de pluma o sintético?)<br>
  <br>
  3.- Tercera capa<br>
  Es aquella utilizada para contrarrestar los efectos de los elementos naturales.<br>
  Las costuras de esta vestimenta deben estar selladas para impedir que ingresen hasta el cuerpo agua o nieve.<br>
  Así que protege de la lluvia, nieve y del viento, cosa que no hacen las prendas de la segunda capa.<br>
  Son usados para tales efectos los tejidos impermeables que, en su mayoría, suelen ser transpirables, como los Gore Tex, DryVent, OmniTech, Outdry...<br>
  <br>
  Muchos expertos aseguran que es la capa más indispensable, pero tiene que ser lo suficientemente transpirable para evitar que el cuerpo humano quede empapado de sudor, lo que podría causar en condiciones extremos, incluso, hasta la hipotermia, lo que resulta ser en extremo peligroso.<br>
  <br>
  Otra característica fundamental es que debe estar diseñada a prueba de desgarros, pues como está expuesta al exterior mantendrá contacto con cualquier objeto que presente el escenario natural como ramas o rocas.<br>
  <br>
  Cuando nos sobre ropa o cuando las condiciones climáticas así lo precisan debemos guardar la segunda capa. El montañista por lo general lleva siempre consigo una mochila para resguardar cualquier prenda u objeto.<br>
  <br>
  Para mayor asesoramiento, buscanos en nuestras 5 sucursales en la Ciudad de Córdoba o a través de nuestro Chat-Online de Suiza Outdoor Since 1888®</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">¿Como nos vestimos para la nieve?</div>
  <div class="copete">
  ¿Impermeable?, ¿resistente al agua?, ¿en capas? Claves de vestimenta para no pasarlo mal en una escapada a la nieve.</div>
  <div class="descripcion"><br>
  <br>
  <br>
  Mojado, con frío y con viento helado golpeándonos la cara podemos pasarla muy mal en una escapada a la nieve. Por eso, es clave estar al menos medianamente equipados, para disfrutar sin sufrir las consecuencias.<br>
  <br>
  Estilo cebolla: para la nieve y fríos extremos, se recomienda vestirse “en capas”. Primero va una capa interior o “primera piel”, constituida por ropa que absorba el sudor y lo expulse al exterior para facilitar su evaporación. Lo mejor son las fibras sintéticas como como poliester, polipropileno, clorofibra o lana merino. Encima va una segunda capa de aislación térmica, prendas cuya misión es retener el calor que genera el cuerpo e impedir su enfriamiento, favoreciendo la evacuación del sudor. Lo mejor es utilizar materiales que aun mojados mantengan sus propiedades aislantes y transpirables (fibras sintéticas como poliester y naturales, como lana). Finalmente, sobre ella va la tercera capa o “membrana”, que protege de la humedad exterior (por lo que debe ser impermeable); del viento (cortavientos) y de nuestro sudor (transpirable). (Para mas detalles Ver blog anterior "Sistema de vestimenta por capas")<br>
  <br>
  Pantalones: son la clave en la práctica de deportes de nieve, y por eso es fundamental que sean impermeables. Las camperas, en cambio, si se viaja a la nieve de forma recreacional y ocasionalmente, es suficiente con que sean respirables y resistentes al viento y al agua.<br>
  <br>
  Atención: lea la etiqueta y consulte, porque no es lo mismo una prenda impermeable que una resistente al agua o con repelencia al agua.Impermeable significa que es impenetrable para el agua, aún en las condiciones climáticas más severas o los usos más demandantes, como las que cuentan con membrana Gore-Tex®. La ropa impermeable, además de la tela, debe tener un tipo de confección que evite la entrada de agua, como costuras selladas y sistemas de cierre efectivo en zonas críticas (capucha, muñecas y mangas).Las prendas “resistentes al agua”, en cambio, no son impermeables, aunque ofrecen protección ante lluvias leves, no son efectivas frente a lluvias fuertes o alta presión de agua (por ejemplo, al arrodillamos en un área húmeda).La “repelencia al agua”, en tanto, se refiere a un recubrimiento químico que se aplica a la tela que hace que el agua “resbale” por la superficie de la prenda sin ser absorbida. Estas películas son conocidas como DWR (Durably Water Repellency) y no son por sí mismas impermeables, salvo cuando están combinadas con telas como Gore-Tex).<br>
  <br>
  ¿Y el calzado? Debería contar con una membrana impermeable (para que no se nos moje el pie al caminar sobre la nieve) y a la vez respirable, para que la transpiración del pie escape. Es fundamental combinarlo con medias de fibras sintéticas porque si la media es de algodón, por más que el calzado sea impermeable y respirable, los pies se mojan con la transpiración, lo que no sólo resulta muy incómodo, sino que puede producir ampollas al final del día. El calzado con membrana Gore-Tex garantiza que los pies se mantengan secos y calientes y sin sudar, aún en condiciones de alta exigencia aeróbica o climatológica.<br>
  <br>
  Guantes: son fundamentales, porque con frío y manos húmedas, todo se complica. Así que, a no escatimar en ellos. Lo principal es que sean impermeables, y en lo posible, de doble capa, es decir, con un guante interior, generalmente de polar u otro material de abrigo, y otro exterior, con tela cortavientos e impermeable. En lo posible, contar con guantes diseñados para esquí, porque suelen ser más flexibles y permitien una mayor libertad de movimientos. Mejor si son largos y con puños elásticos o hebillas de cierre, para que no entre nieve por la parte de atrás.<br>
  <br>
  Para mayor asesoramiento, buscanos en nuestras 5 sucursales en la Ciudad de Córdoba o a través de nuestro Chat-Online de Suiza Outdoor Since 1888®</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">¿Fibra sintética o pluma?</div>
  <div class="copete">
  En esta ocasión hablaremos sobre un tema importantísimo a la hora de nuestras salidas al aire libre: ¿Qué tipo de prenda usar? ¿Relleno de pluma o de fibra?
  </div>
  <div class="descripcion"><br>
  <br>
  Se trata de dos materiales que se pueden comprimir mucho, y que una vez que se expanden, ocupan una gran cantidad de espacio, el máximo que se permiten cada uno. De esta manera, se crea al interior de la prenda un entramado complejo en el cual “se atrapa” y circula el aire caliente desprendido de nuestro cuerpo, quedando retenido en la prenda de forma que no escape, manteniéndonos cálidos. No es que las prendas generen calor, sino que mantienen el propio calor que nuestro cuerpo genera.<br>
  <br>
  <br>
  Fibra<br>
  <br>
  Las fibras están hechas todas de un material sintético de poliéster similar al algodón, cuyo principal propósito es hincharse al igual que la pluma y mantener el aire caliente.<br>
  <br>
  Una ventaja de la fibra por sobre la pluma es que una vez mojada, sigue manteniendo el calor, mantiene espacios internos donde el calor se almacena y tiene una capacidad asombrosa para secarse rápidamente. La diferencia es muy grande, ya que la fibra en un día soleado demorará alrededor de media hora en secarse, en cambio la pluma demorará un día o incluso dos, teniendo en cuenta que debemos mover la pluma para que se seque correctamente. Su capacidad de respiración igualmente es mayor que la pluma.<br>
  <br>
  La fibra es recomendada cada vez que tengamos una actividad en la que estemos en contacto con humedad o agua, y también cuando se trate de actividades aeróbicas, de las que nos hacen sudar mucho. En estos casos, la fibra es perfecta pues nos mantendrá secos y cálidos. La pluma en estos casos se apelmaza, se humedece y no hincha bien, permitiendo que el calor escape.<br>
  <br>
  Pluma<br>
  La pluma en primer lugar, es un material natural, y es el material que da mejor promedio entre compresión, peso y retención de aire caliente, es el más caliente, el más ligero y el más comprimible.<br>
  <br>
  La pluma es recomendada para situaciones de frío extremo, y también situaciones en que no tengamos una actividad física mayor o muy rigurosa. Funciona muy bien para descansar, para reforzarnos a la hora de dormir con nuestros sacos. Por ejemplo, para estar en la noche en un campamento sin recorrer grandes distancias. Es muy comprimible, pesa menos que la fibra y calienta más que esta.<br>
  La capacidad de la pluma para comprimirse y expandirse es única, y hasta ahora no ha logrado ser superada con ningún material sintético, de forma que siempre serán más calientes que las fibras, ocupando menos espacio al estar comprimidas y de menos peso. También, son más agradables al tacto y más suaves. En cuanto a duración, las prendas de pluma tienen una mucho mayor en comparación a la fibra, y mantienen sus propiedades intactas a lo largo de los años, cosa que no sucede en la fibra.<br>
  En el caso de la pluma, la calidad de ésta se mide a través de “cuins”, esto es capacidad de hinchado, de manera que mientras más alto este indicador, mayor calidad de pluma tendrá nuestra prenda, permitiendo aprovechar al máximo las ventajas del material natural.<br>
  <br>
  <br>
  Ahora que ya sabemos las propiedades y características de cada prenda, solo nos queda preguntarnos cual es nuestro propósito y para qué necesitamos la prenda.<br>
  <br>
  - Si se trata de frío intenso, pero seco, la pluma es claramente nuestra opción. Debe tener una buena capacidad de hinchado.<br>
  -Si necesitamos que aguante humedad, lluvia, que sea caliente pero transpirable, como para uso técnico en montaña, esquí, escalada,etc. Requerimos fibras sintéticas.<br>
  <br>
  Para mayor asesoramiento, buscanos en nuestras 5 sucursales en la Ciudad de Córdoba o a través de nuestro Chat-Online de Suiza Outdoor Since 1888®</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Linternas Tácticas</div>
  <div class="copete">
  En este post vamos a contarles que factores definen una linterna táctica y que pasos debemos seguir para elegir la que se adecua mejor a nosotros.
  </div>
  <div class="descripcion"><br>
  <br>
  Las linternas tácticas se distinguen por su robustez, gran luminosidad y elevadas prestaciones ya que están diseñadas para su uso en situaciones extremas.<br>
  <br>
  Sin duda la linterna es un acompañante indispensable para cualquier miembro de los cuerpos y fuerzas de seguridad del Estado o para cualquier trabajador de la seguridad privada.<br>
  Para valorar una linterna táctica tenemos que tener en cuenta:<br>
  Su potencia. La potencia de una linterna viene definida por los lúmenes de la misma. De este modo, encontramos linternas <br>
  tácticas de potencia alta (más de 220 lúmenes), media (entre 220 y 80 lúmenes) y baja (menos de 80 lúmenes).<br>
  <br>
  Su resistencia. A mayor resistencia nuestra linterna soportará mejor los golpes y los impactos. Se define por el material con <br>
  el que esté fabricada. Algunos de los materiales más resistentes son el aluminio o los refuerzos de acero.<br>
  <br>
  Su peso. Una linterna táctica no debe ser excesivamente pesada ya es un objeto que solemos llevar encima, de un peso no <br>
  superior a 200 gramos.<br>
  <br>
  Su tamaño. Las dimensiones de una linterna táctica deben ser prácticas, para facilitar su transporte.<br>
  Su vida útil. Suele venir determinada por la alimentación energética que emplee (pilas o batería) y por su lámpara. En el <br>
  mercado solemos encontrar linternas de led o de xenón. En nuestra opinión es siempre preferible que sea de led, ya que tiene un tiempo de vida mucho mayor y funcionan muy bien como luces estroboscópicas (intermitentes).<br>
  Otros Suelen ser variables de los anteriores, es importante tener en cuenta aspectos como la lateralidad o el alcance de la <br>
  linterna.<br>
  Una vez aclarados estos factores, conviene analizar las situaciones en las que pensamos que vamos a emplear la linterna táctica <br>
  y cómo influyen dichos factores en ellas. Por ejemplo, si vas a emplearla trabajando, será importante que el tamaño sea <br>
  pequeño, si necesitas moverte rápido asegúrate de que no tenga un excesivo peso, etc.<br>
  <br>
  Encontrá toda la línea de linternas tácticas de Fénix, Led Lenser, Shilba y Nitecore en nuestras 5 sucursales Suiza Outdoor en <br>
  Córdoba Capital o en nuestra Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Trekking o Hiking?</div>
  <div class="copete">
  Trekking, hiking, speed hiking, trailrunnig son algunos términos que escuchamos en el día a día y tienen que ver con la montaña...Desde Suiza Outdoor te ayudamos a conocerlas y diferenciarlas.</div>
  <div class="descripcion"><br>
  <br>
  Trek, es una palabra que nació en los ochentas, y se originó por los alpinistas que viajaban al Himalaya o a los Andes. Con esta palabra se referían a las largas caminatas que los acercaban a las bases de las montañas que planeaban ascender.<br>
  <br>
  Cuando hacemos trekking tenemos distintos grados de dificultad para considerar. De este modo podemos elegir rutas para principiantes, o bien rutas que demanden más esfuerzo y experiencia.<br>
  <br>
  El Senderismo (o Hiking) por otro lado es una actividad relajante que implica ir por caminos señalizados usando así el sistema de vías de comunicación tradicional como puentes, trochas, senderos. El espíritu del senderismo, reposa en poder acercar a la gente al medio natural, conocer patrimonios y reservas a través del uso de senderos.<br>
  <br>
  El Excursionismo ( o Trekking) a diferencia del Senderismo (Hiking) es una especialidad del montañismo que demanda más esfuerzo físico y que a la vez implica ir por parajes sin senderos y lugares donde no hay camino que pueden encontrarse en montañas, bosques, selvas, costas, cañones, ríos, cavernas, etc. Esta actividad puede combinarse con la acampada y es una manera consistente de hacer turismo ecológico.<br>
  <br>
  Desde Suiza Outdoor te recomendamos que los términos o palabras no te detengan a la hora de disfrutar una salida al aire libre!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Consejos para elegir tu mochila</div>
  <div class="copete">
  Las mochilas se eligen dependiendo del peso, del precio, del tamaño y del tramo a recorrer. No es lo mismo tener pensado recorrer ciudades que hacer camping o trekking.
  </div>
  <div class="descripcion"><br>
  TAMAÑO:<br>
  Las mochilas se miden según el volumen de carga que puedan llevar, por ende se pregunta por su capacidad en “litros” . Como no sabemos si vas a meter plumas o piedras no podemos considerar cuantos kilos pesará.<br>
  <br>
  Las mochilas pequeñas en general tienen una capacidad que va desde los 20 a los 40 litros. La capacidad media va de 45 a 60 litros y las grandes pueden contener desde 60 a 90 litros.<br>
  <br>
  Una mochila más grande no es necesariamente mejor. Tal vez no puedas ir demasiado lejos con una mochila inmensa sobre tu espalda.<br>
  <br>
  A la hora de escoger tu mochila presta mucha atención en como se ajusta a tu torso y cadera. La ergonomía es esencial. Pruébatela e imagínala con peso. En las mujeres se debe verificar que la curvatura de las correas no afecte la posición de los senos y sea compatible con las caderas.<br>
  <br>
  Además de la estructura interna también es importante fijarse en las correas estabilizadoras frontales que se ajustan a tu tórax para que el peso se mantenga centrado.<br>
  <br>
  Es fundamental procurar que la mochila tenga correas por fuera que permitan comprimir al máximo la mochila. Mientras más ajustadas lleves las cosas por dentro, más espacio tendrás así como mayor estabilidad del contenido.<br>
  <br>
  Las cosas se llevan lo más comprimidas posibles tratando de hacer paquetes pequeños y sólidos.<br>
  <br>
  Finalmente es de ayuda que el material del que está fabricada sea resistente. Fíjate en las costuras, los acabados, las uniones y la resistencia. Si trae un cobertor para lluvia, mucho mejor.<br>
  <br>
  Haz un equilibrio entre lo que necesitas y lo que puedes pagar. Y elige algo que te dure un par de buenos años viajando, no pienses que será para toda la vida. Tu mochila será tratada como un saco de basura en los aeropuertos, los depósitos, bajo la lluvia, la humedad, y tendrá que soportar muchos golpes y arañones.<br>
  <br>
  Usa el buen criterio y consulta por tu mochila ideal en cualquiera de nuestras sucursales Suiza Outdoor.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">como elegir una mira Telescópica</div>
  <div class="copete">Cuando pensamos comprar una mira telescópica, hay una gran variedad de marcas y modelos para elegir. 
  Para comenzar, vale aclarar que hay dos grandes grupos de miras: aumentos fijos y aumentos variables.

  </div>
  <div class="descripcion">Aumentos Fijos:<br>
  Las miras de aumentos fijos fueron las primeras en aparecer. La mayor ventaja está en su sencillez.<br>
  Tienen un menor número de piezas, con menos movimientos, por ello tienen menos defectos y averías <br>
  (Según distribuidores, a igual cantidad entregada, son 3 veces menos propensas a inconvenientes que las variables),<br>
  la distancia ocular es constante, el retículo tiene siempre el mismo tamaño real y aparente. Suelen tener un campo <br>
  de visión más amplio que una variable de igual diámetro objetivo y al mismo aumento y calidad de componentes suelen <br>
  ser más económicas.<br>
  El inconveniente: son de aumento fijo, así que uno hace una elección de aumento y luego debe vivir con ella.<br>
  <br>
  Aumentos Variables:<br>
  Las miras de aumento variable nacieron para poder usar un arma/mira en varias disciplinas de tiro, por ejemplo <br>
  seleccionando un aumento de 4x para caza en monte o hacer largos disparos en montaña seleccionando 12x. Debido <br>
  al mayor número de piezas que la componen y mayor sofisticación, necesitan, a igual calidad de materiales, una <br>
  elaboración más cuidadosa y un mayor control que las miras de aumentos fijos lo cual se refleja en el precio. <br>
  También por la mayor cantidad de piezas suelen ser o más pesadas que las fijas o bien más frágiles. Un punto <br>
  relevante solo tratándose de miras de aumento variable es la ubicación del retículo en el sistema óptico.<br>
  <br>
  Retículos<br>
  <br>
  Tan importante como el tamaño del objetivo, aumentos, etc. es la elección del retículo. Este debe adecuarse<br>
  perfectamente a la disciplina de tiro o caza que practiquemos, podrían enunciarse dos características generales:<br>
  1) que obstruya lo mínimo posible la visión<br>
  2) que sea un diseño que no distraiga nuestra atención y favorezca una rápida adquisición del blanco.<br>
  <br>
  Retículos iluminados<br>
  Es importante que no generen reflejos dentro de la mira y que el brillo sea moderado para que no disminuya <br>
  el diámetro de nuestra pupila.<br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Que es el HyVent?</div>
  <div class="copete">El HyVent es una tecnología superior y transpirable con revestimiento de poliuretano (PU) compuesto de una fórmula multicapa tricomponente para una alta protección impermeable, permeabilidad al agua y larga duración.
  </div>
  <div class="descripcion">Desde que, hace ya casi medio siglo, la empresa The North Face (especializada en prendas y equipaciones para deportes de aventura y montaña) lanzó sus primeros productos, las innovaciones introducidas por la compañía en lo que a diseño se refiere, y los novedosos tejidos técnicos utilizados en la fabricación de sus prendas, no han dejado de sorprender. Y es que la empresa siempre ha buscado ofrecer prendas de máxima calidad y alto rendimiento, ligeras pero funcionales, aislantes pero transpirables.<br>
  <br>
  A grandes rasgos, el HyVent consta de 3 capas con diferente tamaño de poros, que combinadas entre sí consiguen una total impermeabilidad tanto en su exterior como en su interior. Es así como nacen los tejidos de la gama Hyvent, revestidos de poliuretano y formados por varias capas (dos o tres) que ofrecen una total estanqueidad contra la lluvia y la nieve, permitiendo la transpiración y manteniendo el interior de la prenda siempre seco. Estos revolucionarios tejidos de membrana integran en una sola tela las propiedades de tres (capa exterior, intermedia e interior), por lo que la reducción del peso es considerable, además de favorecer la adaptación de la prenda al cuerpo. Pero ¿cómo consigue una sola tela llevar a cabo el cometido de tres? Después de muchos estudios The North Face dio con la respuesta: a través de revestimientos.<br>
  <br>
  Capa exterior: esta primera capa protege a los tejidos interiores de la abrasión.<br>
  Capa intermedia: la capa intermedia utiliza un revestimiento de poliuretano y microporos para canalizar el vapor de agua desde la capa interior hasta la exterior, permitiendo una total impermeabilidad.<br>
  Capa interior: esta capa interior gestiona la humedad, canalizándola hacia los microporos.<br>
  Los tejidos de membrana Hyvent están formados por una capa interior de poliuretano atravesada por una multitud de pequeños poros. Cuando el cuerpo suda, los poros atrapan la humedad y la transportan hacia la capa intermedia. La misión de ésta es impedir que la humedad vuelva a bajar hacia el cuerpo, a la vez que la expulsa hacia la tercera capa y ayuda a disiparla. La tercera capa, la exterior, tiene un revestimiento especial anti-humedad para evitar que el agua procedente de la lluvia, nieve, hielo..., entre en la prenda pero, a la vez, permite que el vapor procedente de la capa intermedia salga al exterior. Es decir, la capa exterior es impermeable en una sola dirección (gracias al revestimiento), de afuera hacia adentro, pero es permeable en sentido contrario (desde dentro hacia afuera), permitiendo de este modo una óptima transpiración y que el sudor sea eliminado.<br>
  <br>
  HyVent está disponible en dos versiones diferentes (HyVent Alpha y HyVent 2,5L) y que se diferencian especialmente por su nivel de transpirabilidad. HyVent Alpha dispone de aproximadamente 500.000 de poros por pulgada cuadrada, permitiendo a la transpiración atravesar la chaqueta antes de que se condense en líquido, manteniendo el cuerpo seco, caliente y cómodo en condiciones climáticas extremas. En cuanto al HyVent 2,5L (también llamada HyVent DT), que se diferencia de la primera en la reducción del grosor de la tercera capa. Por lo tanto, el tejido sigue siendo igual de impermeable pero ligero, compacto y más transpirable que otras membranas.<br>
  <br>
  Características de HyVent<br>
  Como ya se ha indicado, el tejido HyVent es un tejido de The North Face permeable al vapor, transpirable e impermeable. Se caracteriza por su permeabilidad al vapor de 650 gr/m²/24h (equivalente al GORE-TEX Classic) y después de 20 lavados, la columna de agua puede variar aproximadamente a 25.000mm (HyVent 2,5L) y 30.000mm (HyVent Alpha).<br>
  <br>
  No es lo mismo una ligera prenda cortavientos que una chaqueta para travesías de alta montaña. Cada una requiere unas determinadas características que la conviertan en funcional, eficaz y eficiente. La tecnología Hyvent ha evolucionado para dar respuesta a las distintas necesidades, dando como resultado tejidos de última generación:<br>
  Alpha<br>
  Doble Capa (2 Lyer)<br>
  Tri-capa (3 Lyer)<br>
  Hyvent DT y DT EC<br>
  El primero es un tejido con membrana laminada multi-poro (medio millón de poros por pulgada cuadrada). Es muy resistente al roce exterior y mantiene el cuerpo a temperatura constante, seco y confortable. La Hyvent bi-capa incorpora una capa interior revestida de poliuretano y una exterior, el sistema tri-capa introduce una tercera capa intermedia que, además de aislar, ayuda a disipar la humedad hacia el exterior. Las tecnologías DT (Dry Touch) utilizan como base el tejido bi-capa y le incorporan un tratamiento complementario que actúa como tercera capa. Los resultados son los mismos que los conseguidos con un tejido de tres capas, pero el peso es menor, pues se elimina la capa-forro. En el caso de la DT EC se va un paso más allá, empleando imprimación de aceite de ricino natural, con lo que se reduce el uso de material sintético hasta un 50%, una considerable contribución al mantenimiento del medio ambiente.<br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">  Mantente fresco con Columbia</div>
  <div class="copete">En esta sección te mostramos las diferentes tecnologías aplicadas a la indumentaria deportiva;
  hoy Omni-Freeze Zero.</div>
  <div class="descripcion">La tecnología está cambiando significativamente la forma en la que los deportistas, <br>
  sin importar que sean profesionales o aficionados, realizan sus actividades. Mayor rendimiento,<br>
  mayor seguridad y mayor comodidad son algunas de las prioridades de quienes diseñan tanto ropa <br>
  deportiva como equipo dedicado. La gente de Columbia nos presenta en esta oportunidad  <br>
  Omni-Freeze ZERO, una línea de ropa deportiva que mantiene fresco al usuario a medida que <br>
  comienza a sudar.<br>
  La tecnología de esta prenda  está compuesta por pequeños anillos de un polímero especial que <br>
  se expanden al entrar en contacto con la humedad del sudor, permitiendo un mayor recorrido del aire <br>
  sobre el cuerpo. Columbia da a entender que esto optimiza al mecanismo de refrigeración natural, por <br>
  lo que un atleta debería sentirse más fresco con una camiseta Omni-Freeze ZERO que con el torso<br>
  expuesto.<br>
  Encontrá esta y todas las nuevas tecnologìas de Columbia en cualquiera de nuestras sucursales Suiza outdoor del Centro de Córdoba o si sos del interior a través de nuestra Tienda Virtual  www.suizaoutdoor.com.ar</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Pinza multiusos Leatherman</div>
  <div class="copete">Las pinzas multiuso Leatherman son elegidas por los aficionados a la excursión y las actividades al aire libre desde su aparición en el mercado, hace unos 25 años.</div>
  <div class="descripcion"> Estas navajas además de ser multi-funciones, son 100 % de acero inoxidable y se han hecho <br>
  imprescindibles también para electricistas, navegantes, maquinistas, agricultores, mécanicos, <br>
  motoristas, reparadores... <br>
  La empresa Letherman se fundó en el año 1975 cuando Tim Leatherman realizó un viaje a <br>
  Europa con poco presupuesto y acabó harto de su sencilla navaja de excursionista que no <br>
  le servía de mucho cuando tenía que arreglar el grifo de su habitación o reparar su viejo <br>
  coche. Con esto llegó a la conclusión de que lo que realmente necesitaba era una navaja <br>
  compacta con unos buenos alicates, pero en estos momentos no existía ninguna herramienta <br>
  así en el mercado.<br>
  Ingeniero de profesión, Tim dedicó los ocho años siguientes a diseñar una nueva y más útil<br>
  herramienta compacta y en el año 1983 comenzó la producción de la navaja original LEATHERMAN.<br>
  <br>
  Navaja Leatherman de la serie JUICE con un diseño muy espectacular.<br>
  <br>
  Hoy LEATHERMAN es líder en la industria de las navajas multi-usos, las cuales están <br>
  fabricadas en EE UU con un exigente nivel de calidad, utilizando acero inoxidable 100% y <br>
  respaldadas con 25 años de garantía.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Limpieza de nuestra arma.</div>
  <div class="copete">No existe ningún motivo válido para no limpiar un arma antes de guardarla, sobre todo teniendo en cuenta que si no lo hacemos ahora nos veremos obligados a hacerlo en el momento de salir al campo.
  </div>
  <div class="descripcion">En la limpieza del arma, deberán tenerse en cuenta por separado cada una de sus partes:<br>
  EL CAÑÓN Y LOS MECANISMOS: Al efectuar un disparo, el cañón se ve afectado por varios elementos residuales,<br>
  que se producen como consecuencia del mismo: la acción de los gases producidos por la pólvora y la ignición del pistón;<br>
  el plomo y el latón de la camisa de la bala.<br>
  Todos estos elementos, propios del disparo, tienen que tenerse en cuenta y tratarse de un modo diferente. <br>
  Los pasos a seguir para la limpieza serán los siguientes:<br>
  -Pasar un cepillo de bronce con disolvente, 20 veces, cuidando que el cepillo salga siempre por la boca.<br>
  -Limpiar el cepillo con Metanol.<br>
  -Limpiar el cañón con varios parches.<br>
  -Si hay señal de latonado (Parches de color verde): repetir el proceso.<br>
  -Si no hay latonado, pasar un parche con producto mixto o lubricante si se va a guardar, o bien usar directamente.<br>
  LA MADERA:En el mercado encontraremos artículos especialmente pensados para este fin: no sólo sirven para limpiar estas zonas de madera, sino que además les confieren el grado de humedad necesario para que no se resquebrajen.<br>
  Para limpiar estas superficies de madera, procederemos de la siguiente forma:<br>
  Comenzaremos realizando una “limpieza general”; para ello pasaremos un trapo húmedo, con el que deberemos retirar toda<br>
  la humedad y cualquier resto de suciedad visible (no se olvide de las zonas de difícil acceso, el polvo siempre las alcanza).<br>
  Tras este paso de limpieza, simplemente le daremos una capa de cera incolora<br>
  SUPERFICIES METÁLICAS: ya sean pavonadas, cromadas o anodizadas, las superficies metálicas no pueden ser pasadas por alto.<br>
  Para su limpieza procederemos primeramente con un trapo suave, tras el cual aplicaremos un aceite especial para armas,<br>
  insistiendo siempre en las zonas que así lo requieran.<br>
  Tras esta limpieza a fondo, convendrá dar una ligera capa de aceite para armas, lo que servirá como escudo contra la oxidación.<br>
  LA FUNDA: Tenga en cuenta que la funda que normalmente lleva en sus jornadas de caza suele acabar mojada, por el sudor, la lluvia, el rocío…<br>
  Si procede a guardar el arma por largo tiempo en esta funda, correrá el riesgo de que esta humedad termine por oxidar su arma.<br>
  El mejor consejo es que disponga usted de dos fundas: una para sus jornadas de cacería y otra que utilizará exclusivamente cuando se disponga<br>
  a guardar el arma por un período de tiempo más o menos largo.<br>
  Por accesorios para limpieza de su arma, visite nuestro catálogo o diríjase a cualquiera de nuestras sucursales.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Todo sobre la suela Vibram</div>
  <div class="copete">En el blog de hoy te contamos detalles sobre las suelas VIBRAM, muy requeridas a la hora de elegir un calzado con una suela duradera y de buen agarre.</div>
  <div class="descripcion">¿Qué es Vibram?<br>
  Vibram es una firma de origen italiano que recibe este nombre en honor a su fundador, Vitale Bramani, y que se hizo un hueco en el mercado gracias a la invención de las primeras suelas de goma para zapatos y botas de montaña resistentes a la abrasión, que sustituyeron las suelas de clavo o acero que se utilizaban entonces. El desarrollo de este tipo de suela surgió debido a un accidente en 1935, donde 6 montañeros amigos de Bramani murieron por no llevar suelas adecuadas. Los pies son una de las partes del cuerpo sobre la que mayor atención hay que poner a la hora de elegir el equipamiento deportivo adecuado. Estar cómodo y a gusto es de vital importancia ya que así se puede reducir la sensación de cansancio así como evitar rozaduras. En este sentido, las suelas Vibram incorporadas a muchas zapatillas, botas y, en general, todo tipo de productos de calzado, son la mejor opción. Gracias a las excelentes propiedades de adherencia que presentaban, los usuarios pudieron dejar de utilizar su tradicional calzado de clavos o de acero y que resultaban mucho más incómodas. Hoy por hoy los productos Vibram son fácilmente reconocibles por el logo amarillo que presentan y se pueden encontrar en todo tipo de productos.<br>
  Características de Vibram<br>
  <br>
  Las suelas Vibram están fabricadas por un compuesto de goma especial que garantiza el agarre al suelo. Además, cada una de las características de la suela está perfectamente ajustada al tipo de calzado y al uso especial que se va a hacer de él. En general, todo el calzado de montaña y actividades al aire libre, disponen hoy en día de suela Vibram.<br>
  <br>
  Por otro lado, las suelas de goma Vibram son muy resistentes y duraderas por lo que puede estar seguro de que sus botas se mantendrán en perfectas condiciones durante una gran cantidad de tiempo. Además, el innovador dibujo que incorporan en las suelas hace que sean muy adherentes en cualquier tipo de condición, ya sea sobre terreno seco o mojado. De igual forma, este material no se ve afectado ni por las altas ni por las bajas temperaturas, manteniendo intacta su tracción original sobre todas las superficies. Por último, también hay que destacar que se pueden limpiar muy fácilmente con un poco de agua tibia y un cepillo.<br>
  Limpieza de Vibram<br>
  Para suelas muy sucias, eliminar la suciedad extrema con agua tibia primero. En caso de que queden restos entre las ranuras de la suela, eliminarlos simplemente a mano con un objeto puntiagudo. Una vez se han quitado los restos y la suela quede totalmente seca, pasar un cepillo. Si utiliza frecuentemente un calzado con suela Vibram, se recomienda revisar la misma con regularidad y si es necesario, sustituir por una suela nueva en caso de presentar erosión. Nunca espere hasta su desgaste total ya que puede ser muy peligroso en caso de su uso durante deportes de riesgo.<br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Calentadores a Gas</div>
  <div class="copete">A continuación les contaremos las clases de Gases y de cartuchos, pero tengan en cuenta, que lo que debemos decidir es con que Gas y con que cartucho quieren que 
  funcione nuestro calentador.</div>
  <div class="descripcion">Los calentadores Gas son los mas conocidos y los mas usados a la hora de ir de camping, ya que estos son muy prácticos, y los repuestos se consiguen<br>
  en cualquier casa de camping.<br>
  Como todas las cosas siempre existen diversas marcas, algunas buenas, otras malas.<br>
  <br>
  Los calentadores que funcionan a Gas se clasifican en 2 grupos.<br>
  -Gas Butano<br>
  -Gas Propano-Butano<br>
  Los que funcionan con Gas Butano son los mas comunes y económicos de los dos grupos.<br>
  Verdaderamente son muy prácticos, pero tienen un problema, a temperaturas bajas pierden podes calorífico, esto significa que la llama tiene menos <br>
  temperatura y la consecuencias que esto nos traera es que vamos a tardar mas en cocinar, en calentar agua, etc.<br>
  <br>
  Los que son a Gas Propano-Butano no tienen este problema, ya que el Gas Propano no sufre contracción de partículas y a consecuencia de esto no se ve<br>
  afectado el poder calorífico del Gas a las bajas temperaturas.<br>
  Siempre los cartuchos de Gas Propano-Butano tienen un procentaje de cada Gas, generalmente, oscila entre el 5-20% de Propano y 80-95% de Butano.<br>
  Obviamente  mientras mas porcentaje de Propano tenga el cartucho mejor será.<br>
  <br>
  Después independientemente de Gas que tenga el cartucho existen tres diferencia de cartuchos propiamente dicho.<br>
  <br>
  Cartucho a rosca - Son de forma tipo torta (los cartuchos son solo de gas Propano-Butano)<br>
  Cartucho común - Son de forma tipo aerosol (los cartuchos vienen de Butano y de Propano-Butano)<br>
  Cartucho agujerable - Son de forma tipo torta pero se agujerean (vienen solo de Butano)<br>
  <br>
  Los agujereables suelen rendir poco, y salen casi lo mismo que los otros. Se denominan agujerables porque el<br>
  calentador tiene un piquito que perfora al cartucho, por ese motivo una ves colocado no se pueden quitar los cartuchos hasta agotarlo. Por lo tanto son<br>
  muchos menos prácticos a la hora de transportarlos y ocupan mas lugar. Y solo vienen de Butano<br>
  <br>
  Los de Aerosol son una buena alternativa, estos tienen una válvula por lo que se puede quitar y poner el cartucho cuando se desee, vienen en versiones de<br>
  Butano y Propano-Butano, y conviene comprar los de Propano-Butano por el rinde y porque no hay mucha diferencia en precios. Los cartuchos son económicos.<br>
  La contra es que son inestables para cocinar.<br>
  <br>
  Los de cartucho a rosca son la mejor opción. Tienen válvula por lo que el cartucho lo sacas y pones cuando quieras. Son estables para cocinar, vienen de gas<br>
  Propano-Butano. Si bien el cartucho a rosca sale casi el doble que uno tipo aerosol, se pueden comprar un adaptador y podremos usar <br>
  el cartucho tipo aerosol también. Aunque los que son a rosca siguen teniendo mejor rinde.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Qué es el Gore-Tex?</div>
  <div class="copete">Todos hemos oído hablar de lo importante de una prenda impermeable, hoy te presentamos una tecnología a tener en cuenta en días de lluvia.</div>
  <div class="descripcion"><br>
  El Gore Tex® es una membrana de tan sólo 0,01 mm de grosor, y que contiene más de 1400 millones de poros por cm². Estos poros son más pequeños que una gota de agua, pero más grandes que una molécula de agua, lo que significa que permiten salir el vapor de agua de la piel hacia el exterior pero no permite que entre el agua, manteniendo los pies secos en todo momento.<br>
  <br>
  Membrana Goretex<br>
  <br>
  Esta membrana se coloca entre el tejido exterior y un forro de altas prestaciones, con lo cual se consigue un laminado totalmente resistente a cualquier inclemencia climática.<br>
  <br>
  VENTAJAS DE USAR CALZADO CON GORE TEX®<br>
  Pero, ¿qué supone esta membrana de tres capas? Según estén unidas, aportan unos beneficios u otros: si la membrana está unida al tejido exterior se incrementa la comodidad y la versatilidad. Si la membrana se une al tejido exterior y al forro se consigue una mayor durabilidad al reducirse el desgaste de las capas.<br>
  <br>
  MUY RESISTENTE<br>
  La membrana Gore Tex® es un tejido altamente resistente, con los correctos cuidados soportará sin problemas la vida útil del calzado, lo que a largo plazo se convertirá en un ahorro al no tener que comprar nuevos equipos.<br>
  <br>
  EXTREMADAMENTE TRANSPIRABLE<br>
  Gracias a la composición del tejido Gore Tex® el agua no penetra, pero el sudor se evapora fácilmente, que sale  a través de unas ventilaciones laterales que incorpora, aumentando la sensación de comodidad.<br>
  <br>
  IMPERMEABLE<br>
  Este tejido aporta una impermeabilidad prolongada durante toda la vida útil de la prenda, lo que aporta una protección garantizada ante las inclemencias climática como la nieve o la lluvia y el viento.Te Mantendrá  siempre seco y a una temperatura adecuada.<br>
  <br>
  COMODIDAD SUPERIOR<br>
  El tejido se adapta perfectamente a la forma del pie lo que aumenta la comodidad en largos trayectos. Gracias a su composición, el Gore Tex® es muy ligero y su volumen muy reducido, por lo que el peso no será un problema.<br>
  <br>
  TERMOSELLADO EN LAS COSTURAS<br>
  Las puntadas del calzado son un punto de riesgo por donde se pueden producir escapes y perder de este modo las ventajas de impermeabilidad y transpirabilidad que aporta este tejido. Para evitar esto, se usan las cintas GORE-SEAM®, evitando así que se puedan producir estos escapes manteniendo al máximo los beneficios que aporta el Gore Tex®.<br>
  <br>
  La tecnología Gore Tex® está en constante avance, y las nuevas innovaciones son incluidas con regularidad en los nuevos modelos que lanzan al mercado. Esta constante investigación y desarrollo es lo que hace que este tejido sea uno de los más buscados a la hora de comprar calzado técnico.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Que es Ripstop o antidesgarro?</div>
  <div class="copete">El ripstop es una tela desarrollada con una técnica de tejido especial, en el que todos los hilos están entretejidos. De este modo, se genera una alta resistencia a la tracción y visualmente su estructura parece cuadrada.

  </div>
  <div class="descripcion">Características del ripstop<br>
  <br>
  La principal cualidad del ripstop es su capacidad para evitar que los tejidos se desgarren. En su confección, lo realmente importante no sólo son las fibras, sino el entramado de las mismas. EL diseño de su trama se realiza en forma de cuadrícula, de esta forma si se produce una rotura, esta no continua después de la celda donde se inició. Esta propiedad del tejido ripstop es muy importante en temas de equipación de montaña, como en los sacos de dormir. Las superficies donde suelen ser colocados no siempre son las idóneas y la fricción a la que son sometidos hace que se produzcan roturas y desgaste. Sin embargo, los que incluyen este tejido en su confección, resisten perfectamente el uso y suelen durar mucho más tiempo. Entre las cualidades del ripstop encontramos las siguientes:<br>
  -Ligereza<br>
  -Resitencia<br>
  -Duración<br>
  <br>
  Para más información, visitá cualquiera de nuestras 5 sucursales Suiza Outdoor de la Ciudad de Córdoba y encontrá todo lo que necesitás para tus salidas al aire libre! Te esperamos</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Envases para transportar agua.</div>
  <div class="copete">Como sabemos, es importante la hidratación adecuada durante cualquier actividad física que realicemos,por eso te traemos algunas opciones a tener en cuenta en nuestras salidas al aire libre. 
  </div>
  <div class="descripcion">Siempre que salgamos a caminar deberemos tomar la precaución de transportar la suficiente cantidad de agua con nosotros para permitirnos recorrer<br>
  todo el circuito sin sobresaltos, sobre todo si se trata de un escenario natural que no conocemos o que no estamos seguros de poder reaprovisionarnos <br>
  del valioso líquido.<br>
  <br>
  Existen en la actualidad, muchas opciones en cuanto a recipientes de almacenamiento y transporte de líquidos construidos en diversos materiales y con <br>
  diferentes costos, adaptados a todo tipo de presupuesto. Veamos algunas de las opciones que nos ofrece mercado:<br>
  <br>
  Cantimploras militares: Comúnmente construidas en Cantimploraplástico, son la opción más económica. Existen desde hace bastantes años en el mercado y se <br>
  basan en las clásicas cantimploras usadas por miembros de las fuerzas armadas. Lo más común es encontrarlas en la gama del verde o camufladas. Existen <br>
  varios modelos, algunos recubiertos con fundas de tela, que permiten engancharlas al cinturón o bien colgarlas del hombro mediante una correa. Desventajas:<br>
  los materiales de los que están hechas suelen ser de baja calidad por lo cual pueden transmitir sabores indeseados al liquido.<br>
  <br>
  Botellas de aluminio: Construidas en este material ultraliviano, vienen en diferentes tamaños  Botella de aluminio para trekking(capacidad) y colores. <br>
  Su forma se asemeja más a la de una botella, lo cual facilita su transporte en los bolsillos laterales de la mochila, dejándola a mano del trekker en todo<br>
  momento. Tienen un tapón a rosca, normalmente fabricado en aleación de plástico de alto impacto; que funciona en algunos casos como simple tapa, o en otros <br>
  como pico dosificador semejante al de una caramañola de ciclista.<br>
  Es muy común además, que los tapones posean una argolla, de manera que permitan el paso de un mosquetón, a fin de enganchar la botella en caso de que fuese<br>
  necesario.<br>
  Desventaja: Si les da el sol durante la marcha, el líquido se calentará mucho más rápido por causa del aluminio. Son unicamente aptas para agua, no<br>
  es posible trasladar otros líquidos, como jugos, ya que podria causar diarrea.<br>
  <br>
  Botellas de policarbonato: Muy livianas y durables, construidas en este derivado plástico de alto impacto, las convierten en una buena opción a la hora de <br>
  elegir. Son productos con una muy buena relación calidad-precio y se presentan en varios formatos (boca ancha, boca angosta) y tamaños. Además existe una <br>
  amplia gama de colores para elegir.<br>
  Ventaja: Pueden colocarse en el freezer para congelar el contenido.<br>
  Desventaja: Algunos estudios sostienen que el policarbonato libera ciertas sustancias que a largo plazo resultan perjudiciales a la salud.<br>
  <br>
  Botellas de acero inoxidable: Muy similares a las de aluminio en cuanto a forma y diseño; quizá no tan livianas, <br>
  pero con todas las ventajas higiénicas que proporciona el acero inoxidable y por supuesto mucho más durables.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Contagrip: tecnología de suelas muy duraderas</div>
  <div class="copete">Se trata de una tecnología que fusiona de forma atenta y equilibrada la densidad, los componentes y la geometría; 
  puede ofrecerte un rendimiento impecable, tanto si llueve o hace sol, por asfalto o rocas. </div>
  <div class="descripcion">Densidad y componentes<br>
  Las suelas Contagrip están compuestas de diferentes elementos con densidades diversas. Esto significa que la suela <br>
  presenta zonas más blandas o más firmes, dependiendo de dónde estén colocadas. Las áreas más propensas a desgastarse antes incluyen compuestos de mayor densidad y más resistentes para retrasar su desgaste (como por ejemplo la suela exterior). Otras zonas utilizan compuestos de menor densidad que ofrecen algo más de flexibilidad y agarre (como por ejemplo la columna central de la suela).<br>
  <br>
  Geometría<br>
  La geometría hace referencia a la forma y la profundidad generales de la zapatilla y sus partes. La geometría tiene un impacto importante en la sensación ofrecida de equilibrio y comodidad mientras corres por senderos y superficies irregulares e inestables.<br>
  <br>
  Desnivel entre el talón y la puntera:<br>
  La variación geométrica más obvia es el desnivel entre el talón y la puntera: el desnivel es menor en zapatillas para <br>
  competición y senderos agresivos, y mayor para zapatillas de entrenamiento y senderos menos agresivos. La idea es que si te mueves rápidamente por terrenos irregulares, necesitas una mayor sensación de la gravedad para que puedas moverte lo más rápido posible sin tener miedo a resbalarte.<br>
  <br>
  Tacos:<br>
  Otro de los aspectos que se tienen en cuenta en la geometría de la suela son los tacos (los pequeños elementos que <br>
  sobresalen de la suela). Los terrenos más escarpados requieren tacos más largos para poder ofrecer un mejor agarre y mayor protección contra las piedras y las rocas puntiagudas. Salomon también les da una forma especial a los tacos para que no acumulen el barro en la suela y resulten más fáciles de limpiar.<br>
  Los compuestos, las densidades y la geometría de las suelas Contagrip son distintos en cada zapatilla, ya que cada modelo está pensado para actividades ligeramente diferentes: carretera, barrancos, senderos blandos o senderos duros.<br>
  <br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Todo sobre la suela Vibram</div>
  <div class="copete">En el blog de hoy te contamos detalles sobre las suelas VIBRAM, muy requeridas a la hora de elegir un calzado con una suela duradera y de buen agarre.</div>
  <div class="descripcion">¿Qué es Vibram?<br>
  Vibram es una firma de origen italiano que recibe este nombre en honor a su fundador, Vitale Bramani, y que se hizo un hueco en el mercado gracias a la invención de las primeras suelas de goma para zapatos y botas de montaña resistentes a la abrasión, que sustituyeron las suelas de clavo o acero que se utilizaban entonces. El desarrollo de este tipo de suela surgió debido a un accidente en 1935, donde 6 montañeros amigos de Bramani murieron por no llevar suelas adecuadas. Los pies son una de las partes del cuerpo sobre la que mayor atención hay que poner a la hora de elegir el equipamiento deportivo adecuado. Estar cómodo y a gusto es de vital importancia ya que así se puede reducir la sensación de cansancio así como evitar rozaduras. En este sentido, las suelas Vibram incorporadas a muchas zapatillas, botas y, en general, todo tipo de productos de calzado, son la mejor opción. Gracias a las excelentes propiedades de adherencia que presentaban, los usuarios pudieron dejar de utilizar su tradicional calzado de clavos o de acero y que resultaban mucho más incómodas. Hoy por hoy los productos Vibram son fácilmente reconocibles por el logo amarillo que presentan y se pueden encontrar en todo tipo de productos.<br>
  Características de Vibram<br>
  <br>
  Las suelas Vibram están fabricadas por un compuesto de goma especial que garantiza el agarre al suelo. Además, cada una de las características de la suela está perfectamente ajustada al tipo de calzado y al uso especial que se va a hacer de él. En general, todo el calzado de montaña y actividades al aire libre, disponen hoy en día de suela Vibram.<br>
  <br>
  Por otro lado, las suelas de goma Vibram son muy resistentes y duraderas por lo que puede estar seguro de que sus botas se mantendrán en perfectas condiciones durante una gran cantidad de tiempo. Además, el innovador dibujo que incorporan en las suelas hace que sean muy adherentes en cualquier tipo de condición, ya sea sobre terreno seco o mojado. De igual forma, este material no se ve afectado ni por las altas ni por las bajas temperaturas, manteniendo intacta su tracción original sobre todas las superficies. Por último, también hay que destacar que se pueden limpiar muy fácilmente con un poco de agua tibia y un cepillo.<br>
  Limpieza de Vibram<br>
  Para suelas muy sucias, eliminar la suciedad extrema con agua tibia primero. En caso de que queden restos entre las ranuras de la suela, eliminarlos simplemente a mano con un objeto puntiagudo. Una vez se han quitado los restos y la suela quede totalmente seca, pasar un cepillo. Si utiliza frecuentemente un calzado con suela Vibram, se recomienda revisar la misma con regularidad y si es necesario, sustituir por una suela nueva en caso de presentar erosión. Nunca espere hasta su desgaste total ya que puede ser muy peligroso en caso de su uso durante deportes de riesgo.<br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo"> Mantente seco con Columbia OutDry</div>
  <div class="copete">Es la nueva tecnología patentada para la impermeabilización de calzados, guantes e indumentaria técnica.</div>
  <div class="descripcion"><br>
  Gracias a un proceso único de laminación impermeable y respirable directamente sobre la superficie, sella completamente el producto impidiendo el ingreso del agua. <br>
  Desde hace muchos años se han diseñado tecnologías que permiten repeler el agua y mantener nuestros pies secos aún yendo por caminos y parajes húmedos. Gore-Tex es una marca de membranas de reconocido prestigio, pero Columbia está haciendo las cosas muy bien y aquí nos presenta su tecnología Outdry. Ellos defienden que superan las membranas anteriores de otras marcas y las suyas propias por un motivo:<br>
  La membrana está pegada a la zapatilla, mientras que otras membranas son como un calcetín y entre el zapato y la membrana hay una cavidad que se llena de agua, y aunque esta no penetra en la zapatilla, sí que crea humedad y peso por el agua acumulada. <br>
  <br>
  Características<br>
  <br>
  #Absolutamente impermeable.<br>
  #Alto nivel de respirabilidad.<br>
  #Máxima adaptabilidad y flexibilidad.<br>
  <br>
  Para conocer mas acerca de este tipo de tecnologías, podés visitarnos en cualquiera de nuestras 5 sucursales Suiza Outdoor en Córdoba Capital.<br>
  <br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Qué carpa compro?</div>
  <div class="copete">Se viene la primavera y con ella las salidas de camping, en la siguiente nota te ayudamos a elegir la carpa que necesitas.</div>
  <div class="descripcion">Principalmente, una carpa no es un gasto, es una inversión. Comprar algo de baja calidad por ahorrar unos pesos no tiene sentido.<br>
  La carpa es generalmente nuestro principal refugio en la Naturaleza. Por eso, si es buena, brinda seguridad real y psicológica -ya que uno confía en ella- y la inversión se amortiza porque nos acompañará por muchos años.<br>
  Lo que debemos tener en cuenta a la hora de comprar nuestra carpa:<br>
  <br>
  -Peso: “Necesito la carpa más ligera y flexible que no haga bulto en mi mochila ni entorpezca mi caminata o largo viaje”. Hay muchas que cumplen con este requisito, pero a veces sacrifican robustez y seguridad a cambio de menos peso.<br>
  -Humedad: “Necesito la carpa con el mejor aislamiento contra la lluvia”. Excelente criterio a considerar si vamos a las montañas, a un lugar con nieve o de clima tropical.<br>
  -Precio: “Una carpa básica como para pasar el día sería suficiente”. ¿Por qué no? Hay carpas que cuestan demasiado barato. Suficiente si lo tuyo es acampar muy esporádicamente y a lugares que no sean exigentes como el jardín de campo de un amigo o un día entero sin pasar la noche en un lugar frío.<br>
  -Temperatura: “Necesito una carpa que mantenga la temperatura al interior”. ¿Te vas a las altas montañas nevadas? O bien eres un fotógrafo de National Geographic explorando el Himalaya. Considera una carpa que no condense al interior pero que mantenga la temperatura. Aún así, la mayor responsabilidad de tu calidez la tiene una excelente bolsa de dormir.<br>
  -Durabilidad: “Necesito una carpa de material muy resistente, en especial contra los fuertes vientos”. Seguro que sí, especialmente si vas a lugares descampados con vientos que pueden desgarrar tu carpa o terrenos pedregosos y agrestes.<br>
  -Espacio: ¿Necesito una carpa para entrar con mis amigos, para mí y mi pareja o para mí solo?. O de repente quieres viajar con tu bicicleta y ponerla al interior. Sea como sea una carpa grande implica más peso, eso a veces importa y a veces no. Si no viajas en compañía lo mejor sería una carpa para una sola persona y si viajas en bici puedes considerar más espacio para guardarla dentro.<br>
  <br>
  Ventajas de una carpa tipo iglú:<br>
  -Son más livianas<br>
  -Conservan mejor la temperatura si tienen doble techo hasta el piso<br>
  -Por su forma, ofrecen menos resistencia al viento (se mueven menos cuando hay mucho viento)<br>
  -Tienden a ser más impermeables<br>
  -Muchas tienen aleros o ábsides que facilitan el almacenaje de equipo y otras actividades<br>
  -Se limpian más fácilmente<br>
  -No requieren un terreno completamente nivelado ni todos los vientos puestos para permanecer paradas y sin mojarse en el interior<br>
  <br>
  Ventajas de las carpas canadienses:<br>
  -Soportan tratos más rústicos (especiales para chicos y preadolescentes que no cuidan mucho las cosas)<br>
  -Suelen ser más altas, y más cómodas para quienes son altos, gordos, etc.<br>
  -Pueden acoplársele antecarpas o aleros<br>
  -Su piso es más resistente, y su confección general también (es bastante difícil que se desgarren, por ejemplo, por acción del viento)<br>
  -Sus parantes son más fuertes y resistentes</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Tips para elegir una mochila de hidratación</div>
  <div class="copete">La popularidad de las mochilas de hidratación ha crecido enormemente en los últimos años. Los beneficios de usar una 
  mochila de hidratación son evidentes...</div>
  <div class="descripcion">Facilidad para beber: No tienes que soltarte para beber, evitando tener que ralentizar o parar la marcha.<br>
  <br>
  Mayor capacidad que una botella.<br>
  <br>
  Aporta un almacenamiento adicional para lo esencial.<br>
  Eficiencia y rendimiento: Debido a que la ingesta de agua es más simple con una mochila de hidratación, se tiende a beber más a menudo, con lo que se evitan problemas de hidratación.<br>
  <br>
  Protección: Una mochila de hidratación también te aportará cierta protección en la espalda a la hora de protegerte de una <br>
  <br>
  posible caída.<br>
  <br>
  FACTORES A TENER EN CUENTA AL COMPRAR UNA MOCHILA DE HIDRATACIÓN<br>
  <br>
  TAMAÑO Y CAPACIDAD<br>
  El tamaño depende básicamente del tipo de ruta que vas a realizar. Aunque todos queremos llevar el mínimo peso posible en la espalda, la capacidad es uno de los elementos más importantes a la hora de elegir una mochila. Un buen diseño será pequeño, compacto y, aparte de la capacidad de agua que necesitemos, deberá tener los suficientes compartimentos y bolsillos para nuestras necesidades.<br>
  <br>
  Una mochila con capacidad de 1 ó 2 litros y espacio de almacenamiento suficiente para lo esencial (llaves, celu, barrita energética y herramientas) es ideal para rutas cortas de 1 ó 2 horas de duración.<br>
  Para competición y rutas de más de tres horas, es aconsejable llevar una mochila con capacidad para 3 litros de agua y una capacidad de almacenamiento de 9 ó 10 litros.<br>
  Una relación lógica puede ser unos ¾ de litro por cada hora de ruta.<br>
  <br>
  ESTABILIDAD Y CONFORT<br>
  Una buena mochila de hidratación es indispensable que sea cómoda y se adapte perfectamente al cuerpo del usuario. Debe <br>
  proporcionar estabilidad y distribuir uniformemente el peso. Observa que lleve ajustes eficientes y correas que se <br>
  enganchen en el pecho, te proporcionarán una mayor estabilidad.<br>
  <br>
  COMPARTIMENTOS Y ORGANIZACIÓN<br>
  Organizar la carga correctamente es un factor muy importante a la hora de ganar en comodidad y estabilidad. Para ello es <br>
  necesario que nuestra mochila incluya una serie de compartimentos para guardar los artículos esenciales.<br>
  <br>
  Es necesario un bolsillo que ocupe todo el tamaño de la mochila para guardar ropa o un impermeable, mientras que los <br>
  bolsillos de malla externos son muy útiles para herramientas, barras de energía etc… Muchos modelos también tienen <br>
  bolsillos internos acolchados o termosellados para una mayor protección contra impactos, para las llaves, el celu…<br>
  La bolsa del agua nunca debe estar suelta dentro de la mochila, ya que se movería contantemente, pudiendo llegar a <br>
  desequilibrarnos.<br>
  Si en tu modalidad de competición necesitas llevar dos cascos, una característica muy útil es un departamento para poder <br>
  guardar el casco abierto y anclajes para poder atar el casco integral.<br>
  <br>
  VENTILACIÓN<br>
  Aunque es imposible mantener la espalda completamente seca, una mochila con la espalda acolchada y bien ventilada hará <br>
  que la sensación de calor en la espalda no sea tan desagradable. Algunas mochilas incluyen unos canales de ventilación <br>
  que permiten que el aire fluya entre mochila y espalda, evitando la acumulación del sudor. Esto es aplicable también a <br>
  los tirantes de la mochila.<br>
  <br>
  DURABILIDAD DE LOS MATERIALES<br>
  Nuestra mochila de hidratación debe estar construida con materiales suficientemente ligeros pero fuertes, que no se <br>
  rompan a la primera caída, y que se mantengan libres de fugas. <br>
  <br>
  Para conocer mas acerca de este tipo de mochilas, podés visitarnos en cualquiera de nuestras 5 sucursales Suiza Outdoor <br>
  en Córdoba Capital.<br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo"> La importancia de usar un correcto calzado de montaña.</div>
  <div class="copete">En el mercado podrás encontrar una amplia variedad de zapatos para montaña, los cuales no sólo se distinguen por su precio y marca, sino principalmente por sus funciones frente a las diversas actividades que puedes realizar en este terreno particular.</div>
  <div class="descripcion"><br>
  Calzar las prendas apropiadas es fundamental, pues no es una cuestión de estética, sino de funcionalidad. El calzado de montaña ofrece una superficie de apoyo resistente a piedras y tierras, mientras que da apoyo a tus tobillos y mantiene tus pies secos y cálidos, aunque ventilados, durante toda la experiencia. <br>
  Asegúrate de que el modelo se ajuste a tu talón, mientras que permita el movimiento de los dedos del pie, pues tenerlos apretados impediría el correcto flujo sanguíneo a la zona.<br>
  <br>
  Tipos de calzados de montaña<br>
  <br>
  Calzado de suela blanda para senderismo o hiking<br>
  <br>
  Las botas de montaña de planta blanda son las apropiadas para terrenos simples, senderos marcados y largas caminatas. Estas zapatillas tienen una suela de goma flexible, son livianas y bajas, apenas cubriendo el hueso del tobillo en algunos casos, permitiendo la adecuación natural del pie al terreno sin imponer tensión en la torsión del empeine ni de la planta. La huella es texturada, facilitando mejor adherencia a suelos sueltos de polvillo o grava. Se las solicita bajo el nombre de "botas de senderismo o de hiking".<br>
  <br>
  <br>
  Botas semi rígidas para trekking<br>
  <br>
  Son las elegidas para circuitos con intensidad media o formas complicadas, en terrenos pedregosos o compactados. La suela es algo más dura, brindando protección y permitiendo circular por rocas sin causar tensión en el empeine, la planta ni los tobillos.<br>
  La altura de la caña es algo mayor, en especial en los modelos para terrenos húmedos, evitando la torsión del tobillo y protegiéndolo. Es ideal para circuitos intermedios en duración, pues son calzados algo más pesados que requieren de esfuerzo físico en su uso. Se las reconoce como los modelos de "trekking".<br>
  <br>
  Calzado rígido para alta montaña<br>
  <br>
  Las botas rígidas son las adecuadas para rutas de alta montaña y recorridos con alto nivel técnico. Su suela es rígida y su caña más alta y reforzada. Permite un mínimo movimiento al pie, impidiendo la torsión lateral y dando así mucha más protección al tobillo. Son zapatillas pesadas y calientes en el pie, por lo que se las usa sólo cuando el terreno así lo exige. <br>
  Si el suelo requiere del uso de crampones, este es también el modelo más apropiado para cuidar del pie y dar un mejor apoyo que proteja las rodillas y articulaciones de la pierna. <br>
  Puedes pedirlas como "botas de alta montaña" o de "alta montaña extrema", de acuerdo al sitio en el que vayas a utilizarlas.<br>
  <br>
  Para conocer mas acerca de este tipo de calzado, podés visitarnos en cualquiera de nuestras 5 sucursales Suiza Outdoor en Córdoba Capital.<br>
  <br>
  <br>
  <br>
  <br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Que significa Secado Rápido o FlashDry?</div>
  <div class="copete">
  La tecnología FlashDry™ está diseñada para redirigir la humedad a la superficie del tejido y eliminándola rápidamente para ofrecer al usuario sequedad y comodidad durante actividades aeróbicas intensas. ..</div>
  <div class="descripcion">Los tejidos FlashDry™ de la marca The North Face han sido diseñados para repeler la humedad y mantenerla en el exterior y ayudar así a favorecer el secado y mantener al usuario cómodo tras realizar actividades acuáticas.<br>
  <br>
  Los tejidos de algodón FlashDry™ están diseñados para ofrecer una gestión mejorada de la humedad y un tiempo de secado más eficaz en comparación con las telas de algodón tradicionales. Estos tejidos ofrecen la comodidad del algodón a la vez que presentan un mejor rendimiento en la gestión de la humedad.<br>
  <br>
  FlashDry ™ ofrece ventajas en cuanto a la gestión de la humedad y los tiempos de secado, pero también se ha diseñado con una mayor resistencia a la abrasión y los desgarrones para una durabilidad extrema y un gran rendimiento en las condiciones más complejas.<br>
  <br>
  Los tejidos FlashDry ™ se han diseñado para ofrecer ventajas en cuanto a una gran velocidad de secado y facilidad de mantenimiento, pero también se han diseñado con una mayor resistencia a la abrasión y los desgarrones para una durabilidad extrema y un gran rendimiento en condiciones complicadas.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">The North Face vs el Viento</div>
  <div class="copete">
  Los tejidos WindWall™ han sido diseñados para reducir enormemente los efectos del viento frío a la vez que ofrecen la flexibilidad y transpirabilidad para mantener la comodidad del usuario sin producirle demasiado calor.</div>
  <div class="descripcion"><br>
  Los productos de soft shell WindWall™ han sido diseñados en dos opciones: windproof (a prueba de viento) y wind resistant (resistentes al viento). Nuestras opciones windproof son las que ofrecen más protección, son prácticamente impermeables y ofrecen una gran protección ante el viento, por lo que mantienen un nivel de transpirabilidad cómodo en condiciones climáticas frías. Las opciones wind resistant son las más versátiles, mejoradas con elasticidad y una permeabilidad exterior al aire reducida, son transpirables e impermeables y resultan ideales para una amplia gama de actividades y condiciones ambientales.<br>
  <br>
  Los productos de shell WindWall™ ofrecen una protección ligera y resistente con una alta capacidad de compresión para una versatilidad inmediata.<br>
  <br>
  Los productos de forro polar WindWall™ conservan la comodidad y transpirabilidad de los forros convencionales pero han sido diseñados para reducir el frío provocado por el viento y mejorar la calidez.<br>
  <br>
  Todos los productos que cumplen con nuestros estándares WindWall™ llevarán la marca WindWall™ y serán identificados claramente como windproof o wind resistant en las etiquetas de cada artículo.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Que es el Trail Running?</div>
  <div class="copete">
  Aprovechando la cercanía con el The North Face Endurance Challenge 2017 el domingo 2 de Abril en La Cumbrecita, Córdoba, te invitamos a conocer un poco màs acerca de esta disciplina; el Trail Running que día a día atrae a más corredores!</div>
  <div class="descripcion"><br>
  Trailrunning es un deporte que consiste en correr “fuera de pista”, por senderos de montaña, huellas, rastros o caminos secundarios, a través de montañas, cerros y montes, cruzando arroyos y ríos, con grandes trepadas y abruptas bajadas.<br>
  <br>
  Se diferencia de los maratones y el senderismo en que por lo general se lleva a cabo en lugares más agrestes, muy a menudo en terrenos montañosos con grandes pendientes donde se asciende y desciende continuamente; por ello, tanto la naturaleza del terreno como el desnivel del recorrido, además de la distancia, son dos características fundamentales de un recorrido de trailrunning.<br>
  Muchas son las carreras de trailrunning que se organizan a nivel mundial. Debido a que relativamente es reciente la historia de las carreras de montaña como deporte organizado, son muy pocas las entidades organizadoras establecidas.<br>
  En comparación con las carreras de atletismo en general, a menudo existe un menor número de participantes, siendo también limitado su cupo. Esto se debe a varias razones como la estrechez de los senderos, las limitaciones de los parques nacionales por donde muy a menudo se atraviesa, la seguridad y el cuidado del medio ambiente donde esta se desarrolla.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Como elegir un reloj deportivo</div>
  <div class="copete">Utilizar un reloj tiene interesantes beneficios para quienes practican  algún deporte, ya que pueden darnos información al instante de nuestro rendimiento deportivo
  </div>
  <div class="descripcion"><br>
  Debido a los avances de la tecnología existe una enorme oferta de gadgets <br>
  para corredores y los relojes no se quedan atrás, por lo que en esta nota te <br>
  contaremos cuales son las funcionalidades disponibles, para que puedas elegir <br>
  el mejor reloj.<br>
  FUNCIONALIDADES<br>
  Dejando de lado la cuestión presupuestaria, a continuación te contaremos <br>
  las distintas funcionalidades que tienen los relojes que puedes utilizar<br>
  y así seleccionar el que mas se ajuste a tus necesidades:<br>
  INFORMACIÓN:<br>
  Verifica que el reloj almacene la información de tus entrenamientos <br>
  (los mas económicos pueden no almacenarla).<br>
  Asimismo, antes de adquirir tu reloj , verifica que tipo de <br>
  información podrás tener a disposición.<br>
  GPS:<br>
  Cada vez aparecen mayor cantidad de dispositivos con GPS, y los relojes no podían ser la excepción.<br>
  Estos relojes, nos permiten saber al instante la velocidad y ritmo al que corremos,<br>
  además de la distancia recorrida.<br>
  AGUA:<br>
  Aunque no nademos, es indispensable que el reloj para correr que utilicemos sea a <br>
  prueba de agua (waterproof), ya que correr bajo la lluvia inevitablemente lo mojará.<br>
  PODÓMETRO:<br>
  Muchos relojes, incluyen conectividad con dispositivos podómetros <br>
  (miden la cantidad de pasos por minuto), lo que puede servirte para llevar un <br>
  control absoluto de tu cadencia.<br>
  PULSÓMETRO:<br>
  Un reloj para correr con conectividad con algún pulsómetro, <br>
  nos permitirá entrenar respetando zonas de entrenamiento y conocer <br>
  nuestras pulsaciones en cada kilómetro que recorremos.<br>
  CONECTIVIDAD:<br>
  Los relojes mas avanzados, utilizan sistemas de conectividad inalámbricas con nuestras<br>
  computadoras o sitios web, para así subir la información de nuestros entrenamientos a<br>
  la red. Otros, en cambio requieren que conectes con algún cable el reloj con tu pc.<br>
  <br>
  Aunque muchos corredores olvidan verificar este aspecto, resulta indispensable <br>
  que el reloj tenga algún sistema de iluminación que nos permita ver <br>
  la información cuando entrenamos de noche o muy temprano en la mañana.<br>
  <br>
  Estas son las principales funcionalidades que los relojes pueden tener, <br>
  selecciona las que mas te sirvan <br>
  y busca el dispositivo que encaje con tu presupuesto y las tenga.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Impermeables The North Face</div>
  <div class="copete">En Suiza Outdoor te invitamos a conocer un modelo de impermeable de The North Face ideal para días de lluvia; el Venture Jacket...</div>
  <div class="descripcion"><br>
  Sus características son:<br>
  <br>
  -Calce relajado.<br>
  -Membrana HyVent™ 2.5L a prueba de agua, respirable y con las costuras selladas.<br>
  -Capucha fija y completamente ajustable.<br>
  -Solapa dual con Velcro por sobre el cierre frontal.<br>
  -Bolsillos con cierre para manos.<br>
  -Cierres bajo los brazos.<br>
  -Puños ajustables con Velcro®.<br>
  -Empacable en un bolsillo.<br>
  -Tela jaspeada en varios colores.<br>
  <br>
  Pasá por cualquiera de nuestras sucursales en Córdoba Capital o desde nuestra Tienda Virtual https://www.suizaoutdoor.com.ar y encontrá tu favorito !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Impermeable o Resistente al agua?</div>
  <div class="copete">La diferencia entre elegir una prenda a prueba de agua o resistente al agua es importante, por eso te ayudamos a diferenciarlas
  para que puedas elegir la mas apropiada para ti y la actividad que practiques.
  </div>
  <div class="descripcion">Para empezar, debemos decir que a pesar de todas las innovaciones y mejoras que se han venido desarrollando en las <br>
  prendas impermeables, todavía no se ha inventado el tejido ideal o definitivo, entendiéndose como ideal aquel que cumpla<br>
  y ofrezca absoluta solución a todas nuestras necesidades como usuarios. Sin embargo, no cabe duda que se sigue avanzando<br>
  en este terreno, donde las innovaciones han sido mucho mayores a nivel de diseño técnico y/o soluciones de utilización<br>
  práctica, lo cual nos ayuda a clasificar más claramente los distintos modelos en función del tipo de usuario al que va <br>
  destinado. <br>
  <br>
  TIPOS DE PRENDAS IMPERMEABLES<br>
  En el mercado podemos encontrar infinidad de alternativas dentro de la categoría “impermeable” pero se pueden<br>
  clasificarlas en 3 grandes grupos: resistente al agua / transpirable (water-resistant/breathable), <br>
  a prueba de agua / transpirable (waterproof/breathable) y a prueba de agua (waterproof), ¿qué categoría es la adecuada<br>
  para ti? depende de cuánto tiempo vas a pasar en la lluvia, la intensidad y tipo de actividad. <br>
  <br>
  *Prendas resistentes al agua / transpirables (water-resistant/breathable)<br>
  Son prendas diseñadas para mantenerte seco en una llovizna o lluvia ligera, “resistente al agua”, quiere decir que <br>
  resiste hasta un punto, pues no es “a prueba de agua” como otras. Las prendas resistentes al agua son más transpirables <br>
  y generalmente menos costosas, y resultan una buena alternativa para actividades más intensas donde la transpiración es <br>
  alta. Dentro de esta categoría encontramos a las chaquetas rompevientos y soft shells.<br>
  <br>
  *Prendas a prueba de agua / transpirable (waterproof/ breathable)<br>
  Las prendas a prueba de agua y transpirables acogen a una amplia gama de usos y son las preferidas de la gente activa, <br>
  pues esta categoría de prendas busca brindar un equilibrio entre impermeabilidad y transpirabilidad, haciendo que los <br>
  usuarios se encuentren en confort la mayor parte del tiempo.<br>
  Las telas impermeables / transpirables repelen la precipitación al mismo tiempo que permiten evacuar el vapor del sudor, <br>
  sin embargo no siempre tan rápidamente como quisiéramos.  Este tipo de prendas, por su construcción y tecnología pueden <br>
  subdividirse a su vez en dos grandes subgrupos: laminados e inducidos.<br>
  -Impermeables laminados: utilizan membranas de alta tecnología (Gore-Tex, eVent,   MemBrain Strata, Conduit), las cuales <br>
  poseen microporos lo suficientemente pequeños para impedir el ingreso del las gotas de agua, pero al mismo tiempo lo <br>
  suficientemente grandes para permitir evacuar el vapor del sudor. Este tipo de prendas son más costosas. Este tipo de <br>
  construcción ofrece un mayor grado transpirabilidad y durabilidad.<br>
  -Impermeables inducidos: son prendas con recubrimientos (Precip, HyVent, Powertex) que se aplican al material exterior <br>
  por su parte interna. El principio es el mismo que el de los laminados, sin embargo no ofrecen el mismo grado de <br>
  transpirabilidad y durabilidad pero son más económicos y en la mayoría de casos más livianos.<br>
  *Prendas impermeables (waterproof) / no transpirable<br>
  Quizá el impermeable más básico y conocido pero el menos recomendado para ejercitarse al aire libre,<br>
  pues son bastante impermeables pero nada transpirables, con lo cual estaremos empapados al poco tiempo <br>
  de iniciado el ejercicio. Recomendable solamente para actividades de muy baja intensidad como avistamiento de aves,<br>
  pesca, observar deportes al aire libre, etc.<br>
  <br>
  <br>
  <br>
  <br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Te Presentamos la  THERMOBALL HYBRID </div>
  <div class="copete">
  Ésta campera que combina el abrigo de las camperas Thermoball y la elasticidad de un SoftShell llegó a nuestros locales Suiza Outdoor y en éste Blog te mostramos sus características.</div>
  <div class="descripcion"><br>
  Esta chaqueta liviana e híbrida con tecnología ThermoBall™ y Apex Aerobic(SoftShell) que entrega calor con un material externo liviano y elástico. Posee una gran respirabilidad, lo cual hace que sea perfecta para actividades aeróbicas.<br>
  <br>
  CARACTERISTICAS<br>
  Calce activo.<br>
  Nylon anti desgarro con insulado ThermoBall™.<br>
  Las mangas y los paneles en los costados poseen tecnología TNF™ Apex Aerobic.<br>
  Cierre completo.<br>
  Capucha caída con abertura en la parte frontal.<br>
  Bolsillos con cierre.<br>
  Cordón elástico en la pretina y puños elasticados.<br>
  <br>
  Encontrá tu modelo preferido The North Face Hybrid en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">TKA: Tejidos Térmicos de Micropolar The North Face</div>
  <div class="copete">
  Adelantándonos al invierno, te ofrecemos las especificaciones técnicas acerca de los tejidos térmicos exclusivos de The North Face para esta temporada.</div>
  <div class="descripcion"><br>
  Los forros TKA (Thermal Kinetic Advancement) son tejidos patentados creados por The North Face® que ofrecen un aislamiento y resistencia al desgaste superiores. Hay tres pesos diferentes de forros TKA en nuestros productos, y también ofrecemos el TKA Superstretch súper elástico que resulta excelente para actividades aeróbicas.<br>
  TKA 100<br>
  <br>
  El TKA 100 es un forro de microfibras ligero y de alto rendimiento con un acabado de lujo. El microforro TKA ofrece calidez en la zona próxima a la piel o en un sistema de capas. Este tejido 100 % poliéster ha sido diseñado para ser duradero y resistente a la formación de bolitas. Ofrece calidez aunque esté mojado, se lava bien, se comprime mucho y puede guardarse con facilidad.<br>
  <br>
  VENTAJAS<br>
  <br>
  Calidez<br>
  Forro ligero con aislamiento superior<br>
  Resistencia al desgaste.<br>
  <br>
  Encontrá tu modelo Micropolar preferido en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">¿Qué es Softshell?</div>
  <div class="copete">
  En el Blog de hoy, te traemos detalles acerca de este tipo de prendas, ideales para el frío que se viene!
  </div>
  <div class="descripcion"><br>
  Softshell es un tipo de tejido, el cual está compuesta por 2 ó 3 capas distintas para crear una combinación única en el campo textil. Estas capas estas compuestas por poliéster en la parte exterior, una membrana transpirable intermedia y micro lana en el interior. No se sabe con exactitud cuándo empezó a utilizarse el tejido softshell por primera vez, aunque el termino “softshell” como tejido para prendas de ropa empezó a hacerse popular en la década de los 90. Hoy en día es principalmente utilizado para camperas, las cuales hacen la función de impermeables y cortavientos.<br>
  Se caracteriza por la conformación de tres capas diferentes en una sola prenda. Podemos decir entonces que una prenda de softshell es un "todo terreno": una combinación adecuada, para todas las condiciones climatológicas.<br>
  Características generales:<br>
  <br>
  -Funcional<br>
  -Cómoda<br>
  -Moderna<br>
  -Transpirable<br>
  -A prueba de viento<br>
  -Resistente al agua<br>
  <br>
  Encontrá tu modelo preferido de Softshell en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra Tienda <br>
  Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">The North Face Inlux Jacket</div>
  <div class="copete">
  En el Blog de hoy, te traemos detalles acerca del modelo Inlux, ideal para el frío que se viene!</div>
  <div class="descripcion"><br>
  <br>
  Te contamos que es una campera versátil que proporciona calidéz e impermeabilidad en todo momento. <br>
  Tecnología DryVent ™ impermeable y respirable con costuras Termoselladas. <br>
  Aislamiento sintético y forro de polar suave para mayor comodidad y calidéz. <br>
  Capucha ajustable y desmontable. <br>
  Dos bolsillos con cierre y uno interno en el pecho para dispositivos multimedia. <br>
  Cierre en las axilas para ventilación. Ajuste con Velcro® en los puños.<br>
  Barrera de humedad impenetrable para garantizar la sequedad. <br>
  INSULATED: El aislamiento atrapa el aire caliente para reducir la pérdida de calor y aumentar el calor. <br>
  Tejidos ligeros que ayudan a regular la temperatura del cuerpo.<br>
  <br>
  Encontrá tu modelo preferido The North Face Inlux en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra <br>
  <br>
  Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Buscás calzado Salomon?</div>
  <div class="copete">
  Suiza Outdoor Since 1888 tiene la mayor variedad y mejores precios para la actividad que necesites!
  </div>
  <div class="descripcion"><br>
  Encontrá tu modelo preferido de SALOMON en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra <br>
  Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Aires Comprimido Gamo</div>
  <div class="copete">

  En el blog de hoy te traemos especificaciones acerca de las tecnologías aplicadas en los rifles de aire comprimido de la marca española Gamo.
  </div>
  <div class="descripcion"><br>
  IGT Mach 1<br>
  Con el nuevo sistema IGT Mach 1 se sustituye el clásico muelle por un sistema neumático de carga especial, piston-gas de última generación que consigue mejorar las prestaciones de una forma increíble. Con el sistema IGT Mach 1 se garantiza: <br>
  mayor velocidad, impacto y precisión.<br>
  <br>
  IGT (Inert gas technology)<br>
  Nuevo sistema neumático de carga que sustituye el muelle tradicional por un pistón-gas. Esta nueva tecnología mejora tu precisión, garantiza una menor vibración y acorta el “lock time” (tiempo del balín en el cañón una vez apretado al <br>
  gatillo).<br>
  <br>
  Whisper<br>
  El silencio como premisa. El sistema Whisper consiste en un cañón de acero cuya boca esta enfundada en cámaras de polímero con un sistema moderador de sonido. El moderador comprime el sonido y previene la expansión de éste hasta un 52% comparado con un sistema estándar.<br>
  <br>
  Bull Whisper<br>
  Sistema reconocido por los mejores tiradores. El cañón Bull Whisper reduce significativamente el sonido de salida del balín gracias a la cámara acústica que se encuentra al final del cañón. Cuando el balín pasa a través del sistema Bull Whisper, este disipa el aire a través de las cámaras del sistema reduciendo el sonido, sin perder velocidad ni <br>
  efectividad.<br>
  <br>
  <br>
  <br>
  Whisper fusion<br>
  Nuevo sistema Whisper Fusion, la primera tecnología de Gamo que combina 2 tecnologías Whisper. Cuando el balín alcanza el final del cañón, accede a una primera innovación tecnológica que modera el sonido mediante diferentes cámaras de aire y finalmente llega a la segunda fase, que modera el donido usando cámaras acústicas.<br>
  <br>
  <br>
  SAT (Smooth Action trigger)<br>
  Los nuevos gatillos con tecnología SAT (gatillo de acción suave, en inglés) son un nuevo y revolucionario diseño de <br>
  presión en dos etapas, que le permite a los tiradores efectuar el disparo de la forma más controlada posible, mejorando la precisión.<br>
  <br>
  <br>
  RRR (Recoil Reducing Rail, raíl de reducción del retroceso)<br>
  Este sistema se ha desarrollado para atenuar las enormes tensiones que soporta la mira debido al intenso retroceso de las carabinas de aire comprimido de gran potencia. El raíl de reducción de retroceso utiliza una estructura de aluminio de dos piezas, separadas por montantes de doble polímero que absorben las ondas de choque. Una vez montado en el raíl de reducción de retroceso, las tensiones de retroceso que soportan los componentes internos de la mira se reducen en casi un 100%.<br>
  <br>
  <br>
  Encontrá tu modelo preferido de GAMO en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra <br>
  Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">LA AUTÉNTICA BOTA AMARILLA </div>
  <div class="copete">

  Después de 44 años la icónica Timberland® 6-inch boot sigue siendo un clásico que simboliza la artesanía auténtica. </div>
  <div class="descripcion"><br>
  <br>
  LA HISTORIA <br>
  En 1973 la pequeña población de Newmarket, en New Hampshire, se convirtió en el lugar de nacimiento de una leyenda estadounidense. Sidney Swartz creó la icónica Timberland® 6-inch boot, una de las primeras botas impermeables de su clase, pensando en los trabajadores. Se convirtió en un clásico al instante y desde entonces es un icono de estilo en muchos lugares del mundo. <br>
  <br>
  CUERO IMPERMEABLE DE CALIDAD SUPERIOR <br>
  Empleamos nobuk granulado de la mejor calidad y sellamos cuidadosamente cada costura para garantizar su impermeabilidad. <br>
  <br>
  SUELAS EXTERIORES COLOR MIEL<br>
  La gruesa suela de goma dentada, diseñada para durar, estabiliza el paso con un agarre fiable.<br>
  <br>
  MÁS DETALLES<br>
  El cuello acolchado tiene un revestimiento de espuma doble y refuerzo de nailon para amortiguar el tobillo con un ajuste perfecto y cómodo.<br>
  Los cordones de este modelo clásico son de nailon reciclado 100 % y se han rediseñado para que no se desaten.<br>
  Incluye un segundo juego de cordones para una mayor versatilidad.<br>
  El aislamiento PrimaLoft® de 400 gramos proporciona gran protección contra el frío.<br>
  La suela Anti-Fatigue forrada en piel hace que los pies más activos disfruten de un confort superior.<br>
  <br>
  Encontrá tu modelo de Timberland preferido en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Para este Invierno... OmniHeat</div>
  <div class="copete">
  En el Blog de hoy te contamos algunas de las características del Omni-Heat, exclusivo de Columbia, usado en camperas, gorros, guantes e indumentaria en general.


  </div>
  <div class="descripcion"><br>
  La clave de esta innovadora tecnología radica en su propiedad reflectante, garantizando mantenerte cálido y confortable en condiciones de frío intenso mientras provee un ambiente confortable y seco, ya que logra expulsar la humedad mediante las propiedades conductoras de los puntos metálicos de su tejido.<br>
  <br>
  Características<br>
  <br>
  Refleja el calor corporal<br>
  Altamente respirable<br>
  Reduce la necesidad de aplicar capas gruesas<br>
  Elimina la humedad y te mantiene cómodo y seco.<br>
  <br>
  Encontrá tu abrigo con Omni-Heat en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra <br>
  Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Beretta APX</div>
  <div class="copete">
  Para los amantes de las armas de fuego, en el Blog de hoy te brindamos características de la Pistola 9mm de Beretta; la APX.

  </div>
  <div class="descripcion"><br>
  La principal característica de esta pistola semiautomática, disponible por el momento en calibre 9mm Parabellum y .40 S&W, es que por primera vez en la historia de la compañía italiana, un arma de grueso calibre funciona mediante el sistema de aguja lanzada (striker fired en su popular acepción anglosajona). Un modelo más que se suma por tanto a la tendencia iniciada y siempre apoyada por Glock.El cual sustituye al tradicional martillo que desde 1915 ha acompañado a todas las pistolas de grueso calibres de la fábrica italiana, entre ellas la Beretta 92 FS y la M9. A diferencia de ambos modelos, la nueva Beretta APX está montada sobre un armazón de polímero y cuenta con un carril táctico Picatinny, ubicado justo debajo de su cañón. Todos sus controles son ambidiestros, y su corredera metálica presenta diferentes acanaladuras que facilitan su manejo con guantes, tal y como sucede con el amplio arco de su guardamontes.<br>
  <br>
  La APX tiene una capacidad de 17+1 , comandos completamente ambidiestros, riel porta accesorio, aparatos de puntería de combate y seguro en cola de disparador.<br>
  <br>
  Según los responsables de Beretta, su nueva pistola APX se ha diseñado teniendo en mente las necesidades de los militares, los agentes de policía y demás fuerzas del orden, ya que serán sus principales usuarios. Tal y como aseguró el Manager General de la compañía, Carlo Ferlito, en la presentación oficial de su nueva pistola, “Beretta ha esperado a entrar en el mercado de las armas cortas de aguja lanzada hasta saber que teníamos una pistola que podía satisfacer las necesidades específicas del profesional”. En este sentido, la Beretta APX has estado más de 3 años en fase de producción, lo que da buena cuenta del interés y las expectativas que la empresa italiana ha depositado en este modelo. “Probamos ampliamente la pistola con los profesionales que iban a ser los usuarios finales de la APX, e íbamos incorporándole todos las sugerencias y mejoras que nos comentaban. Así, hemos conseguido como resultado una pistola que ofrece un rendimiento superior en cuanto a resistencia, fiabilidad, precisión y ergonomía”, cita el propio Ferlito.<br>
  <br>
  Para más detalles acerca de la Beretta APX comunicate con nosotros a través de Facebook, twitter o telefónicamente.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo"> X Ultra Mid 2 GTX</div>
  <div class="copete">
  En este Blog te traemos especificaciones de la bota más vendida de Salomon; la X Ultra Mid 2 GTX

  </div>
  <div class="descripcion"><br>
  X Ultra Mid 2 GTX suma la protección de la caña media con el Gore-Tex. Salomon la ha ideado para media montaña pero <br>
  podrás incluso utilizarla en alta.<br>
  Monta un chasis avanzado para una mayor estabilidad y una suela que te permitirá ir por terrenos técnicos sin problemas.<br>
  Perfecta para el usuario de media-alta montaña que quiere una protección total frente a la lluvia, el frío y hace excursiones por terrenos técnicos y rocosos.<br>
  <br>
  La piel duradera y GORE-TEX® proporcionan confort y protección en esta bota de trekking estable y ligera.<br>
  • Textil impermeable: Tratamientos de larga duración combinados con pieles de la mejor calidad para asegurar un nivel de <br>
  impermeabilidad fiable.<br>
  • Lengüeta reforzada para evitar que entren pequeñas piedras.<br>
  • Protección frente al barro.<br>
  • Bolsillo para guardar los cordones sobrantes.<br>
  • GORE-TEX Mantiene tus pies secos por fuera y por dentro.<br>
  • Chasis avanzado: El chasis más ligero y avanzado de Salomon, situado entre la suela y la entresuela, maximiza el <br>
  control de tracción, la gestión de la energía y ofrece una gran protección para un recorrido eficaz, así <br>
  como una gran respuesta y estabilidad.<br>
  • Sistema SensiFit: El sistema Sensifit trabaja para acolchar el pie proporcionando un ajuste preciso y seguro.<br>
  • Puntera protectora de goma.<br>
  • Espuma en el talón.<br>
  • Botín interior impermeable.<br>
  • Suela Contragrip: Con una combinación de gomas para un gran agarre en cualquier circunstancia.<br>
  • EVA inyectada.<br>
  • Ortholite: El forro interior OrthoLite® combina la espuma específica Ortholite® con la talonera EVA. La espuma Ortholite® crea un entorno más fresco, seco, higiénico y acolchado bajo el pie. No se rompe ni pierde eficacia con el <br>
  tiempo.<br>
  A base de neumáticos reciclados para proteger el medio ambiente. La talonera de EVA ofrece una mejor sujeción del talón y un mayor acolchamiento.<br>
  <br>
  Encontrá tu modelo de Salomon preferido en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo"> Salomon X ultra 2</div>
  <div class="copete">
  En el Blog de esta semana te traemos todo lo que necesitas saber sobre un calzado ideal para correr en la montaña...</div>
  <div class="descripcion"><br>
  <br>
  <br>
  <br>
  La X Ultra 2 es perfecta para avanzar rápido durante el día y relajarse por la noche. Con un chasis estable de alto rendimiento y una parte superior precisa y transpirable, podrás avanzar a toda velocidad por cualquier sendero. Sus líneas elegantes y gran ajuste son aptas para cualquier aventura.<br>
  <br>
  Responden excelentemente tanto en terrenos húmedos y con barro como en zonas secas y escarpadas. El tipo de suela que lleva es el ContraGrip.<br>
  <br>
  Rígida y no muy flexible cualidad que favorece la protección del pie cuando se transita por zonas rocosas. Los tacos son multidireccionales para proporcionar mayor agarre y evitar la acumulación de barro en las hendiduras.<br>
  <br>
  Todo el chasis de la zapatilla está cosido con espuma y tejido ultra resistente con el objetivo de crear un molde perfecto. La forma de la plantilla promueve que el pie se quede en el lugar correcto para evita la fricción al caminar.<br>
  <br>
  Puntera reforzada con goma en la parte de los dedos. A diferencia de otras marcas que solo cubren el dedo gordo, el refuerzo de la serie X Ultra 2 se extiende desde el dedo gordo hasta el dedo pequeño.<br>
  <br>
  El sistema QuickLace es exclusivo de la marca Salomon y únicamente esta incorporado en la gama alta de zapatillas de montaña. <br>
  Son unos cordones finos pero ultra resistentes unidos entre si por un soporte de plástico que actúa de tope, con un solo tirón puedes abrocharte las zapatillas. La marca ha habilitado unos pequeños bolsillos en la lengüeta de las zapatillas con el objetivo de guardar el cordón una vez abrochado para evitar tropiezos.<br>
  El sistema solo lo encontrarás en la gama alta de zapatillas de la serie.<br>
  <br>
  Encontrá tu modelo de Salomon preferido en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Aislamiento Térmico con Pluma</div>
  <div class="copete">
  Usando solamente las plumas de alta calidad en todos sus productos, The North Face® evalúa cada partida de plumas en tres fases de producción para la suavidad, resistencia a la humedad y la capacidad de recuperar la suavidad después de compresión.</div>
  <div class="descripcion">El poder de relleno de plumas es un indicador de dos características claves: la relación calor/peso y compresibilidad, <br>
  ambos críticos para retener calor y proporcionar comodidad. El verdadero poder de relleno se mide poniendo una onza de <br>
  plumas de ganso en un cilindro graduado y midiendo el volumen que ocupan las plumas en pulgadas cúbicas. Los productos de <br>
  The North Face® se aislan con plumas exclusivas, con un alto poder de relleno de entre 550 y 900, lo cual representa la <br>
  más alta calidad de plumas de ganso disponible comercialmente.<br>
  <br>
  <br>
  Beneficios<br>
  <br>
  Calor y comodidad superiores.<br>
  Aislamiento liviano.<br>
  Más suave que plumas de menos poder de relleno (solamente 900 + 800)<br>
  Calidad más alta, plumas de alto poder de relleno.<br>
  <br>
  Pluma 900<br>
  Es la calidad más alta disponible en el mercado y se considera el absoluto máximo de aislamiento – con una relación <br>
  <br>
  calor/peso que no se compara con nada, es altamente compresible, ultra liviano y sorprendentemente cálido. Está <br>
  <br>
  disponible en cantidades extremadamente limitadas, por lo tanto The North Face® utiliza Pluma de 900 exclusivamente en su <br>
  <br>
  línea premium Summit Series® outerwear y gear, donde el peso mínimo y calor máximo son cruciales.<br>
  <br>
  Pluma 800<br>
  Calor superior de una alta relación agrupación/pluma, la pluma 800 presenta alta suavidad y compresibilidad para el<br>
  calor y rendimiento excepcionales.<br>
  <br>
  Pluma 700<br>
  Presenta una fuerte relación calor/peso y proporciona aislamiento de poco peso en una gama variada de temperaturas.<br>
  <br>
  Pluma 600<br>
  Es cálido, liviano y durable. Es ideal para actividades generales outdoor y el uso diario.<br>
  <br>
  Encontrá tu modelo preferido en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Linterna halógena o led?</div>
  <div class="copete">

  La linterna es un elemento en muchas ocasiones imprescindible, por eso desde Suiza Outdoor te ayudamos a elegir entre una u otra...</div>
  <div class="descripcion"><br>
  <br>
  <br>
  <br>
  Hace unos años no había dudas: únicamente tenías unos pocos modelos para elegir, y con bombillas halógenas o sencillas. Hoy en día la oferta es mucho más amplia, con halógenas, xenon, HID, led… y la elección se puede complicar muchísimo.<br>
  Básicamente serán dos tipos los que más se usan: halógenas y led. <br>
  <br>
  Las halógenas nos ofrecen una luz con una temperatura de color más cálida (luz amarillenta) aunque la bombilla es menos <br>
  eficiente y una gran parte del consumo de energía se transforma en temperatura (la lámpara se calienta), con lo cual tienen un <br>
  consumo mayor y las pilas durarán menos. <br>
  Esto no es excesivamente importante si se emplean pilas recargables, baratas y con muchas capacidad. Algunas de estas linternas se pueden trucar para tener mayor potencia y así dar más luz.<br>
  <br>
  Las linternas led ofrecen un consumo considerablemente menor, con lo cual las baterías (pilas) tienen un mayor duración. Esta <br>
  tecnología se está desarrollando muy rápido, y se ofrecen ya linternas que nos dan más luz y mucho más concentrada a un coste <br>
  bajo, pues hasta hace poco tiempo las led eran excesivamente caras.<br>
  Su temperatura de color es fría generalmente (luz blanca) y cuando realmente se aprecia la luz que ofrecen es en condiciones de <br>
  oscuridad total.<br>
  <br>
  En cuanto al haz, a más concentrado, más iluminará en ese punto y más alcance, pero fuera de ese spot no habrá luz. Cuanto más abierto el haz, tendremos una visión más completa, pero menos intensidad a igualdad de potencia que con una de haz concentrado.<br>
  A veces, debemos huir de los datos técnicos, que si lúmens o potencia, pues pueden ser engañosos. Por ejemplo puede ofrecer <br>
  muchos lumens en un haz muy reducido, al concentrarlo todo en un punto muy pequeño.<br>
  Finalmente, para gustos colores. Hay a gente que le gustará más una u otra. Haz abierto, o cerrado, luz más fría o más cálida… <br>
  <br>
  Lo que no hay duda es que en Suiza Outdoor encontrarás la linterna que mas se adapte a tu necesidad.<br>
  Visitá nuestras sucursales en Córdoba Capital o nuestra Tienda Virtual desde cualquier punto del país entrando a <br>
  www.suizaoutdoor.com.ar</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Como utilizar una brújula</div>
  <div class="copete">Como sabemos, la brújula es, después del mapa, el elemento más importante para ser capaz de orientarse en un
  territorio desconocido. En este blog te damos una breve reseña de como usarla.
  </div>
  <div class="descripcion"> Su funcionamiento se basa en la atracción magnética que ejerce la Tierra sobre los objetos imantados, de forma que la <br>
  aguja (imantada), siempre indique la dirección del norte magnético.<br>
  <br>
  La precisión de la brújula es mayor en bajas altitudes que en  alturas superiores. Existen varios tipos de brújulas, entre ellas <br>
  se destacan; la cartográfica, la lensática y la brújula de dedo.<br>
  La mayoría de la brújulas constan de partes fundamentales:<br>
  Base: es una pieza normalmente rectangular, de plástico transparente, con reglas o escalas en sus bordes, tanto en alguna escala habitual para mapas topográficos (1:50.000), como en otra más común en mapas de orientación (1:15.000).<br>
  De esta forma no resulta difícil calcular distancias sobre el plano. En la base también puede haber una lupa y una <br>
  flecha roja ("flecha de dirección"), la cual marca el rumbo a seguir, si manipulamos la brújula con corrección..<br>
  <br>
  Limbo: Está colocado sobre la base, es una pieza circular giratoria. Está graduado en 360 grados dentro de los cuales <br>
  están incluidos los cuatro puntos cardinales, también tiene líneas norte-sur, una flecha para utilizar como norte, y por <br>
  supuesto la aguja imantada que siempre señala al norte (su parte roja).<br>
  <br>
  Como usarla:<br>
  Elegir una ruta, colocar un canto de la brújula o una línea de dirección uniendo los puntos de la misma una recta de <br>
  rumbo. Desde donde estamos hasta donde queremos ir, o desde donde estamos en qué grados de dirección queremos ir.  <br>
  Sin mover la base de la brújula, giramos el limbo hasta hacer coincidir las líneas norte sur del limbo con las del mapa <br>
  (ponerlas paralelas), eso si, siempre con la flecha norte del limbo apuntando hacia el norte del mapa.   <br>
  Separamos la brújula del mapa, la tomamos en la mano, la mantenemos horizontal y nos giramos hasta que el norte del <br>
  limbo coincida con el norte de la aguja imantada. Manteniendo esta orientación, la regla de dirección de la base, es la <br>
  que nos marca el rumbo a seguir.<br>
  Para más información, acercate a cualquiera de nuestras sucursales de Suiza Outdoor.<br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo"> Carpa de 3 o 4 estaciones?</div>
  <div class="copete">
  Podemos ver que en muchos lados cuando se habla de carpas se las diferencia por su forma y también se las clasifica por "estaciones", ya sea carpas de 3 estaciones o de 4 estaciones. En este Blog te contamos de que se trata...</div>
  <div class="descripcion"><br>
  Carpa de 3 o 4 estaciones?<br>
  Podemos ver que en muchos lados cuando se habla de carpas se las diferencia por su forma y también se las clasifica por "estaciones", ya sea carpas de 3 estaciones o de 4 estaciones. En este Blog te contamos de que se trata...<br>
  <br>
  <br>
  Es casi intuitivo pensar que cuando se habla de estaciones son la estaciones climáticas del <br>
  año y es exactamente de eso mismo a lo que se refieren.<br>
  Cuando se habla de 3 estaciones, se refiere a una carpa con las cualidades para poder <br>
  acampar en climas típicos de primavera, verano y otoño.<br>
  Normalmente estas carpas sueles ser livianas y por ende con poca resistencia a los climas <br>
  más fuertes, pero más económicas. Lo que no quiere decir que no sean útiles, si no que <br>
  muchas veces cuando viajamos conviene llevar carpas con características para la zona <br>
  climática por donde vamos a estar, por el simple hecho de ahorrarnos lugar en la mochila y <br>
  principalmente peso. ¿Para qué queremos cargar una carpa de alta montaña si vamos a ir a un <br>
  lugar que es árido y hace calor la mayoría de los días? Ese esfuerzo extra puede marcar la <br>
  diferencia.<br>
  Las carpas llamadas de 4 estaciones están mejor preparadas para el invierno. Por lo que tienen mayor resistencia a vientos fuertes, su nivel de impermeabilidad es mayor y por ende suelen ser más costosas.<br>
  No necesariamente sean más pesadas que las de 3 estaciones. Los modelos de carpas para alta montaña hoy en día buscan utilizar materiales cada vez más livianos sin perder las características de impermeabilidad y respirabilidad.<br>
  Como son carpas con estructuras más rígidas, suelen ser un poco más complicadas de armar que <br>
  las anteriores, ya que las varillas vienen e configuraciones que parecen más extrañas, pero <br>
  son las responsables de brindarles seguridad.<br>
  <br>
  Encontrá la carpa que necesitás en cualquiera de nuestras sucursales Suiza Outdoor del Centro de Córdoba o si sos del interior a través de nuestra Tienda Virtual www.suizaoutdoor.com.ar</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Este invierno abrigate con THERMOBALL</div>
  <div class="copete">
  The North Face™ desarrolló en colaboración con Primaloft® una nueva alternativa sintética para la pluma de ganso.. el THERMOBALL.</div>
  <div class="descripcion">Thermoball™ es una revolución en la tecnología de aislamiento térmico. A diferencia de las insulaciones sintéticas tradicionales con filamento continuo, la pequeña y redonda fibra sintética Primaloft se agrupa estrechamente para imitar las agrupaciones de la pluma; conservando el calor entre pequeñas bolsas de aire.<br>
  Los exámenes independientes hechos por la Universidad de Kansas han mostrado que la tecnología Thermoball puede ofrecer poco peso, calor, y compresibilidad, igual a la pluma, con la ventaja que tienen las sintéticas de un aislamiento en un clima húmedo.<br>
  <br>
  Experiencia Thermoball en Acción<br>
  <br>
  Beneficios<br>
  <br>
  -Aislamiento térmico.<br>
  -Liviano<br>
  -Calor fenomenal en condiciones frías y húmedas.<br>
  -Igual de compresible que la pluma.<br>
  -Aisla incluso cuando esta húmedo.<br>
  <br>
  Encontrá tu modelo Thermoball preferido en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">¿Qué me pongo?</div>
  <div class="copete">

  Cuando practicamos actividades al aire libre que implican cierta demanda aeróbica, como hiking o trekking de media o alta dificultad en senderos de montaña, nuestra indumentaria debe cumplir al menos tres funciones básicas...
  </div>
  <div class="descripcion"><br>
  1. Ser respirable, porque cuando hacemos actividad física producimos vapor corporal, y la ropa debe poder evacuarlo <br>
  (alejarlo de la piel) permaneciendo seca, para que no perdamos calor y nos sintamos cómodos.<br>
  <br>
  2. Ser impermeable, porque aún en verano podemos acceder a áreas con nieve, o porque al aire libre siempre es posible tener que sobrellevar lluvias o lloviznas imprevistas.<br>
  <br>
  3. Cortar el ingreso del viento, ya que éste es un factor clave en la sensación de incomodidad causada por la baja <br>
  temperatura. El viento disminuye la sensación térmica considerablemente cuando estamos al aire libre.<br>
  <br>
  Calzado y medias:<br>
  Los pies sudan, y mucho. Si se combinan unos pies sudorosos con agua de fuentes externas (lluvia, charcos), se logra la receta perfecta para producir ampollas al final de cualquier actividad. Por lo tanto, el calzado tiene que ser tanto respirable como resistente al agua, permitiendo que la transpiración salga al exterior sin dejar que la humedad se filtre. Las medias también deben escogerse con cuidado: tienen que ser absorbentes y secarse rápido, para mantener los pies secos. El algodón no es una buena opción porque atrapa la humedad en sus fibras y tarda mucho en secarse. Cuando hace calor, hay que llevar siempre medias finas de poliéster.<br>
  <br>
  Para un mejor asesoramiento podés acercarte a cualquier sucursal Suiza Outdoor de la Ciudad de Córdoba o si estás en el interior del país a través del Chat-Online de nuestra Tienda Virtual www.suizaoutdoor.com.ar</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">¿Por qué usar gore-Tex?</div>
  <div class="copete">
  Hoy te ayudamos a entender porqué las mejores marcas del sector trabajan con Gore-Tex. Y cómo se ha creado el éxito de esta mítica membrana.</div>
  <div class="descripcion"><br>
  <br>
  <br>
  A veces los mejores avances tecnológicos llegan por pura casualidad. Fue en 1978 cuando el creador de la empresa descubrió el ePTFE, un polímero con cualidades excepcionales. Primero, se usó en medicina para implantes quirúrgicos, ya que el cuerpo no rechaza la membrana. Luego fue la aeroespacial que se interesó por el ePTFE para la confección de los trajes espaciales. Pero, casualmente, fueron sus cualidades impermeables y transpirantes que hicieron de esta membrana un elemento indispensable en la industria del textil.<br>
  <br>
  El Gore-tex es una membrana de politetrafluoretileno expandido de apenas unos milímetros que se inserta entre otras dos capas de tejido. Pero sus cualidades son muy útiles para los deportistas que quieren prendas de altas prestaciones. Sus principales cualidades son una impermeabilidad duradera, una gran transpirabilidad, una extraordinaria resistencia al frío, resistencia a las arrugas y gran durabilidad.<br>
  <br>
  La empresa Gore-Tex ha entendido la importancia de que el cuerpo expulse el calor, al mismo tiempo que tiene que estar protegido de las inclemencias exteriores. Por eso, Gore-Tex colabora con las diferentes empresas para garantizar un producto de altas prestaciones que pasa por un riguroso examen de calidad. Ninguna prenda puede salir al mercado sin tener el certificado de homologación de Gore-Tex.<br>
  <br>
  Para simplificar, diremos que Gore-Tex maltrata las prendas hasta al extremo, en sus laboratorios. Primero controla la calidad de la confección con unos tests específicos de presión, impermeabilidad, repelencia al agua e infinitos lavados a maquina. Luego, comprueban en una torre de agua y un túnel de viento la efectividad de los termosellados y de cualquier especificación de la confección. Por último, prueban el tejido en condiciones reales gracias a un team de reconocidos atletas mundial.<br>
  <br>
  Para un mayor asesoramiento, te recomendamos visitar nuestras sucursales en la Ciudad de Córdoba o a través de nuestro Chat-Online de Suiza Outdoor.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">La Historia de ... Buck Knives</div>
  <div class="copete">Generalmente cuando mencionamos una marca importante a nivel mundial sabemos que es grande, pero no sabemos porque... Desde el Blog de Suiza Outdoor te traemos la historia de BUCK, una de las cuchillerías mas importantes del mundo.</div>
  <div class="descripcion"><br>
  La historia de Buck empieza en 1902 cuando un joven herrero de Kansas fue en busca de una mejor manera de templar el acero para que <br>
  la hoja del cuchillo dure más. Su primer cuchillo fue creado en 1902. El joven herrero llamado Hoyt Buck hizo cada cuchillo a mano. <br>
  Su trabajo fue muy apreciado durante la Segunda Guerra Mundial. Después de la guerra, en 1947, se mudo a San Diego y abrió sus <br>
  propia empresa HH Buck & Son. El hijo de Hoyt, Al, revolucionó la industria de la cuchillería con el modelo plegable Hunter 110, en <br>
  el año 1964. Incluso hoy en día los productos Buck sorprenden y por eso siguen entre los mejores fabricantes de cuchillos.<br>
  <br>
  ¿Por qué comprar un cuchillo Buck? <br>
  Los cuchillos  Buck Knives son unos de los mejores porque detrás de cada cuchillo y cada navaja <br>
  hay mas de 100 años de experiencia y artesanía. Para el proceso de fabricación se emplean materiales nobles de primera calidad y lo <br>
  último en tecnología y así conseguir un cuchillo que cumpla con las exigencias de calidad que los clientes esperan de un cuchillo. <br>
  Estos cuchillos o navajas, si son utilizados correctamente, pueden durar toda la vida. Cada cuchillo que Buck lanza al mercado es <br>
  cuidadosamente fabricado y diseñado.<br>
  Conseguí tu cuchillo Buck en nustras sucursales  en Córdoba Capital o desde nuestra Tienda Virtual.<br>
  <br>
  Sugerencias...<br>
  <br>
  https://www.suizaoutdoor.com.ar/Producto/4/CUCHILLERIA/40104/Cuchillo-Buck-Woodsman-M0102BRS-B-102-cm<br>
  <br>
  https://www.suizaoutdoor.com.ar/Producto/4/CUCHILLERIA/51920/Cuchillo-Buck-PATHFINDER-mod-0105BKS-B-127-cm</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo"> Tips para elegir calzado de montaña</div>
  <div class="copete">
  A veces entramos en un negocio para comprar zapatillas y nos dejamos llevar por los colores y diseños cuando debemos prestarle mayor atención a sus tecnicismos. Desde Suiza Outdoor te ayudamos seleccionar tus próximas zapatillas de montaña!</div>
  <div class="descripcion"><br>
  TALLA<br>
  <br>
  Es fundamental acertar con tu talla. Normalmente todas las marcas se rigen por unas tallas universales, especificadas en la <br>
  zapatilla, pero la horma o construcción de cada modelo puede hacer que unas se ajusten más o menos a tu pie siendo la misma talla. <br>
  Especialmente en la puntera y el ajuste del talón.<br>
  Suele ser recomendable probarse el modelo a última hora del día cuando los pies están calientes, probarse diferentes tallas y andar <br>
  un rato con ellas antes de descantarte por tu talla correcta en ese modelo concreto. <br>
  Es muy aconsejable no llevarlas ajustadas en la puntera, ya que la punta de tus dedos sufrirá especial presión en las bajadas <br>
  fuertes y largas. Ante la duda cógete media talla más grande siempre y cuando la horma envuelva bien tu pie.<br>
  <br>
  COMPETICIÓN O ENTRENAMIENTO?<br>
  <br>
  Al igual que en todas las disciplinas atléticas conviene usar un modelo u otro dependiendo de la distancia o si vas a entrenar o <br>
  competir.<br>
  Durante los entrenamientos conviene usar un modelo con buena amortiguación que es proporcional al mayor peso del modelo. <br>
  Para competir o hacer entrenamientos intensos puedes apostar por un modelo más ligero y menos amortiguado que te permita tener una <br>
  rápida respuesta y sensación con el terreno.<br>
  <br>
  DISTANCIA<br>
  <br>
  Éste factor también hay que tenerlo en cuenta ya que nuestros pies se verán recompensados si acertamos con el modelo para cada ocasión.<br>
  En entrenamientos o competiciones de larga distancia (ultra trail) busca un modelo cómodo y bien amortiguado.<br>
  En distancias más cortas (hiking), dependiendo de tu intención busca una zapatilla o bota más ágil y con mayor respuesta.<br>
  Para distancias muy cortas y en ascenso (kilómetro vertical) te vendrá bien un modelo muy ligero, mínimamente protegido y con un <br>
  taqueado agresivo que te permita ascender lo más rápido posible.<br>
  <br>
  SUPERFICIE<br>
  <br>
  Si corres por pistas o senderos con poca dificultad hay modelos mixtos que se pueden ajustar a esa necesidad.<br>
  En terrenos de montaña habrá que valorar las características del terreno:<br>
  Cuando está muy embarrado, especialmente en épocas lluviosas, un taqueado agresivo te ayudará a traccionar de manera adecuada.<br>
  Sobre terrenos muy técnicos el perfecto ajuste y perfil bajo del modelo te dará la seguridad necesaria para correr con agilidad.<br>
  <br>
  SUELA<br>
  <br>
  La disposición del taqueado, su material y la altura del mismo harán un modelo más adecuado para unos u otros terrenos.<br>
  Un gran espacio entre tacos ayuda a no acumular excesivo barro en zonas de mucho barro<br>
  Un taco alto ayuda a la tracción pero habrá que tener cuidado en zonas de piedra lisa porque la superficie de contacto es menor y su adherencia se verá reducida<br>
  Un taco bajo y variado en la suela será lo más recomendable en terrenos mixtos.<br>
  <br>
  ESTABILIDAD/AMORTIGUACIÓN<br>
  <br>
  El equilibrio entre ambos factores marcará el éxito de un modelo y de tu elección. <br>
  Un modelo muy amortiguado y de perfil alto será algo más inestable y la sensación con el terreno será menor. Por el contrario, tus <br>
  pies estarán bien protegidos de los objetos del terreno.<br>
  Aquel más bajo y con un espesor menor mantendrá tus pies cerca del terreno dándote una mayor sensación y estabilidad, pero notarás <br>
  más las piedrecillas o ramas del camino.<br>
  <br>
  Para mayor asesoramiento encontranos en nuestras sucursales en Córdoba Capital, redes sociales o a través de nuestro Chat-online de <br>
  <br>
  suizaoutdoor.com.ar</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Rompeviento Vs Impermeable</div>
  <div class="copete">
  A veces buscando abrigo para lluvia nos preguntamos...¿Es lo mismo rompeviento que impermeable? ¡Desde Suiza Outdoor te despejamos las dudas!</div>
  <div class="descripcion"><br>
  <br>
  La característica principal de los impermeables y rompevientos es que, a diferencia de otros abrigos livianos es que permiten <br>
  transpirabilidad; esto quiere decir que mantienen el cuerpo seco, no sólo porque evitan que entre el agua de lluvia, sino porque <br>
  además expulsa el sudor y la condensación generada por el calor corporal.<br>
  Si bien el rompeviento protege del frío, su transpirabilidad es mucho mayor que su impermeabilidad, y aunque el clima sea frío, se <br>
  secan rápidamente con el calor del cuerpo. Sin embargo, no se recomienda usarlo con una lluvia constante porque esto disminuye su <br>
  capacidad.  <br>
  Los impermeables poseen las cualidades inversas, evita que te mojes por factores externos, es decir, son más impermeables que <br>
  transpirables.<br>
  <br>
  La diferencia principal entre rompeviento e impermeable es que éste último tendrá costuras termoselladas.¿Qué quiere decir? <br>
  significa que más allá de la costura normal de aguja e hilo, las capas estarán unidas por calor, lo que previene el ingreso de agua<br>
  por entre las costuras.<br>
  <br>
  Para un mejor asesoramiento, te sugerimos acercarte a cualquiera de nuestras sucursales en Córdoba Capital o a través de el Chat-<br>
  OnLine de Suiza Outdoor Since 1888.<br>
  <br>
  <br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Que buscar en una linterna frontal</div>
  <div class="copete">
  Las linternas frontales son un tipo de linternas que se colocan en la cabeza, las cuales nos ofrecen ventajas muy interesantes para ciertos trabajos o situaciones, hecho que las está convirtiendo en un tipo de linternas muy vendidas en el mercado.</div>
  <div class="descripcion"><br>
  <br>
  <br>
  Ventajas que nos ofrece una linterna frontal<br>
  Nos ofrece la iluminación necesaria dejándonos las manos totalmente libres para la realización de cualquier actividad.<br>
  Ilumina siempre donde se dirige la cabeza, teniendo así el foco de iluminación siempre en la misma dirección que nuestros ojos.<br>
  Al incidir la iluminación en el mismo ángulo que la vista, no vamos a tener sombras o puntos muertos.<br>
  Gracias a los nuevos sistemas de linternas frontales LED, cada vez existen linternas frontales más potentes, ofreciéndonos una gran intensidad de luz.<br>
  Gracias a todo eso, actualmente las linternas de cabeza son muy utilizadas en una gran cantidad de actividades y sectores como industria, camping, senderismo, ciclismo, caza, pesca, entre muchos otros.<br>
  <br>
  Rendimiento lumínico o intensidad de la luz<br>
  También se le puede llamar potencia de iluminación, y es la cantidad de luz emitida en todas las direcciones.<br>
  Esta se mide en lúmenes y es una de las primeras cosas que nos fijamos cuando compramos una linterna frontal; ya que a mayor número de lúmenes, mayor será la luz que hará nuestra linterna frontal.<br>
  <br>
  Alcance<br>
  Según el uso que le vayamos a dar a nuestra linterna frontal deberemos elegir un dispositivo que nos permita iluminar la distancia necesaria, con el fin de poder satisfacer nuestras necesidades.<br>
  <br>
  Autonomía<br>
  La autonomía que tendrá nuestra linterna frontal es una de las variables más importantes a tener en cuenta. Es vital plantearnos en que situaciones la utilizaremos y saber durante cuánto tiempo necesitaremos de su luz.<br>
  <br>
  Peso <br>
  La comodidad de nuestra linterna frontal es una de las variables más importantes a tener en cuenta, ya que por mucha luz que haga o por mucho que dure la batería, si nuestro frontal no es cómodo no podremos realizar la actividad o trabajo a gusto, y al final terminaremos quitándonos la linterna de la cabeza.<br>
  <br>
  Éstos son algunos de los detalles a la hora de comprar una linterna frontal, por un mayor asesoramiento podés acercarte a cualquiera de nuestras 5 sucursales en Córdoba Capital o desde la comodidad de tu casa en suizaoutdoor.com.ar</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Cuidate del sol de la mano de Columbia</div>
  <div class="copete">
  Columbia, la primera compañía a nivel mundial en recibir el sello de recomendación que otorga la Fundación de Cáncer de Piel a la ropa que posee una protección solar efectiva y segura.</div>
  <div class="descripcion"><br>
  La tecnología Omni Shade impide que los peligrosos rayos UVA y UVB lleguen hasta la piel, permitiendo pasar más <br>
  horas bajo el sol.<br>
  Impermeables, ropa deportiva, pantalones y hasta gorros son algunas de las prendas con factor solar <br>
  que la línea Omni Shade de la marca Columbia Sportswear tiene para su colección primavera-verano.<br>
  <br>
  En un alianza con la Fundación del Cáncer, la firma desarrolló esta última tecnología que permite proteger la <br>
  piel de los rayos ultravioleta A y B (UVA y UVB), contribuyendo a prevenir quemaduras solares, el envejecimiento <br>
  prematuro y sobre todo el cáncer de piel.<br>
  <br>
  Esta última innovación permite pasar más tiempo disfrutando de las actividades al aire libre sin peligro a la exposición<br>
  solar. Todo gracias a un tejido rico en elementos reflectantes de los rayos solares y a su tecnología de absorción <br>
  ultravioleta.<br>
  <br>
  La Línea de ropa incluye productos tanto para el hombre como para la mujer y los niños, además posee una etiqueta que<br>
  indica el factor de protección a la radiación ultravioleta (UPF). Los niveles <br>
  van en una escala de UPF 15 a UPF 50. Mientras más alto es el UPF, mayor es la protección contra los rayos ultravioletas.<br>
  <br>
  El estándar del Sello de Recomendación para ropa certifica que un factor UPF 30 ofrece una “protección muy buena”, <br>
  mientras que un factor UPF 50 proporciona una “protección excelente”.<br>
  <br>
  Encontrá tu prenda con protección solar, en cualquiera de nustras sucursales Suiza Outdoor de la Ciudad de Córdoba o a través de nuestra Tienda Virtual, desde la comodidad de tu casa.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Como organizar tu mochila</div>
  <div class="copete">
  Se viene la primavera y con ella los mejores días para ir de campamento, por eso desde Suiza Outdoor te damos unos Tips a la hora de armar la mochila!</div>
  <div class="descripcion"><br>
  1) Prepara la lista de elementos y equipamiento que vas a llevar. <br>
  <br>
  2) Tu mochila debe ser liviana. La mochila de trekking con carga completa, no debería pesar <br>
  mas que el 20% de tu peso corporal.<br>
  <br>
  3) Clasifica en categorías tu equipamiento de camping: carpa, bolsa de dormir, cocina, <br>
  comida, ropa de abrigo, ropa de uso diario, limpieza y perfumería, varios (cámara de foto, <br>
  etc.), documentación, pasajes y reservas. Usa bolsas plásticas para organizarlas.<br>
  <br>
  4) Como distribuir la carga en tu mochila:<br>
  En el fondo coloca la bolsa de dormir y parte de ropa (elementos livianos)..<br>
  Cerca de la espalda deben ir los elementos pesados. Normalmente es la comida, elementos de <br>
  cocina (marmita, calentador) y la carpa o parte de la carpa que nos corresponda llevar<br>
  En los huecos libres y en la parte superior agregamos la ropa, botiquín y elementos de uso <br>
  diario y fácil alcance.<br>
  <br>
  5)Los elementos frágiles, delicados y pequeños como cámara de fotos, celular, altímetro, <br>
  lentes de sol, cortaplumas, linterna, etc., deben ir en el bolsillo superior o bolsillos <br>
  laterales, para un fácil y rápido acceso.<br>
  <br>
  6) Documentación de viaje: documento de identidad, pasaporte, licencia de conducir, mapas, <br>
  hojas de ruta, direcciones y teléfonos útiles, pasajes y reservas son principalmente la <br>
  documentación a llevar. Es recomendable guardarlo es en bolsas tipo ziploc para protegerlas.<br>
  <br>
  7)Verificamos la comodidad y peso de la mochila: Con la mochila cargada, nos la montamos y <br>
  verificamos el peso y comodidad. Debe sentirse cómoda y liviana. Si la notamos pesada, <br>
  seguramente estamos llevando elementos de más que debemos quitar.<br>
  <br>
  8) Organizar todo dentro de la mochila para no dejar cosas colgando fuera de ella. Todo <br>
  elemento que nos quede colgando de la mochila entorpecerá nuestro trekking.<br>
  <br>
  10)Deja lugar en tu mochila, para guardar la ropa que llevas puesta y recuerdos o regalos <br>
  que puedas comprar en tu viaje.. Notaras que a medida que pasan los días, tu mochila <br>
  comienza a desordenarse y van a entrar menos cosas.<br>
  <br>
  <br>
  Visitá nuestras sucursales Suiza Outdoor del Centro de Córdoba o si sos del interior a través de nuestra Tienda Virtual www.suizaoutdoor.com.ar</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">La historia de ...The North Face</div>
  <div class="copete">
  Si nombran The North Face, ya todos sabemos que hablamos de indumentaria y equipamiento de alto rendimiento, pero ¿conocés sus comienzos?</div>
  <div class="descripcion"><br>
  <br>
  A través de la década de 1960, la marca The North Face® se volvió ampliamente apreciada y reconocida por los deportistas que practicaban actividades al aire libre y posteriormente comenzó a patrocinar expediciones a algunos de los rincones más recónditos del planeta, los cuales nunca habían sido explorados. Esto ayudo a impulsar una fuerte tradición, la cual continua fuertemente en la actualidad y está reforzando constantemente el mantra de The North Face®, por medio de su slogan, Never Stop Exploring™.<br>
  <br>
  The North Face® lleva su nombre inspirado en uno de las partes más frías e implacables del monte Half Dome, ubicado en el parque natural de Yosemite en el condado de Mariposa en California, Estados Unidos. Hemos apoyado a los mejores exploradores del mundo a alcanzar las alturas más insondables de la cordillera del Himalaya. Pero la leyenda de The North Face® comienza irónicamente a la orilla del mar, más precisamente en una playa ubicada en un vecindario al norte de la bahía de San Francisco, con apenas 45.7 metros de altura sobre el nivel del mar. Fue en este lugar en 1966 cuando dos entusiastas de las excursiones al aire libre (Douglas Tompkins y Kenneth "Hap" Klopp), tomaron la decisión de seguir con su pasión y posteriormente tomaron la decisión de fundar una pequeña tienda de artículos al detal para montañismo.<br>
  <br>
  Poco tiempo después, esta pequeña tienda llego a ser conocida como The North Face®, un minorista dedicado a la venta de equipamiento de alto desempeño para excursiones y alpinismo en climas hostiles. En 1968, The North Face® se trasladó al otro lado de la bahía de San Francisco, a explotar las posibilidades sin límites del área de Berkeley, y a comenzar a diseñar y fabricar su propia marca de ropa y equipo técnico expedicionario y altamente especializado.<br>
  <br>
  A principios de 1980, The North Face estaba realizando exploración en los límites exteriores del mundo del esquí, lo que contribuyó a complementar su portafolio de productos con ropa para esquí extremo.  Al finalizar la década, The North Face® se convirtió en el único proveedor en los estados unidos en ofrecer una colección de ropa comprimible de alto desempeño especial para exteriores, al igual que ropa para esquiar, bolsas de dormir, mochilas y carpas.<br>
  <br>
  La década de 1990 marcó el comienzo de una era en la que The North Face® amplió aún más su apoyo y participación hacia los atletas y la exploración. El logo Half Dome de The North Face® empezó a hacer sus apariciones con mayor regularidad en eventos de ultra maratón, carreras a campo abierto y grandes paredes de escalada.<br>
  <br>
  <br>
  <br>
  En Argentina la marca llega en el año 2008, y es referente indiscutido en disciplinas como trail running (a través de su circuito The North Face Endurance Challenge  y por el que han pasado más de 10.000 atletas en sus clásicas distancias de 10, 21, 50 y 80km de montaña); el trekking, la escalada , el montañismo (acompañando a los mejores pro, o a amateurs que sin duda se destacan), y las disciplinas invernales como el ski y el snowboard.<br>
  <br>
  Ahora, a 50 años de su humilde inauguración, The North Face® ofrece una extensa línea de ropa de alto rendimiento, además de equipamiento y calzado especializado. Nos empuja la pasión por la innovación, para trasmitir a las personas un deseo ferviente de sobrepasar los límites de la exploración. Seguimos profundamente orgullosos de ser la primera opción de los más consagrados escaladores, montañistas, esquiadores extremos, snowboarders, corredores de resistencia y exploradores del mundo.<br>
  <br>
  Aún no sabemos lo que la marca  deparara para sus años futuros. Sin embargo hay una certeza fundamental: The North Face® mantendrá un firme compromiso con la superación de los límites del diseño, para que la gente pueda empujar sus límites en los ambientes exteriores, a través de la exploración.<br>
  <br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">¿Querés empezar en Trail Running?</div>
  <div class="copete">
  No es lo mismo ejercitarse en el asfalto que salir a explorar nuevos terrenos al aire libre. Para que no tengas problemas, echa un vistazo a estas recomendaciones de Suiza Outdoor.</div>
  <div class="descripcion"><br>
  <br>
  Calma<br>
  Empezar a correr implica armarse de paciencia, sea el terreno que sea. Al igual que existen errores a evitar en el 'running' cuando se es novato, también hay comportamientos que deben dejarse a un lado cuando se practica el 'trail running'. Uno de ellos es querer ir a tope el primer día que se pisa la montaña.<br>
  <br>
  Por muchos kilómetros que se lleven acumulados en las piernas por asfalto, al iniciarse en 'trail running' hay que ser precavidos porque nuestro cuerpo debe acostumbrarse a los nuevos estímulos. Notaremos un aumento de la fatiga, pese a no estar ni cerca de los ritmos que podemos llevar en asfalto.<br>
  <br>
  Olvida los ritmos<br>
  El tema de los ritmos es precisamente otro de los consejos para iniciarse en el 'trail running', principalmente porque debemos olvidarnos de ellos. Parece una obviedad, pero la montaña no es la carretera. Nos encontraremos con desniveles constantes, piedras, tierra, terrenos más técnicos, ramas y raíces, cambios de luz…<br>
  Todos esos factores, sumados a la necesidad de adaptación del propio cuerpo, nos harán prácticamente imposible correr a los mismos ritmos que en asfalto. De nuevo, calma y adaptación.<br>
  <br>
  <br>
  Precaución y seguridad<br>
  En la ciudad debemos tener cuidado con el tráfico y ser bien visibles, principalmente si entrenamos o muy temprano o ya entrada la noche. Si nos encontramos mal, nos lesionamos o nos ocurre algo, lo normal es que estemos rodeados de civilización, pero ¿y si nos ocurre en la montaña?<br>
  <br>
  El 'trail running' ofrece una libertad total para correr, pero no conocer los caminos ni el entorno multiplica las posibilidades de sufrir un accidente, una caída o una lesión. Es por eso que hay que cuidar todavía más los elementos de seguridad, sobre todo si salimos en solitario a correr.<br>
  <br>
  Llevar siempre identificación, elementos reflectantes o teléfono móvil para estar localizables ante una emergencia es básico. Por supuesto, una acción que no falla es el simple hecho de avisar a nuestros familiares sobre qué zona vamos a explorar o cuánto tiempo aproximado va a durar nuestra actividad.<br>
  <br>
  Por suerte, hoy en día la mayoría llevamos 'smartphones' y existen multitud de aplicaciones que nos permiten emitir en directo el entrenamiento, así como herramientas de emergencia para compartir la ubicación, como Alpify.<br>
  <br>
  Equipamiento<br>
  En el apartado de equipamiento, hay que hacer una parada obligatoria en las zapatillas de 'trail running'. Sí es muy recomendable tener unas zapatillas para correr por la montaña, ya que las de asfalto no están preparadas para esos terrenos y, además de deteriorarse con mayor rapidez, tampoco nos darán la comodidad, sujeción del tobillo, la tracción y el agarre necesarios en determinados terrenos.<br>
  <br>
  Para más asesoramiento podrás acercarte a cualquiera de nuestras 5 Sucursales Suiza Outdoor de la Ciudad de Córdoba oa a través de nuestro Chat Online.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">¿Como elegir una bolsa de dormir?</div>
  <div class="copete">
  Arrancó la primavera y con ella las ganas de salir a acampar.La duda... ¿como elijo mi bolsa de dormir?
  </div>
  <div class="descripcion"><br>
  Una bolsa de dormir te permitirá dormir cómodamente después de un día de excursión. Debido a que las bolsas de dormir para mochilero están diseñadas para llevarlas sobre la espalda, por lo general son de pesos ligeros y compresibles. Además del peso de la bolsa de dormir, los mochileros deben considerar el aislamiento térmico, el grado de temperatura, la forma, tamaño de la bolsa y características especiales. Utiliza estos consejos para elegir una bolsa de dormir para mochilero<br>
  <br>
  Selecciona una bolsa de dormir para mochilero que esté graduada a las temperaturas que has previsto durante tu viaje. <br>
  <br>
  Existen 4 categorías generales de la norma europea de rangos de temperatura para bolsas de dormir. Los rangos se calculan en base a los usuarios que utilizan 1 capa de ropa con un gorro y utilizan una almohadilla de aislamiento térmico debajo de la bolsa de dormir.<br>
  -Elige una bolsa de dormir para épocas de verano a temperaturas mayores de 1,6 °C .<br>
  -Selecciona una bolsa de dormir 3 estaciones para temperaturas que estén entre los -12 y 1,6 °C <br>
  -Lleva una bolsa de dormir para climas fríos con temperaturas menores de -23 y -12 °C <br>
  -Elige una bolsa de dormir para invierno extremo con temperaturas menores de -23.3 °C <br>
  <br>
  <br>
  Existen muchos Tipos de bolsa de dormir, variando según el modelo, pero vamos a hablar de los más generales que nos encontraremos en el mercado:<br>
  <br>
  Saco rectangular: Con cremallera que se abre totalmente y se desenganchan por abajo, quedando como una colcha. Sus ventajas son la amplitud y el poder unirse a otro saco. Suelen usarse en verano.<br>
  <br>
  Saco de momia: Tienen forma de sarcófago (más ancho por los hombros) una vez cerrados. Poseen cremallera grande y lateral.<br>
  <br>
  Saco-sábana: Saco de tela de sábana usado como higiene para no contactar con el saco (si no es tuyo) o con las mantas y colchones de los refugios ( no suelen lavarse mucho) . Se lava fácilmente en la lavadora.<br>
  <br>
  Doble saco: Usado en alta montaña, siendo 2 sacos de pluma cosidos uno encima del otro y con costuras contrapuestas ( aísla más aún).<br>
  <br>
  Funda de vivac: Usada para dormir al raso y funciona como la tercera capa impermeable de la vestimenta de montaña. Sirve para cortar el viento y proteger de la lluvia. No transpira, por lo que se suda más en el saco. <br>
  <br>
  <br>
  Para un mayor asesoramiento, te recomendamos visitar nuestras sucursales en la Ciudad de Córdoba o a través de nuestro Chat-Online de Suiza Outdoor.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo"> ¿Qué medias utilizo?</div>
  <div class="copete">
  A la hora de hacer actividad física, es fundamental contar con la indumentaria adecuada. En materia de medias, las constantes innovaciones tecnológicas aseguran un máximo confort y rendimiento.</div>
  <div class="descripcion"><br>
  <br>
  Cada día más personas se suman a la tendencia saludable de correr y realizar actividad física, pero a la hora de hacerlo es fundamental contar con la indumentaria adecuada de acuerdo al tipo de deporte que se vaya a practicar.<br>
  <br>
  Espesores y tamaños no son tan determinantes como el tipo de material con el que está fabricada la media. No hay que usar las que son 100% algodón, pues una vez que éste se moja queda húmedo. Los pies transpiran y quedan mojados hasta que termine el circuito. Lo mismo sucede si por casualidad pisás un charco, seguirás corriendo con humedad en tus pies.<br>
  Las mejores medias para correr son las que son producidas con materiales sintéticos (poliéster, acrílicos y etc) porque absorben la humedad y mantienen estable la humedad de los pies.<br>
  <br>
  <br>
  Para más asesoramiento podrás acercarte a cualquiera de nuestras 5 Sucursales Suiza Outdoor de la Ciudad de Córdoba o a través de nuestro Chat Online.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">¡¡ Te contamos todo acerca de The North Face Endurance Challenge !! </div>
  <div class="copete">
  The North Face Endurance Challenge se ha consagrado como la carrera más desafiante del Trail Running de Sudamérica.
  </div>
  <div class="descripcion"><br>
  <br>
  <br>
  La edición 2018 de The North Face Endurance Challenge está prevista para el domingo 15 de abril en La Cumbrecita, Córdoba y espera a la multitud de trail runners que poblaron su geografía en 2017.<br>
  <br>
  La Cumbrecita, enclavada en las sierras cordobesas, demostró ser un spot súper exigente para los amantes del trail por su desnivel técnico y por la dificultad de sus terrenos. A lo que en la edición 2017 se sumó un clima difícil que puso un condimento extra al gran desafío Outdoor. The North Face Endurance Challenge Argentina 2018 es la octava edición del circuito que tendrá lugar en nuestro país.<br>
  ASí como en la edición anterior contó con la presencia de Diane Van Deren, ultra runner oficial del Team The North Face, la invitada especial de este año es Rory Bosio; 2 veces ganadora de una de las ultra maratones mas exigentes como Ultra-Trail du Mont-Blanc (UTMB) en los Alpes, a través de Francia, Italia y Suiza. Los cupos son limitados.<br>
  La carrera es non stop y son 4 las distancias que los corredores pueden elegir para testear sus límites: 10k, 21k, 50k y 80k. Comienza a entrenar para vivir unas de las experiencias más desafiantes de tu vida.<br>
  <br>
  Para mas información, acercate a cualquiera de nuestras sucursales Suiza Outdoor de la Ciudad de Córdoba o a través de nuestro chat on-line.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Como elegir bolsas de dormir #2</div>
  <div class="copete">¿Estás pensando en una bolsa de dormir? Desde Suiza Outdoor te contamos que debes tener en cuenta a la hora de elegirla.</div>
  <div class="descripcion">A la hora de elegir una bolsa de dormir debemos en primer lugar valorar qué usos vamos a darle. No es lo mismo ir de camping que hacer senderismo de alta montaña; como tampoco lo es pernoctar a la intemperie en cualquier estación del año. <br>
  Lo que se recomienda es tener  dos bolsas de dormir: una para las épocas calurosas (con temperatura de confort de hasta 0º) y otra para situaciones de frío intenso (con temperatura de confort de hasta -20º).<br>
  <br>
  Además hay características que debemos tener en cuenta para adquirir nuestra bolsa de dormir<br>
  <br>
  1. El peso. Fundamental para el senderismo de largo recorrido en autosuficiencia. Una diferencia de 300 gramos puede no ser muy relevante al iniciar la marcha, pero cuando arrastras varias jornadas es una cuestión vital.<br>
  <br>
  2. El volumen. Es importante que no sea ni muy estrecho ni muy ancho, y que tenga una alta capacidad de plegado para que no ocupe demasiado espacio en la mochila.<br>
  <br>
  3. La capacidad térmica (espesor de la capa aislante, materiales y detalles técnicos como el cierre, la forma de la capucha, etc). El rasgo más importante a investigar a este respecto es la humedad.<br>
  <br>
  En cualquier caso, a nuestro entender, el factor más importante para lograr un óptimo grado de aislamiento es el relleno, y aquí tenemos dos opciones:<br>
  los sacos de pluma o los de fibra sintética. <br>
  Los primeros suelen tener una mezcla variable de plumón y pluma, ambos materiales naturales. La diferencia se da en que el plumón es la pluma más pegada al cuerpo del ave y, aunque tiene un tamaño más pequeño y es más fina, su capacidad de aislamiento es mucho mayor aun siendo más ligero. Es precisamente esas cualidades las que trata de imitar la fibra sintética en el relleno de los sacos, generalmente con poliéster.<br>
  <br>
  Muchos fabricantes, en sus artículos, suelen abogar por los sacos de fibra sintética, en detrimento de los de pluma y plumón. Esto se debe, en gran medida, a que se intenta poner en valor la propia fibra sintética que ellos trabajan exclusivamente para sus productos. Pero, en nuestra experiencia, el plumón es mejor aislante y menos pesado. Por lo que recomendamos en este sentido las fibras naturales.<br>
  En síntesis las bolsas de pluma serán mas ligeras, proporcionan mayor abrigo en menor volúmen. La generalidad de este este tipo de bolsas de dormir es que suelen ser mas costosas que bolsas con relleno sintético.<br>
  Las bolsas de dormir con relleno sintético tienden a ser de mayor tamaño en comparación con una de pluma en el mismo rango de temperatura. Punto positivo sería su tiempo de secado mucho mas rápido (que una de pluma) en caso que nuestra bolsa de dormir se humedezca. Su menor coste las hace preferidas  para acampes en interior o épocas de media estación.<br>
  <br>
  Para mayor asesoramiento podrás acercarte a tu sucursal Suiza Outdoor Since 1888® mas cercana, a través de nuestras redes sociales o nuestro Chat On-Line.<br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo"> Trail Running.¿Qué zapas uso?</div>
  <div class="copete">
  El trail running puede ser muy diverso en cuanto a los terrenos en los que se puede disputar una prueba, por eso desde Suiza Outdoor tratamos de ayudarte a la hora de elegir un calzado.</div>
  <div class="descripcion"><br>
  <br>
  Tenemos que saber para qué vamos a utilizar la zapatilla, para entrenar, para competir o para ambas cosas. Dependiendo de nuestros ritmos, nuestros objetivos y nuestro dinero quizá optemos por un par de zapatillas para entrenar y otras para la competición y entrenamientos de calidad, en cualquiera de los casos tendremos que conocer los terrenos por los que esa zapatilla se va a mover e intentar escoger un modelo capaz de responder con garantías en esas situaciones.<br>
  <br>
  Terrenos con barro<br>
  Por lo general estas pruebas son trails de tierra y hierba, que por condiciones climáticas se convierten en barrizales. En estos terrenos lo que prima son zapatillas con taqueado muy marcado en la suela, con tacos largos y separados entre sí, poco a poco se está creando o asentando una categoría conocida como Soft Trail o Soft Ground, en la que se encuadrarían todas las zapatillas aptas para terrenos blandos como hierba o barro.<br>
  <br>
  Este tipo de zapatillas de trail evita que la suela se llene de barro, ya que la separación entre tacos y la vibración de estos evita que el barro se quede atorado en la suela. Muchos corredores suelen elegir zapatillas impermeables para este tipo de carreras, sobre todo cuanto más barro haya y menor distancia tenga la prueba mejor funcionará, especialmente en climas fríos.<br>
  <br>
  Pistas forestales y caminos<br>
  La mayoría de los trails o la mayoría de los kilómetros de los trails se disputan en caminos, pistas forestales, senderos o zonas abiertas, es por ello que la mayoría de corredores suele utilizar modelos de este tipo, de ahí que sean los más vendidos y conocidos.<br>
  <br>
  Este tipo de zapatillas no solo son útiles en pistas, bosque y sendero, sino que en muchos casos también se defienden en terrenos más complicados, con algo de barro o algo de roca; en el caso que ponía antes de un trail 80%-20% o incluso en un trail donde el 70% sean pistas y el resto sea roca o barro no muy complicado podríamos usar un modelo de este grupo.<br>
  <br>
  Dentro de este conjunto tenemos las zapatillas polivalentes, que cuentan con bastantes tacos marcados, pero cuya suela y mediasuela no son tan rígidos como los modelos de roca o tan faltas de amortiguación como los modelos de barro, las zapatillas polivalentes son amortiguadas, agradables y responden bien puntualmente al salirnos de la pista o del terreno firme.<br>
  <br>
  En el otro subsegmento nos encontraríamos las zapatillas más pisteras, son modelos similares a los anteriores pero que son más cómodas, agradables y ligeras, de hecho muchas de estas entrarían en el grupo de modelos para gente ligera. Son modelos con un taqueado menos agresivo y polivalente, los tacos son más blandos, pequeños y numerosos, son zapatillas que van muy bien por caminos, pistas, hierba seca e incluso algo de asfalto, son las llamadas Door 2 Trail o Mixtas.<br>
  <br>
  Terrenos con roca<br>
  Existen trails muy rocosos y generalmente con mucho desnivel, son trails muy técnicos y difíciles que requieren experiencia previa en el mundillo y buena técnica y conocimientos. El calzado adecuado para este tipo de terrenos suele tener una suela muy robusta, con caucho de mucha dureza, un taqueado fuerte y marcado para agarrarse a la roca sin que esta lo arranque, y suelen tener una placa antiroca en la mediasuela y por lo general poca amortiguación y mucho peso. Además el upper que envuelve el pie suele ser más grueso y resistente.<br>
  <br>
  No es sencillo elegir este tipo de calzado pues suele ser el menos agradable y el más exigente con el pie y la musculatura, por lo que mucha gente tiende a utilizar zapas polivalentes algo más firmes a pesar de que las someterán a mucho desgaste, los más expertos, gente con más técnica o gente acostumbrada a lidiar con este tipo de trails de continuo tienen en el mercado modelos agresivos aptos para correr trails muy rocosos.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">¿Cómo elegir binoculares?</div>
  <div class="copete">

  Ya se trate de deportes, sitios históricos, lugares de interés durante los viajes o de las maravillas de la naturaleza, los binoculares son la herramienta que más conviene a fin de encontrarse cada vez más cerca de las cosas que le cautivan.</div>
  <div class="descripcion"><br>
  <br>
  <br>
  Permiten ver más de cerca y con mayor detalle y así disfrutar más intensamente del mundo. Para asegurarse que el binocular elegido proporcione una excelente visualización, primero hay que familiarizarse con varios factores, y luego considerar las necesidades individuales.<br>
  Resulta inútil contar con un potente aumento si la imagen aparece opaca o borrosa. Un binocular más grande habitualmente proporciona un mayor aumento, pero para que su diseño resulte en una excelente imagen, se requieren lentes objetivos más grandes para realizar la definición o resolución y la luminosidad.<br>
  -En cuanto a la luminosidad, debe advertirse que existen límites a la cantidad de luz que el ojo puede percibir. Durante el día es necesario eliminar cierta cantidad de luz, mientras que durante la noche, se necesita más. En consecuencia, algunos binoculares con objetivo más grandes resultan idóneos para la capacidad de absorción lumínica nocturna del ojo, mientras que para observaciones diurnas resultan mejores los más pequeños.<br>
  -¿Qué significan los números? Se hace referencia a los binoculares mediante una combinación de números: ej. 7X35mm, 8X40mm ó 10X50mm. También notará que estos números van impresos en el binocular mismo. El primer número es la capacidad de aumento.<br>
  <br>
  Con un binocular de 7X35mm, el objeto se observa 7 veces mayor que el mismo objeto visto a simple vista. Los dos últimos números se refieren al diámetro, en milímetros, del lente objetivo (el lente situado a mayor distancia del ojo)<br>
  <br>
  La resolución varía directamente proporcional al tamaño de la lente objetivo. Todo lo demás permanece constante, una lente objetivo más grande siempre proporcionará imágenes en mayor detalle que una lente objetivo de menor tamaño, independientemente del aumento.<br>
  <br>
  El campo de visión es el ancho en metros del área que puede observar con cualquier par de binoculares a una distancia de 915 metros. Por lo general, a mayor poder de aumento, menor será el campo de visión. Por ejemplo, un binocular estándar de 7X35mm tiene un campo de visión de aproximadamente 104 metros a 915 metros de distancia, más un binocular estándar de 10X50mm proporciona un campo de visión de 83 metros a 915 metros de distancia.<br>
  <br>
  Para más asesoramiento podrás acercarte a cualquiera de nuestras 5 Sucursales Suiza Outdoor de la Ciudad de Córdoba o a través de nuestro Chat Online.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Razones para correr con guantes...</div>
  <div class="copete">
  Las manos son realmente sensibles al frío, debido a las terminaciones nerviosas y sensoriales con las que cuenta. Protegerlas de las bajas temperaturas mientras corremos tiene sus ventajas.</div>
  <div class="descripcion"><br>
  <br>
  Paulatinamente, los días se van acortando, y las noches se adueñan de la jornada. Y, como sabemos, el frío finalmente ganará la partida. Para los corredores, el frío en realidad no es un impedimento. De hecho, con bajas termperaturas, se rinde al máximo. Pero las manos, descubiertas, sí sufren el frío. Para paliar esta situación, el uso de guantes puede ser una buena alternativa. <br>
  <br>
  Los motivos:<br>
  <br>
  1- Terminaciones nerviosas: Las manos son una de las partes del cuerpo con gran cantidad de terminaciones nerviosas y sensoriales. Es por eso que las manos son realmente sensibles al frío, por lo que resulta importante que las protejas con guantes mientras corres en temperaturas frías.<br>
  <br>
  2- Baja musculatura: Los músculos de las manos, no tan famosos como los biceps o los glúteos, tienen un papel fundamental en el movimiento de los dedos. Pero al no tener el tamaño de otros grupos musculares, el flujo sanguíneo y la elevación de su temperatura es diferente. Mientras corrés las manos tardarán más que el resto del cuerpo en elevar su temperatura, y ello genera que durante los primeros minutos se sienta el frío en ellas.<br>
  <br>
  3- Bajas cantidades de grasas: Las manos son una de las zonas del cuerpo con menor acumulación de grasa corporal. La grasa corporal colabora con la termoregulación y protege al cuerpo del frío. Las zonas con más grasa acumulada es donde el frío se siente menos. Por lo tanto, las manos son más sensibles al frío.<br>
  <br>
  <br>
  Para un mejor asesoramiento, acercate a nuestras sucursales en Córdoba Capital, si estás en el interior del país, a través de nuestro Chat On-Line de Suiza Outdoor.<br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">Éste invierno, buscá tu abrigo con Omni-Heat Reflective</div>
  <div class="copete">

  La clave de esta innovadora tecnología radica en su propiedad reflectante, garantizando mantenerte cálido y confortable en condiciones de frío intenso.</div>
  <div class="descripcion"><br>
  <br>
  <br>
  OMNI-HEAT Conserva el calor en condiciones de frío. Esta tecnología  te ayuda a mantener el calor y a evitar su pérdida, proporcionando comodidad en los días fríos. Omni-Heat® es el mejor sistema de control del calor corporal en actividades al aire libre. Omni-Heat® te garantiza que seguirás sintiendo calor y comodidad en condiciones de frío. Los productos Omni-Heat® te ayudan a contrarrestar el frío en cualquier lugar.<br>
  <br>
  Genera tu propio calor<br>
  Esta tecnología térmica reflectante ayuda a regular la temperatura reflectando y conservando el calor que genera el cuerpo, a la vez que disipa la humedad y el exceso de calor para mantener la comodidad del usuario.<br>
  <br>
  * Aumenta la retención de calor hasta en un 20% aproximadamente<br>
  * El forro de puntos facilita la retención del calor, además de permitir un alto nivel de transpiración para reducir el exceso de calor y la humedad<br>
  * La composición metálica neutraliza la electricidad estática<br>
  * Altamente respirable<br>
  * Reduce la necesidad de aplicar capas gruesas<br>
  * Elimina la humedad y te mantiene cómodo y seco.<br>
  <br>
  <br>
  Encontrá tu abrigo con Omni-Heat en nuestras 5 sucursales Suiza Outdoor en Córdoba Capital o en nuestra <br>
  Tienda Virtual www.suizaoutdoor.com.ar desde la comodidad de tu casa !!<br>
  </div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">

  <div class="titulo">¿Qué es el Acero Damasco?</div>
  <div class="copete">Entre la gran cantidad de tipos de aceros utilizados para la fabricación de cuchillería, nos encontramos con una variedad que destaca por sus grabados y contraste entre diferentes capas; hablamos del Acero de Damasco...

  </div>
  <div class="descripcion"><br>
  <br>
  El acero de Damasco (denominado también como acero damasceno o acero damasquino) es un tipo de acero de crisol empleado y elaborado en Oriente Medio para la fabricación de espadas desde el 1100 hasta el 1700. Las espadas de acero de Damasco eran legendarias por su dureza y su filo "casi eterno", siendo muy aclamadas en Europa.<br>
  La leyenda cuenta que las espadas de este acero podían cortar en 2 las de aceros europeos, debido a la perfecta mezcla de aceros de diferentes durezas. En realidad, este tipo de aleación se produjo en oriente medio durante miles de años, perdiéndose la fórmula aproximadamente en el siglo XVI, de modo que el acero damasquinado actual, aunque estéticamente es similar, no se realiza del mismo modo que el clásico o “auténtico”.<br>
  El acero damasquinado se considera un metal precioso, y debido a la laboriosidad de su producción, resulta en cuchillos de muy alto precio y que normalmente son piezas "custom",  conmemorativas u hojas  de alta gama.<br>
  <br>
  La técnica empleada para su elaboración es hoy en día un debate entre metalúrgicos especialistas en la elaboración del acero. Las espadas de acero damasquinado se conocen por los patrones de sus hojas. <br>
  Los aceros de Damasco son producidos por soldadura en forja de 2 o más metales diversos (normalmente aceros), que tras calentarse son soldados uniendo las diversas placas. Posteriormente, el característico dibujo se marca con ácido, y el grabado de los metales revela las capas y da el contraste, la profundidad y el color.<br>
  El acero damasquinado actual permite obtener buenos filos,para un material destinado a piezas de coleccionista o para aquellos que no van a dar un uso intensivo al cuchillo y prefieren poseer un modelo exclusivo.<br>
  <br>
  Para mas asesoramiento, podrás acercarte a cualquiera de nuestras 5 sucursales Suiza Outdoor Since 1888® de la Ciudad de Córdoba o a través de nuestro Chat On-Line de nuestra Tienda Virtual.</div>
  </div>
  </div>

  </div>




  <div class="noticia tarjeta">

  <div class="row">
  <div class="col-md-12">
  <div class="titulo">Tipos de Acero para cuchillería</div>
  <div class="copete">A la hora de comprar un cuchillo no solo debemos pensar en el tipo y uso, también tenemos que pensar en el material en el que está hecho. Por eso desde Suiza Outdoor te acercamos los Tipos de Acero mas usados en la industria...
  </div>
  <div class="descripcion"><br>
  Acero 420HC<br>
  El 420HC es una versión con mayor contenido de carbono del 420 estándar, de ahí su denominación (420 HIgh Carbon). Al poseer también altos niveles de cromo, es un material con buenas características de resistencia a la abrasión y mantenimiento del filo. El 420HC es un excelente acero para cuchillos de uso general, aunque su resultado final depende del templado que se haya realizado. Se reafila fácilmente, y tiene muy buena resistencia a la corrosión. <br>
  Si se trabaja correctamente, el 420HC puede convertirse en un gran acero, a la altura de otros de mayor categoría, como de hecho sucede con las piezas fabricadas por Buck, quienes desarrollaron un método especial para tratar este acero que se ha traducido en productos muy apreciados para gran cantidad de aplicaciones.<br>
  Su principal usuario es Buck, y también podemos encontrarlo en Gerber.<br>
  <br>
  Acero 440A<br>
  El 440A es un acero inoxidable de bajo coste, con un contenido de carbono del .75% y con la mayor resistencia a la oxidación de toda la serie 440. Posee buenas características de resistencia a la corrosión, es sencillo de afilar y resistente a los impactos sin que se produzcan roturas.<br>
  Es uno de los aceros con mayor tradición de uso en la industria cuchillera, ya que es económico y fácil de trabajar. Dependiendo del fabricante, se obtienen muy buenos resultados.<br>
  Durante los años 80, algunos fabricantes de origen chino marcaron sus productos como "acero 440", y debido a la mala calidad de su producción, ocasionaron un cierto recelo entre los usuarios a los cuchillos fabricados en acero 440A, no obstante esta fama es infundada en el caso de los fabricados en Europa y Estados Unidos.<br>
  <br>
  Acero 440C<br>
  El 440C es un acero inoxidable de gama alta, rey de los cuchillos inoxidables en los años 80 hasta la aparición del ATS-34. Es el que posee menor resistencia al óxido de la serie 440, aunque es excelente para el uso diario, y muy indicado para cuchillos de caza.<br>
  Es un acero difícil de trabajar, por lo que el precio de los cuchillos fabricados con él suele ser alto. Puede pulimentarse a espejo, lo que mejora las propiedades de resistencia al óxido y la facilidad para limpiarlo. Especialmente en manos de artesanos, permite fabricar piezas de superlativa calidad.<br>
  A pesar de que en los últimos años han aparecido muchos nuevos tipos de “superaceros”, el 440C sigue manteniéndose como uno de los mejores aceros de cuchillería del mercado.<br>
  Boker es uno de los fabricantes con mayor reputación en el uso del acero 440C.<br>
  <br>
  Acero AN-58<br>
  Equivalente al acero inoxidable 425, desarrollado por la empresa cuchillera española Nieto a partir de 2007, se trata de un derivado del acero 420 con pequeñas reducciones en sus componentes químicos.<br>
  Es un buen acero para cuchillos de uso general. Se obtienen filos muy aceptables.<br>
  Este acero se encuentra presente en los cuchillos de la marca Nieto, que es la propietaria de la patente.<br>
  <br>
  <br>
  Acero 7Cr17MoV<br>
  El 7Cr17MoV es un acero inoxidable del tipo 440A modificado, que contiene vanadio con el objetivo de aumentar su dureza y resistencia a la corrosión. La dureza recomendada en los productos finales empleando este acero es de 55/57 HRC.<br>
  Se trata de un acero de fabricación china, y aunque en su nomenclatura hace referencia a la inclusión de vanadio (V), en realidad no se le añade o se hace en muy pequeña  proporción (0,04%). Su calidad general es comparable el AUS-6, y en algunos ámbitos se le ha descrito como equivalente al 440C, aunque esto no es del todo exacto.<br>
  El 7Cr17MoV es utilizado por fabricantes como Gerber, Cold Steel y es adecuado para un uso intermedio como cuchillos de campo o machetes.<br>
  <br>
  Acero 8Cr13MoV<br>
  Este es un acero de producción china  y posee una excelente relación precio/ calidad. Parecido en características al 440C . Precisa de un buen templado para prevenir micro roturas en el filo, lo cual depende del fabricante del cuchillo o navaja. Es un poco inferior al 440C en resistencia al desgaste, así como en dureza.<br>
  Es un acero adecuado para cuchillos de cocina, tarea en la que supera a la mayoría de los aceros de gama baja/ intermedia.<br>
  <br>
  Acero 9Cr13MoV<br>
  Producido por Ahonest Changjang Stainless Steel, se trata de un acero inoxidable con adición de cobalto forjado con un alto nivel de carbono. Su uso principal se encuentra en tijeras de barbero de alta gama y cuchillos y navajas de caza.<br>
  En general es mejor que los 440C  en retención de filo, con una resistencia a la corrosión similar a estos aceros.  No obstante, es un acero difícil de afilar. Como en otros aceros inoxidables de procedencia china, sus características finales dependen en gran medida del método de fabricación que haya aplicado la firma cuchillera, oscilando entre una buena calidad en las firmas norteamericanas, y media/ baja si el cuchillo ha sido fabricado en China.<br>
  <br>
  <br>
  Acero MoVa<br>
  El acero MoVa es un material de fabricación española, con adición de molibdeno y vanadio endurecido. <br>
  El acero MoVa albaceteño simplemente hace referencia a la inclusión de estos dos componentes (molibdeno y vanadio), sin describir otras características del mismo. En comparación con el acero más similar, el 420HC, el MoVa es más difícil de afilar y retiene el filo por más tiempo. Posee mejores características generales que el 440A.<br>
  En general, es muy buen acero para cuchillos de uso general, caza o que no vayamos a someter a grandes niveles de estrés. Es usado por varios fabricantes españoles, como Muela.<br>
  <br>
  Por mayor asesoramiento podés encontrarnos en nuestras 5 sucursales de Córdoba Capital o a través de nuestro Chat Online en www.suizaoutdoor.com.ar o  www.armeriasuiza.com.ar</div>
  </div>
  </div>

  </div>
</div>

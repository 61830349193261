import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Usuario } from '../../modelos/usuario/usuario';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../servicios/auth/auth.service';
import { MensajesService } from '../../servicios/mensajes/mensajes.service';
import { AdministradorDeSubscripcionesService } from '../../servicios/administrador-de-subscripciones';

@Component({
  selector: 'app-nueva-contrasenia',
  templateUrl: './nueva-contrasenia.component.html',
  styleUrls: ['./nueva-contrasenia.component.scss']
})
export class NuevaContraseniaComponent implements OnInit, OnDestroy {

  @Input() token : string = null;
  @Output() cambiarModal : EventEmitter<string> = new EventEmitter();

  public form : FormGroup;
  public error : string = null;
  public validar : boolean = false;
  public cargando : boolean = false;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private mensajesService: MensajesService,
              private subscripcionesService : AdministradorDeSubscripcionesService) {

    this.form = this.formBuilder.group({
      contrasenia: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscripcionesService.desechar('NuevaContraseniaComponent');
  }

  reiniciar() {
    this.validar = true;
    if (this.form.valid && this.token != null && !this.cargando) {
      this.cargando = true;
      let s1 : Subscription = this.authService.reiniciarCuenta(this.token, this.form.get("contrasenia").value).pipe(
        finalize(() => {
          this.cargando = false;
        })
      ).subscribe(
        response => {
          this.mensajesService.mostrarMensaje('¡Tu constraseña se cambió con éxito!');
          this.cambiarModal.emit(null);
        },
        error => {
          switch (error.status) {
            case 400:
            case 404:
              this.error = 'El enlace de recuperación ya no es válido, vuelve a recuperar la contraseña';
            break;
            default:
              this.mensajesService.mostrarMensajeTemporal('Ocurrió un error al recuperar la contraseña, vuelve a intentarlo');
            break;
          }
        }
      );
      this.subscripcionesService.agregar('NuevaContraseniaComponent', s1);
    }
  }

  recuperar() {
    this.cambiarModal.emit('Recuperar');
  }
}

export class Busqueda {
  rubro : number
  subRubro : number
  subSubRubro : number
  marca : number
  categoria : number
  linea : number
  destacado : boolean
  oferta : boolean
  outlet : boolean
  masVendido : boolean
  texto : string
  pagina : number
  cantidad : number
  orden : string
  descendente : boolean
  catalogo : number

  constructor() {
    this.rubro = null;
    this.subRubro = null;
    this.marca = null;
    this.categoria = null;
    this.destacado = null;
    this.oferta = null;
    this.outlet = null;
    this.masVendido = null;
    this.texto = null;
    this.pagina = 0;
    this.cantidad = 30;
    this.orden = null;
    this.descendente = false;
    this.catalogo = null;
  }
}
import { Base } from './base';
export class Marca extends Base {

  constructor(data : any = {}) {
    super(data);
  }

  public static extraer(data : any = {}) : Array<Marca> {
    let entidades : Array<Marca> = new Array();
    if(data && data.length > 0) {
      data.forEach((e) => {
        entidades.push(new Marca(e));
      });
    }
    return entidades
  }
}
<div class="container mt-3">
  <h3 class="titulo-principal">TRABAJA CON NOSOTROS</h3>
  <div class="row align-items-center">
    <div class="col-12 col-md-6 col-lg-7">
      <div class="formulario-contacto tarjeta">
        <h5 class="mb-4 text-center text-lg-left">Dejanos tus datos y adjuntá tu CV y te responderemos a la brevedad:</h5>
        <form [formGroup]="formContacto" (submit)="enviarFormContacto()" autocomplete="false">
            <div class="contenedor-datos-contacto" *ngIf="!solicitarDatosDeContacto && usuario">
              <div class="form-row align-items-center">
                <div class="col-12 col-lg-9">
                  <p class="mb-3"><span class="d-block d-lg-inline">Nombre completo: </span>{{usuario.getNombreCompleto()}}</p>
                  <p class="mb-3"><span class="d-block d-lg-inline">Email: </span>{{usuario.email}}</p>
                  <p class="mb-0"><span class="d-block d-lg-inline">Teléfono: </span>{{usuario.getTelefonos()}}</p>
                </div>
                <div class="col-12 col-lg-3 mt-3 mt-lg-0 text-center text-lg-right">
                  <button class="btn btn-sm btn-link  btn-grey" (click)="editarDatosDeContacto()" type="button">Cambiar</button>
                </div>
              </div>
            </div>
            <div *ngIf="solicitarDatosDeContacto">
            <div class="form-row">
              <div class="form-group col-12">
                <label>Nombre completo</label>
                <input type="text"
                       [ngClass]="{'form-control' : true, 
                                   'is-invalid' : validarFormContacto && formContacto.get('nombreCompleto').errors
                                  }"
                       placeholder="Ingresá tu nombre completo"
                       formControlName="nombreCompleto"
                >
                <div class="invalid-feedback d-block" *ngIf="validarFormContacto && formContacto.get('nombreCompleto').errors">
                  <span *ngIf="formContacto.get('nombreCompleto').hasError('required')">
                    El campo nombre completo es obligatorio
                  </span>
                  <span *ngIf="formContacto.get('nombreCompleto').hasError('maxlength')">
                    El campo nombre completo tiene un máximo de 255 letras
                  </span>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-12 col-lg-6">
                <label>Email</label>
                <input type="email"
                       [ngClass]="{'form-control' : true, 
                                   'is-invalid' : validarFormContacto && formContacto.get('email').errors
                                  }"
                       placeholder="Ingresá tu email"
                       formControlName="email"
                >
                <div class="invalid-feedback d-block" *ngIf="validarFormContacto && formContacto.get('email').errors">
                  <span *ngIf="formContacto.get('email').hasError('required')">
                    El campo email es obligatorio
                  </span>
                  <span *ngIf="formContacto.get('email').hasError('email')">
                    El campo email debe ser una dirección de email válida
                  </span>
                </div>
              </div>
              <div class="form-group col-12 col-lg-6">
                <label>Teléfono (opcional)</label>
                <input type="tel"
                       [ngClass]="{'form-control' : true, 
                                   'is-invalid' : validarFormContacto && formContacto.get('telefono').errors
                                  }"
                       placeholder="Ingresá tu teléfono"
                       formControlName="telefono"
                >
                <div class="invalid-feedback d-block" *ngIf="validarFormContacto && formContacto.get('telefono').errors">
                  <span *ngIf="formContacto.get('telefono').hasError('required')">
                    El campo teléfono es obligatorio
                  </span>
                  <span *ngIf="formContacto.get('telefono').hasError('pattern')">
                    El campo teléfono debe contener sólo números
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <label>CV (Currículum Vitae)</label>
              <div class="custom-file">
                <input [ngClass]="{'custom-file-input' : true, 
                                   'is-invalid' : validarFormContacto && archivoInvalido
                                  }"
                        id="curriculumVitae"
                        type="file"
                        (change)="seleccionarArchivo($event.target.files)"
                >
                <label class="custom-file-label" for="curriculumVitae">{{archivoAdjunto ? archivoAdjunto.name : 'Selecciona un archivo (pdf, doc, docx)'}}</label>
              </div>
              <div class="invalid-feedback d-block" *ngIf="validarFormContacto && archivoInvalido">
                El archivo debe ser en formato pdf, odt, doc o docx y tiene un máximo de 15MB
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <label>Mensaje</label>
              <textarea [ngClass]="{'form-control' : true, 
                                    'is-invalid' : validarFormContacto && formContacto.get('consulta').errors
                                   }"
                        placeholder="Dejanos tu mensaje..."
                        formControlName="consulta"
              ></textarea>
              <div class="invalid-feedback d-block" *ngIf="validarFormContacto && formContacto.get('consulta').errors">
                <span *ngIf="formContacto.get('consulta').hasError('required')">
                  El campo consulta es obligatorio
                </span>
                <span *ngIf="formContacto.get('consulta').hasError('maxlength')">
                  El campo consulta tiene un máximo de 255 letras
                </span>
              </div>
            </div>
          </div>
          <div class="form-row mt-3">
            <div class="col-12 text-center text-lg-right">
              <button class="btn btn-suiza btn-pill btn-bold px-5" type="submit">ENVIAR</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-5">
      <div class="informacion-contacto">
        <h4 class="titulo">INFORMACIÓN</h4>
        <div class="detalle">
          <p class="text-center">
            <br/>
            <strong>Casa Central:</strong>
            <br/>
            Rivadavia 302 esq. Lima
            <br/>
            (0351) 4210006/7
            <br/>
            Skype: armeria34
            <br/>
            <br/>
            <strong>Horario de Atención:</strong>
            <br/>
            <br/>
            Lunes a Viernes
            <br/>
            09:00 - 19:00 hs.
            <br/>
            <br/>
            Sábados
            <br/>
            08.30 a 14 hs.
            <br/>
            <br/>
            También podés escribirnos al email
            <strong>rrhh@suizaoutdoor.com.ar</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


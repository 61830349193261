import { Component, OnInit } from '@angular/core';

import { CarritoService } from '../../servicios/carrito/carrito.service';

import { ItemCarrito } from '../../modelos/carrito/item-carrito';

@Component({
  selector: 'app-notificacion-carrito',
  templateUrl: './notificacion-carrito.component.html',
  styleUrls: ['./notificacion-carrito.component.scss']
})
export class NotificacionCarritoComponent implements OnInit {

  public mostrarNotificacion : boolean = false;
  public ocultar : boolean = false;
  public item : ItemCarrito;

  private timeid;

  constructor(private carritoService : CarritoService) {
    this.carritoService.obtenerNotificador().subscribe((actualizacionCarrito) => {
      if(actualizacionCarrito.accion == 'item-agregado') {
        clearTimeout(this.timeid);
        this.item = actualizacionCarrito.item;
        this.ocultar = false;
        setTimeout(() => {
          this.mostrarNotificacion = true;
          this.timeid = setTimeout(() => {
            this.cerrar();
          }, 10000);
        }, 100);
      }
    });
  }

  ngOnInit() {
  }

  cerrar() {
    this.mostrarNotificacion = false;
    setTimeout(() => {
      this.ocultar = true;
    }, 300);
  }
}

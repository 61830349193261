import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../servicios/auth/auth.service';
import { MensajesService } from '../../servicios/mensajes/mensajes.service';
import { AdministradorDeSubscripcionesService } from '../../servicios/administrador-de-subscripciones';

@Component({
  selector: 'app-recuperar-cuenta',
  templateUrl: './recuperar-cuenta.component.html',
  styleUrls: ['./recuperar-cuenta.component.scss']
})
export class RecuperarCuentaComponent implements OnInit, OnDestroy {

  @Input() email : string;
  @Output() cambiarModal : EventEmitter<string> = new EventEmitter();

  public form : FormGroup;
  public validar : boolean = false;
  public cargando : boolean = false;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private mensajesService: MensajesService,
              private subscripcionesService : AdministradorDeSubscripcionesService) {

    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  ngOnInit() {
    this.form.get('email').setValue(this.email);
  }

  ngOnDestroy() {
    this.subscripcionesService.desechar('RecuperarCuentaComponent');
  }

  volver() {
    this.cambiarModal.emit('Login');
  }

  recuperarCuenta() {
    this.validar = true;
    if (this.form.valid && !this.cargando) {
      this.cargando = true;

      let s1 : Subscription = this.authService.recuperarCuenta(this.form.get("email").value).pipe(
        finalize(() => {
          this.cargando = false;
        })
      ).subscribe(
        response => {
          this.mensajesService.mostrarMensaje('Te enviamos un email con un enlace para recuperar la contraseña');
          this.cambiarModal.emit('Login');
        },
        error => {
          switch (error.status) {
            case 404:
              this.form.get('email').setErrors({
                invalid : true
              });
            break;
            default:
              this.mensajesService.mostrarMensajeTemporal('Ocurrió un error al recuperar tu cuenta, inténtalo nuevamente');
            break;
          }
        }
      );
      this.subscripcionesService.agregar('RecuperarCuentaComponent', s1);
    }
  }
}

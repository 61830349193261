import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  private esNavegador : boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.esNavegador = isPlatformBrowser(this.platformId);
  }

  public redireccionar(url : string) {
    if(this.esNavegador) {
      window.location.href = url;
    }
  }
}

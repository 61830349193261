import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdministradorDeSubscripcionesService implements OnDestroy{

  private subscripciones : Map<string, Array<Subscription>> = new Map<string, Array<Subscription>>();

  constructor() {}

  ngOnDestroy() : void {
    this.subscripciones.forEach((subscripciones : Array<Subscription>, grupo : string) => {
      this.desecharTodos(subscripciones);
    });
    this.subscripciones = null;
  }

  public agregar(grupo : string, ...subscripciones : Array<Subscription>) : void {
    if(this.subscripciones.has(grupo)) {
      let existentes : Array<Subscription> = this.subscripciones.get(grupo);
      existentes = existentes.concat(subscripciones);
    }
    else {
      this.subscripciones.set(grupo, subscripciones);
    }
  }

  public desechar(grupo : string) : void {
    this.desecharTodos(this.subscripciones.get(grupo));
    this.subscripciones.delete(grupo);
  }

  private desecharTodos(subscripciones : Array<Subscription>) : void {
    if(subscripciones && subscripciones.length > 0) {
      subscripciones.forEach((subscripcion : Subscription, indice : number) => {
        subscripcion.unsubscribe();
      });
    }
  }
}
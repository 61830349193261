import { Component, OnInit } from '@angular/core';

import { ScrollerService } from '../../servicios/scroller.service';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {

  constructor(private scrollerService : ScrollerService) { }

  ngOnInit() {
    this.scrollerService.reiniciarScrollVertical();
  }
}

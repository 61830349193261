import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private esNavegador : boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.esNavegador = isPlatformBrowser(this.platformId);
  }

  public setItemSession(key : string, value : string) {
    return this.esNavegador ? sessionStorage.setItem(key, value) : null;
  }

  public getItemSession(key : string) {
    return this.esNavegador ? sessionStorage.getItem(key) : null;
  }

  public removeItemSession(key : string) {
    return this.esNavegador ? sessionStorage.removeItem(key) : null;
  }

  public keySession(index : number) {
    return this.esNavegador ? sessionStorage.key(index) : null;
  }

  public clearSession() {
    return this.esNavegador ? sessionStorage.clear() : null;
  }

  public setItemLocal(key : string, value : string) {
    return this.esNavegador ? localStorage.setItem(key, value) : null;
  }

  public getItemLocal(key : string) {
    return this.esNavegador ? localStorage.getItem(key) : null;
  }

  public removeItemLocal(key : string) {
    return this.esNavegador ? localStorage.removeItem(key) : null;
  }

  public keyLocal(index : number) {
    return this.esNavegador ? localStorage.key(index) : null;
  }

  public clearLocal() {
    return this.esNavegador ? localStorage.clear() : null;
  }
}


